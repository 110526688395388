.minimqm {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .minimqm .div {
    background-color: #ffffff;
    height: 2712px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .minimqm .overlap {
    height: 253px;
    left: -18px;
    position: absolute;
    top: -54px;
    width: 338px;
  }
  
  .minimqm .rectangle {
    height: 155px;
    left: 18px;
    position: absolute;
    top: 54px;
    width: 320px;
  }
  
  .minimqm .text-wrapper-2 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 10px;
    left: 254px;
    letter-spacing: -0.4px;
    line-height: normal;
    position: absolute;
    top: 67px;
  }
  
  .minimqm .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 169px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
    width: 33px;
  }
  
  .minimqm .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 210px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .minimqm .text-wrapper-5 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 112px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .minimqm .component-1 {
    left: 20px !important;
    position: absolute !important;
    margin-top: 60px;
    width:85px;
    height: 35px;
  }
  
  .minimqm .text-wrapper-6 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat-BlackItalic", Helvetica;
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    height: 30px;
    left: 96px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 177px;
    white-space: nowrap;
  }
  
  .minimqm .rectangle-2 {
    border: 6px solid;
    border-color: #fecc00;
    height: 90px;
    left: 92px;
    position: absolute;
    top: 154px;
    width: 152px;
  }
  
  .minimqm .text-wrapper-7 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 39px;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 202px;
    width: 159px;
  }
  
  .minimqm .text-wrapper-8 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 10px;
    left: 42px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 205px;
    width: 222px;
  }
  
  .minimqm .p {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    height: 90px;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 228px;
    width: 283px;
  }
  
  .minimqm .overlap-2 {
    height: 153px;
    left: -3px;
    position: absolute;
    top: 339px;
    width: 319px;
  }
  
  .minimqm .overlap-3 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 285px;
  }
  
  .minimqm .rectangle-3 {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 153px;
    left: 20px;
    position: absolute;
    top: 0;
    width: 151px;
  }
  
  .minimqm .element {
    height: 115px;
    left: 28px;
    position: absolute;
    top: 7px;
    width: 134px;
  }
  
  .minimqm .div-2 {
    color: transparent;
    font-family: "Montserrat-ExtraBold", Helvetica;
    font-size: 6px;
    font-weight: 800;
    height: 7px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.5;
    position: absolute;
    top: 63px;
    width: 165px;
  }
  
  .minimqm .span {
    font-family: "Montserrat-ExtraBoldItalic", Helvetica;
    font-style: italic;
  }
  
  .minimqm .text-wrapper-9 {
    color: #ffffff;
    font-family: "Montserrat-ExtraBoldItalic", Helvetica;
    font-style: italic;
  }
  
  .minimqm .rectangle-4 {
    background-color: #03133d;
    height: 38px;
    left: 142px;
    position: absolute;
    top: 7px;
    width: 143px;
  }
  
  .minimqm .text-wrapper-10 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 148px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
    white-space: nowrap;
  }
  
  .minimqm .text-wrapper-11 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 148px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    white-space: nowrap;
  }
  
  .minimqm .element-2 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 176px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 47px;
  }
  
  .minimqm .overlap-4 {
    height: 153px;
    left: 17px;
    position: absolute;
    top: 522px;
    width: 294px;
  }
  
  .minimqm .overlap-5 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 269px;
  }
  
  .minimqm .rectangle-5 {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 151px;
  }
  
  .minimqm .img {
    height: 96px;
    left: 8px;
    position: absolute;
    top: 16px;
    width: 134px;
  }
  
  .minimqm .rectangle-6 {
    background-color: #03133d;
    height: 38px;
    left: 122px;
    position: absolute;
    top: 16px;
    width: 143px;
  }
  
  .minimqm .element-3 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 10px;
    font-weight: 300;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 22px;
    white-space: nowrap;
  }
  
  .minimqm .text-wrapper-12 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 129px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 29px;
    width: 140px;
  }
  
  .minimqm .element-4 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 59px;
    width: 137px;
  }
  
  .minimqm .overlap-6 {
    height: 153px;
    left: 17px;
    position: absolute;
    top: 888px;
    width: 279px;
  }
  
  .minimqm .overlap-7 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 265px;
  }
  
  .minimqm .photo {
    height: 110px;
    left: 9px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 133px;
  }
  
  .minimqm .element-5 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 131px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 22px;
  }
  
  .minimqm .element-6 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 159px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 59px;
  }
  
  .minimqm .overlap-8 {
    height: 155px;
    left: 17px;
    position: absolute;
    top: 1071px;
    width: 302px;
  }
  
  .minimqm .element-7 {
    height: 110px;
    left: 8px;
    position: absolute;
    top: 18px;
    width: 133px;
  }
  
  .minimqm .element-8 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 131px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 24px;
  }
  
  .minimqm .element-l {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 159px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 56px;
  }
  
  .minimqm .overlap-9 {
    height: 153px;
    left: 17px;
    position: absolute;
    top: 1254px;
    width: 277px;
  }
  
  .minimqm .overlap-10 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 277px;
  }
  
  .minimqm .photo-2 {
    height: 110px;
    left: 9px;
    position: absolute;
    top: 20px;
    width: 132px;
  }
  
  .minimqm .text-wrapper-13 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 129px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 20px;
    width: 148px;
  }
  
  .minimqm .text-wrapper-14 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 129px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 41px;
    white-space: nowrap;
  }
  
  .minimqm .element-9 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 54px;
    width: 120px;
  }
  
  .minimqm .overlap-11 {
    height: 153px;
    left: 17px;
    position: absolute;
    top: 705px;
    width: 285px;
  }
  
  .minimqm .IMG {
    height: 101px;
    left: 8px;
    position: absolute;
    top: 18px;
    width: 131px;
  }
  
  .minimqm .rectangle-7 {
    background-color: #03133d;
    height: 38px;
    left: 122px;
    position: absolute;
    top: 11px;
    width: 143px;
  }
  
  .minimqm .element-10 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 21px;
  }
  
  .minimqm .element-11 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 51px;
  }
  
  .minimqm .element-12 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    height: 180px;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1434px;
    width: 291px;
  }
  
  .minimqm .overlap-12 {
    height: 153px;
    left: 17px;
    position: absolute;
    top: 1640px;
    width: 287px;
  }
  
  .minimqm .image {
    height: 116px;
    left: 7px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 132px;
  }
  
  .minimqm .rectangle-8 {
    background-color: #03133d;
    height: 38px;
    left: 122px;
    position: absolute;
    top: 19px;
    width: 143px;
  }
  
  .minimqm .text-wrapper-15 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 10px;
    font-weight: 300;
    height: 12px;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 24px;
    white-space: nowrap;
  }
  
  .minimqm .text-wrapper-16 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 9px;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 37px;
  }
  
  .minimqm .element-13 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 158px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 62px;
  }
  
  .minimqm .overlap-13 {
    height: 168px;
    left: 17px;
    position: absolute;
    top: 1824px;
    width: 300px;
  }
  
  .minimqm .overlap-14 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 267px;
  }
  
  .minimqm .image-2 {
    height: 116px;
    left: 7px;
    object-fit: cover;
    position: absolute;
    top: 10px;
    width: 139px;
  }
  
  .minimqm .rectangle-9 {
    background-color: #03133d;
    height: 38px;
    left: 122px;
    position: absolute;
    top: 20px;
    width: 143px;
  }
  
  .minimqm .element-14 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 26px;
  }
  
  .minimqm .element-15 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 143px;
  }
  
  .minimqm .overlap-15 {
    height: 159px;
    left: 17px;
    position: absolute;
    top: 2192px;
    width: 309px;
  }
  
  .minimqm .image-3 {
    height: 117px;
    left: 10px;
    position: absolute;
    top: 18px;
    width: 133px;
  }
  
  .minimqm .text-wrapper-17 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 21px;
  }
  
  .minimqm .element-16 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
  }
  
  .minimqm .overlap-16 {
    height: 162px;
    left: 17px;
    position: absolute;
    top: 2008px;
    width: 326px;
  }
  
  .minimqm .image-4 {
    height: 101px;
    left: 7px;
    object-fit: cover;
    position: absolute;
    top: 18px;
    width: 138px;
  }
  
  .minimqm .rectangle-10 {
    background-color: #03133d;
    height: 38px;
    left: 122px;
    position: absolute;
    top: 21px;
    width: 143px;
  }
  
  .minimqm .element-17 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 126px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
  }
  
  .minimqm .element-18 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 159px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 63px;
  }
  
  .minimqm .overlap-17 {
    
    background-size: 100% 100%;
    height: 160px;
    left: 0;
    position: absolute;
    top: 2391px;
    width: 320px;
  }
  
  .minimqm .text-wrapper-18 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 81px;
    left: 29px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 45px;
    width: 272px;
  }
  
  .minimqm .text-wrapper-19 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 25px;
    font-weight: 700;
    height: 30px;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 9px;
    white-space: nowrap;
  }
  
  .minimqm .overlap-18 {
    height: 171px;
    left: -5px;
    position: absolute;
    top: 2563px;
    width: 325px;
  }
  
  .minimqm .rectangle-11 {
    height: 144px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 223px;
  }
  
  .minimqm .element-19 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .minimqm .rectangle-12 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .minimqm .text-wrapper-20 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat-MediumItalic", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .minimqm .text-wrapper-21 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .minimqm .div-3 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 8px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 32px;
    width: 62px;
  }
  
  .minimqm .text-wrapper-22 {
    line-height: 7.6px;
  }
  
  .minimqm .text-wrapper-23 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .minimqm .text-wrapper-24 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .minimqm .element-20 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .minimqm .line {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .minimqm .component-183 {
    left: 108px !important;
    top: 23px !important;
    width: 217px !important;
  }
  