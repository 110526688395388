.component {
  height: 122px;
  width: 128px;
}

.component .overlap-group-2 {
  height: 75px;
  left: 18px;
  position: relative;
  top: 47px;
  width: 110px;
}

.component .text-wrapper {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: italic;
  font-weight: 300;
  left: -8px;
  letter-spacing: -0.35px;
  line-height: 5.9px;
  position: absolute;
  top: 18px;
  transform: rotate(-90deg);
  width: 39px;
}

.component .m {
  color: #ffffff;
  font-family: "Black Ops One", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 53px;
  letter-spacing: -0.8px;
  line-height: 13.4px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 21px;
}

.component .UP {
  -webkit-text-stroke: 1px #ffffff;
  color: transparent;
  font-family: "Black Ops One", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 20px;
  letter-spacing: -0.8px;
  line-height: 13.4px;
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.component .PR {
  height: 10px;
  left: 17px;
  position: absolute;
  top: 15px;
  width: 20px;
}

.component .GR {
  height: 11px;
  left: 16px;
  position: absolute;
  top: 30px;
  width: 21px;
}

.component .group {
  height: 68px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 110px;
}

.component .mask-group {
  height: 37px;
  left: 26px;
  position: absolute;
  top: 11px;
  width: 42px;
}
