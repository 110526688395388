.mettalurgicalp {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .mettalurgicalp .div {
    background-color: #ffffff;
    border: 1px none;
    height: 4584px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .mettalurgicalp .view {
    height: 540px;
    left: 49px;
    position: absolute;
    top: 2174px;
    width: 1215px;
  }
  
  .mettalurgicalp .overlap {
    height: 540px;
    position: relative;
    width: 1209px;
  }
  
  .mettalurgicalp .overlap-group {
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1209px;
  }
  
  .mettalurgicalp .rectangle {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 640px;
  }
  
  .mettalurgicalp .img {
    height: 430px;
    left: 35px;
    position: absolute;
    top: 55px;
    width: 570px;
  }
  
  .mettalurgicalp .rectangle-2 {
    background-color: #03133d;
    height: 140px;
    left: 569px;
    position: absolute;
    top: 19px;
    width: 640px;
  }
  
  .mettalurgicalp .text-wrapper-2 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 662px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 39px;
    width: 453px;
  }
  
  .mettalurgicalp .p {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 36px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 269px;
  }
  
  .mettalurgicalp .span {
    font-style: italic;
  }
  
  .mettalurgicalp .text-wrapper-3 {
    color: #ffffff;
    font-style: italic;
  }
  
  .mettalurgicalp .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 669px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 181px;
  }
  
  .mettalurgicalp .overlap-wrapper {
    height: 540px;
    left: 49px;
    position: absolute;
    top: 1584px;
    width: 1215px;
  }
  
  .mettalurgicalp .rectangle-3 {
    background-color: #03133d;
    height: 140px;
    left: 569px;
    position: absolute;
    top: 13px;
    width: 640px;
  }
  
  .mettalurgicalp .div-2 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    /* font-size: 40px; */
    font-weight: 400;
    left: 586px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 611px;
  }
  
  .mettalurgicalp .text-wrapper-4 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .mettalurgicalp .text-wrapper-5 {
    font-size: 20px;
  }
  
  .mettalurgicalp .div-3 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 36px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 287px;
  }
  
  .mettalurgicalp .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 669px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 167px;
  }
  
  .mettalurgicalp .overlap-group-wrapper {
    height: 540px;
    left: 49px;
    position: absolute;
    top: 2764px;
    width: 1224px;
  }
  
  .mettalurgicalp .overlap-2 {
    height: 540px;
    position: relative;
    width: 1218px;
  }
  
  .mettalurgicalp .overlap-3 {
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1218px;
  }
  
  .mettalurgicalp .rectangle-4 {
    background-color: #03133d;
    height: 140px;
    left: 578px;
    position: absolute;
    top: 40px;
    width: 640px;
  }
  
  .mettalurgicalp .text-wrapper-6 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 675px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 446px;
  }
  
  .mettalurgicalp .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 679px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 213px;
  }
  
  .mettalurgicalp .element-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 351px;
    left: 89px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 3350px;
    width: 1191px;
  }
  
  .mettalurgicalp .div-4 {
    height: 1538px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1448px;
  }
  
  .mettalurgicalp .overlap-4 {
    height: 1109px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mettalurgicalp .rectangle-5 {
    height: 686px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mettalurgicalp .text-wrapper-7 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 80px;
    font-weight: 400;
    height: 205px;
    left: 330px;
    letter-spacing: 0;
    /* line-height: 100px; */
    position: absolute;
    top: 699px;
    width: 780px;
  }
  
  .mettalurgicalp .text-wrapper-8 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 190px;
    font-style: italic;
    font-weight: 900;
    height: 300px;
    left: 185px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 453px;
    width: 1014px;
  }
  
  .mettalurgicalp .rectangle-6 {
    border: 40px solid;
    border-color: #fecc00;
    height: 526px;
    left: 272px;
    position: absolute;
    top: 428px;
    width: 775px;
  }
  
  .mettalurgicalp .text-wrapper-9 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 109px;
    left: 450px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 1000px;
    white-space: nowrap;
    width: 526px;
  }
  
  .mettalurgicalp .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 368px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1170px;
    width: 1156px;
  }
  
  .mettalurgicalp .component-129 {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .mettalurgicalp .image {
    height: 28px;
    left: 1342px;
    position: absolute;
    top: 39px;
    width: 42px;
  }
  
  .mettalurgicalp .component-130-instance {
    left: 513px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .mettalurgicalp .design-component-instance-node {
    color: #ffffff !important;
  }
  
  .mettalurgicalp .view-2 {
    left: 689px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .mettalurgicalp .view-3 {
    left: 825px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .mettalurgicalp .view-4 {
    left: 940px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .mettalurgicalp .view-5 {
    height: 400px;
    left: 0;
    position: absolute;
    top: 4184px;
    width: 1452px;
  }
  
  .mettalurgicalp .overlap-5 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .mettalurgicalp .rectangle-7 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .mettalurgicalp .rectangle-8 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .mettalurgicalp .text-wrapper-11 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .mettalurgicalp .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .mettalurgicalp .div-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .mettalurgicalp .text-wrapper-13 {
    line-height: 21.8px;
  }
  
  .mettalurgicalp .text-wrapper-14 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .mettalurgicalp .element-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .mettalurgicalp .text-wrapper-15 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .mettalurgicalp .element-6 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .mettalurgicalp .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .mettalurgicalp .line-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mettalurgicalp .component-128-instance {
    left: 586px !important;
    top: 99px !important;
  }
  
  .mettalurgicalp .view-6 {
    height: 415px;
    left: -15px;
    position: absolute;
    top: 3746px;
    width: 1473px;
  }
  
  .mettalurgicalp .overlap-6 {
    background-image: url(../imagesMetalurgicalProd/rectangle-90.svg);
    background-size: 100% 100%;
    height: 415px;
    left: 15px;
    position: relative;
    width: 1440px;
  }
  
  .mettalurgicalp .text-wrapper-16 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 91px;
    left: 96px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 185px;
    width: 1192px;
  }
  
  .mettalurgicalp .text-wrapper-17 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 9px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 42px;
    white-space: nowrap;
  }
  