.rpw {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.rpw .text-wrapper {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -1.1px;
  line-height: 70px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
