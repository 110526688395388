.mechanical-tr {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .mechanical-tr .div {
    background-color: #ffffff;
    border: 1px none;
    height: 3020px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .mechanical-tr .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 43px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1079px;
    width: 1191px;
  }
  
  .mechanical-tr .overlap {
    height: 488px;
    left: 0;
    position: absolute;
    top: 1136px;
    width: 1440px;
  }
  
  .mechanical-tr .text-wrapper-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 700;
    height: 59px;
    left: 435px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 429px;
    width: 873px;
  }
  
  .mechanical-tr .overlap-group {
    height: 476px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mechanical-tr .rectangle {
    background-color: #03133d;
    height: 420px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mechanical-tr .rectangle-2 {
    border: 1px solid;
    border-color: #ffffff;
    height: 180px;
    left: 497px;
    position: absolute;
    top: 23px;
    width: 440px;
  }
  
  .mechanical-tr .rectangle-3 {
    border: 1px solid;
    border-color: #ffffff;
    height: 180px;
    left: 948px;
    position: absolute;
    top: 23px;
    width: 440px;
  }
  
  .mechanical-tr .rectangle-4 {
    border: 1px solid;
    border-color: #ffffff;
    height: 180px;
    left: 948px;
    position: absolute;
    top: 215px;
    width: 440px;
  }
  
  .mechanical-tr .rectangle-5 {
    border: 1px solid;
    border-color: #ffffff;
    height: 180px;
    left: 497px;
    position: absolute;
    top: 215px;
    width: 440px;
  }
  
  .mechanical-tr .element {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 125px;
    left: 975px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 45px;
    width: 378px;
  }
  
  .mechanical-tr .element-2 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 137px;
    left: 544px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 39px;
    width: 361px;
  }
  
  .mechanical-tr .text-wrapper-3 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 161px;
    left: 544px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 214px;
    width: 345px;
  }
  
  .mechanical-tr .text-wrapper-4 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 141px;
    left: 974px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 224px;
    width: 380px;
  }
  
  .mechanical-tr .text-wrapper-5 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 64px;
    left: 46px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 70px;
    width: 409px;
  }
  
  .mechanical-tr .img {
    height: 334px;
    left: 0;
    position: absolute;
    top: 142px;
    width: 423px;
  }
  
  .mechanical-tr .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 199px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1635px;
    width: 1191px;
  }
  
  .mechanical-tr .view {
    height: 400px;
    left: 0;
    position: absolute;
    top: 2620px;
    width: 1452px;
  }
  
  .mechanical-tr .overlap-group-2 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .mechanical-tr .rectangle-6 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .mechanical-tr .rectangle-7 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .mechanical-tr .text-wrapper-7 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
   
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .mechanical-tr .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .mechanical-tr .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .mechanical-tr .span {
    line-height: 21.8px;
  }
  
  .mechanical-tr .text-wrapper-9 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .mechanical-tr .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .mechanical-tr .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .mechanical-tr .element-4 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .mechanical-tr .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .mechanical-tr .line-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mechanical-tr .image {
    left: 586px !important;
    top: 99px !important;
  }
  
  .mechanical-tr .div-3 {
    height: 1044px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1446px;
  }
  
  .mechanical-tr .overlap-2 {
    height: 1044px;
    position: relative;
    width: 1440px;
  }
  
  .mechanical-tr .rectangle-8 {
    height: 693px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mechanical-tr .text-wrapper-11 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 80px;
    font-weight: 400;
    height: 189px;
    left: 330px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 719px;
    width: 780px;
  }
  
  .mechanical-tr .text-wrapper-12 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 160px;
    font-style: italic;
    font-weight: 900;
    height: 300px;
    left: 303px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 478px;
    width: 811px;
  }
  
  .mechanical-tr .rectangle-9 {
    border: 40px solid;
    border-color: #fecc00;
    height: 470px;
    left: 272px;
    position: absolute;
    top: 418px;
    width: 835px;
  }
  
  .mechanical-tr .text-wrapper-13 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 100px;
    left: 510px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 934px;
    white-space: nowrap;
  }
  
  .mechanical-tr .component-95 {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .mechanical-tr .image-2 {
    height: 28px;
    left: 1326px;
    position: absolute;
    top: 63px;
    width: 42px;
  }
  
  .mechanical-tr .component-96-instance {
    left: 497px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .mechanical-tr .design-component-instance-node {
    color: #ffffff !important;
  }
  
  .mechanical-tr .view-2 {
    left: 673px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .mechanical-tr .view-3 {
    left: 809px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .mechanical-tr .view-4 {
    left: 924px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .mechanical-tr .overlap-wrapper {
    height: 289px;
    left: -6px;
    position: absolute;
    top: 2308px;
    width: 1456px;
  }
  
  .mechanical-tr .overlap-3 {
    height: 290px;
    left: 6px;
    position: relative;
    top: -1px;
    width: 1440px;
  }
  
  .mechanical-tr .rectangle-10 {
    height: 217px;
    left: 0;
    position: absolute;
    top: 73px;
    width: 1440px;
  }
  
  .mechanical-tr .text-wrapper-14 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 69px;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 151px;
    width: 1101px;
  }
  
  .mechanical-tr .text-wrapper-15 {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 21px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    width: 1212px;
  }
  
  .mechanical-tr .rectangle-11 {
    background-color: #03133d;
    height: 400px;
    left: 0;
    position: absolute;
    top: 1876px;
    width: 161px;
  }
  
  .mechanical-tr .rectangle-12 {
    background-color: #03133d;
    height: 400px;
    left: 1279px;
    position: absolute;
    top: 1876px;
    width: 161px;
  }
  
  .mechanical-tr .viber {
    height: 400px;
    left: 170px;
    position: absolute;
    top: 1876px;
    width: 360px;
  }
  
  .mechanical-tr .photo {
    height: 400px;
    left: 910px;
    object-fit: cover;
    position: absolute;
    top: 1876px;
    width: 360px;
  }
  
  .mechanical-tr .photo-2 {
    height: 400px;
    left: 540px;
    position: absolute;
    top: 1876px;
    width: 360px;
  }
  
  @media only screen and (max-width: 768px) {
   
    .mechanical-tr {
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
    
    .mechanical-tr .div {
      background-color: #ffffff;
      height: 917px;
      overflow: hidden;
      position: relative;
      width: 316px;
    }
    
    .mechanical-tr .element {
      color: #ffffff;
      font-family: "?????-??", Helvetica;
      font-size: 20px;
      font-weight: 400;
      height: 100px;
      left: 975px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 1206px;
      width: 378px;
    }
    
    .mechanical-tr .p {
      color: #ffffff;
      font-family: "?????-??", Helvetica;
      font-size: 20px;
      font-weight: 400;
      height: 141px;
      left: 974px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 1360px;
      width: 380px;
    }
    
    .mechanical-tr .image {
      height: 26px;
      left: -30873px;
      position: absolute;
      top: 1094px;
      width: 40px;
    }
    
    .mechanical-tr .view {
      left: 497px !important;
      position: absolute !important;
      top: 47px !important;
    }
    
    .mechanical-tr .component-62 {
      color: #ffffff !important;
    }
    
    .mechanical-tr .component-62-instance {
      left: 673px !important;
      position: absolute !important;
      top: 47px !important;
    }
    
    .mechanical-tr .design-component-instance-node {
      left: 809px !important;
      position: absolute !important;
      top: 47px !important;
    }
    
    .mechanical-tr .view-2 {
      left: 924px !important;
      position: absolute !important;
      top: 47px !important;
    }
    
    .mechanical-tr .photo {
      height: 120px;
      left: 201px;
      object-fit: cover;
      position: absolute;
      top: 688px;
      width: 80px;
    }
    
    .mechanical-tr .overlap {
      height: 281px;
      left: 0;
      position: absolute;
      top: 0;
      width: 316px;
    }
    
    .mechanical-tr .rectangle {
      height: 187px;
      left: 0;
      position: absolute;
      top: 0;
      width: 316px;
    }
    
    .mechanical-tr .img {
      height: 118px;
      left: 34px;
      position: absolute;
      top: 146px;
      width: 245px;
    }
    
    .mechanical-tr .text-wrapper-2 {
      -webkit-text-stroke: 1px #ffffff;
      color: transparent;
      font-family: "Montserrat-BlackItalic", Helvetica;
      font-size: 30px;
      font-style: italic;
      font-weight: 900;
      height: 20px;
      left: 75px;
      letter-spacing: 0;
      line-height: 20px;
      position: absolute;
      text-align: center;
      top: 158px;
      width: 173px;
    }
    
    .mechanical-tr .text-wrapper-3 {
      color: #03133d;
      font-family: "?????-??", Helvetica;
      font-size: 25px;
      font-weight: 400;
      height: 50px;
      left: 71px;
      letter-spacing: 0;
      line-height: 20px;
      position: absolute;
      text-align: center;
      top: 186px;
      width: 173px;
    }
    
    .mechanical-tr .group {
      height: 77px;
      left: 0;
      position: absolute;
      top: 0;
      width: 110px;
    }
    
    .mechanical-tr .element-2 {
      color: #000000;
      font-family: "?????-??", Helvetica;
      font-size: 10px;
      font-weight: 400;
      height: 20px;
      left: 29px;
      letter-spacing: 0;
      line-height: 20px;
      position: absolute;
      text-align: center;
      text-shadow: 0px 4px 4px #00000040;
      top: 261px;
      width: 250px;
    }
    
    .mechanical-tr .text-wrapper-4 {
      color: #000000;
      font-family: "Montserrat-Light", Helvetica;
      font-size: 11px;
      font-weight: 300;
      height: 43px;
      left: 9px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 308px;
      width: 267px;
    }
    
    .mechanical-tr .overlap-group {
      height: 188px;
      left: 0;
      position: absolute;
      top: 367px;
      width: 317px;
    }
    
    .mechanical-tr .rectangle-2 {
      background-color: #03133d;
      height: 163px;
      left: 0;
      position: absolute;
      top: 0;
      width: 317px;
    }
    
    .mechanical-tr .element-3 {
      height: 113px;
      left: 0;
      position: absolute;
      top: 56px;
      width: 141px;
    }
    
    .mechanical-tr .text-wrapper-5 {
      color: #ffffff;
      font-family: "?????-??", Helvetica;
      font-size: 10px;
      font-weight: 400;
      height: 18px;
      left: 9px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 26px;
      width: 147px;
    }
    
    .mechanical-tr .rectangle-3 {
      border: 1px solid;
      border-color: #ffffff;
      height: 50px;
      left: 143px;
      position: absolute;
      top: 22px;
      width: 80px;
    }
    
    .mechanical-tr .rectangle-4 {
      border: 1px solid;
      border-color: #ffffff;
      height: 50px;
      left: 229px;
      opacity: 0.8;
      position: absolute;
      top: 22px;
      width: 80px;
    }
    
    .mechanical-tr .element-4 {
      color: #ffffff;
      font-family: "?????-??", Helvetica;
      font-size: 5px;
      font-weight: 400;
      height: 30px;
      left: 232px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 30px;
      width: 73px;
    }
    
    .mechanical-tr .text-wrapper-6 {
      color: #ffffff;
      font-family: "?????-??", Helvetica;
      font-size: 5px;
      font-weight: 400;
      height: 45px;
      left: 146px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 76px;
      width: 74px;
    }
    
    .mechanical-tr .rectangle-5 {
      border: 1px solid;
      border-color: #ffffff;
      height: 50px;
      left: 143px;
      position: absolute;
      top: 84px;
      width: 80px;
    }
    
    .mechanical-tr .rectangle-6 {
      border: 1px solid;
      border-color: #ffffff;
      height: 50px;
      left: 229px;
      position: absolute;
      top: 84px;
      width: 81px;
    }
    
    .mechanical-tr .text-wrapper-7 {
      color: #ffffff;
      font-family: "?????-??", Helvetica;
      font-size: 5px;
      font-weight: 400;
      height: 35px;
      left: 232px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 81px;
      width: 73px;
    }
    
    .mechanical-tr .element-5 {
      color: #ffffff;
      font-family: "?????-??", Helvetica;
      font-size: 5px;
      font-weight: 400;
      height: 47px;
      left: 146px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 9px;
      width: 89px;
    }
    
    .mechanical-tr .text-wrapper-8 {
      color: #000000;
      font-family: "Montserrat-Bold", Helvetica;
      font-size: 9px;
      font-weight: 700;
      height: 32px;
      left: 66px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 156px;
      width: 214px;
    }
    
    .mechanical-tr .photo-2 {
      height: 120px;
      left: 117px;
      position: absolute;
      top: 688px;
      width: 80px;
    }
    
    .mechanical-tr .text-wrapper-9 {
      color: #000000;
      font-family: "Montserrat-Light", Helvetica;
      font-size: 10px;
      font-weight: 300;
      height: 100px;
      left: 6px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 559px;
      width: 304px;
    }
    
    .mechanical-tr .rectangle-7 {
      background-color: #03133d;
      height: 120px;
      left: 0;
      position: absolute;
      top: 688px;
      width: 29px;
    }
    
    .mechanical-tr .rectangle-8 {
      background-color: #03133d;
      height: 120px;
      left: 285px;
      position: absolute;
      top: 688px;
      width: 31px;
    }
    
    .mechanical-tr .viber {
      height: 120px;
      left: 33px;
      position: absolute;
      top: 688px;
      width: 80px;
    }
    
    
}