.serviM {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .serviM .div {
    background-color: #ffffff;
    height: 1068px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .serviM .overlap {
    height: 227px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 320px;
  }
  
  .serviM .overlap-group {
    height: 227px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }
  
  .serviM .text-wrapper {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-style: italic;
    font-weight: 300;
    left: -18px;
    letter-spacing: -0.35px;
    line-height: 5.9px;
    position: absolute;
    top: 22px;
    transform: rotate(-90deg);
    width: 51px;
  }
  
  .serviM .UP {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Black Ops One", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 15px;
    letter-spacing: -1px;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 180px;
  }
  
  .serviM .m {
    color: #03133d;
    font-family: "Black Ops One", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 14px;
    left: 61px;
    letter-spacing: -1.1px;
    line-height: normal;
    position: absolute;
    top: 29px;
    white-space: nowrap;
    width: 25px;
  }
  
  .serviM .PR {
    height: 14px;
    left: 13px;
    position: absolute;
    top: 20px;
    width: 26px;
  }
  
  .serviM .GR {
    height: 14px;
    left: 12px;
    position: absolute;
    top: 38px;
    width: 26px;
  }
  
  .serviM .mask-group {
    height: 47px;
    left: 25px;
    position: absolute;
    top: 11px;
    width: 50px;
  }
  
  .serviM .text-wrapper-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 105px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .serviM .text-wrapper-3 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 159px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .serviM .rectangle {
    background-color: #03133d;
    height: 147px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .serviM .line {
    height: 1px;
    left: 29px;
    object-fit: cover;
    position: absolute;
    top: 160px;
    width: 125px;
  }
  
  .serviM .text-wrapper-4 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-style: italic;
    font-weight: 500;
    left: 153px;
    letter-spacing: -0.3px;
    line-height: 4.8px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 99px;
    width: 49px;
  }
  
  .serviM .iso {
    height: 147px;
    left: 160px;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .serviM .rectangle-2 {
    border: 2px solid;
    border-color: #ffffff;
    height: 92px;
    left: 19px;
    position: absolute;
    top: 101px;
    width: 176px;
  }
  
  .serviM .text-wrapper-5 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 6px;
    font-weight: 400;
    height: 6px;
    left: 34px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 164px;
    white-space: nowrap;
  }
  
  .serviM .text-wrapper-6 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 4px;
    font-style: italic;
    font-weight: 500;
    left: 153px;
    letter-spacing: -0.2px;
    line-height: normal;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 102px;
    width: 43px;
  }
  
  .serviM .rectangle-3 {
    background-color: #d9d9d980;
    height: 90px;
    left: 160px;
    position: absolute;
    top: 101px;
    width: 33px;
  }
  
  .serviM .img {
    height: 147px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .serviM .text-wrapper-7 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 21px;
    font-weight: 400;
    height: 21px;
    left: 30px;
    letter-spacing: -1.05px;
    line-height: normal;
    position: absolute;
    top: 128px;
    white-space: nowrap;
  }
  
  .serviM .text-wrapper-8 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 200px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .serviM .text-wrapper-9 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 234px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .serviM .p {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 11px;
    font-weight: 400;
    height: 15px;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 230px;
    width: 220px;
  }
  
  .serviM .ISO {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 300;
    height: 190px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 266px;
    width: 298px;
  }
  
  .serviM .overlap-group-2 {
    height: 218px;
    left: -26px;
    position: absolute;
    top: 458px;
    width: 369px;
  }
  
  .serviM .rectangle-4 {
    background-color: #03133d;
    height: 133px;
    left: 6px;
    position: absolute;
    top: 41px;
    transform: rotate(6deg);
    width: 357px;
  }
  
  .serviM .image {
    height: 218px;
    left: 37px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 160px;
  }
  
  .serviM .image-2 {
    height: 218px;
    left: 186px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 160px;
  }
  
  .serviM .overlap-2 {
    height: 184px;
    left: -25px;
    position: absolute;
    top: 722px;
    width: 371px;
  }
  
  .serviM .rectangle-5 {
    background-color: #03133d;
    height: 133px;
    left: 7px;
    position: absolute;
    top: 23px;
    transform: rotate(-7.67deg);
    width: 357px;
  }
  
  .serviM .image-3 {
    height: 178px;
    left: 48px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 123px;
  }
  
  .serviM .image-4 {
    height: 178px;
    left: 199px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 123px;
  }
  
  .serviM .overlap-3 {
    height: 171px;
    left: 0;
    position: absolute;
    top: 919px;
    width: 324px;
  }
  
  .serviM .rectangle-6 {
    height: 144px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 218px;
  }
  
  .serviM .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .serviM .rectangle-7 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .serviM .text-wrapper-10 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .serviM .text-wrapper-11 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .serviM .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .serviM .span {
    line-height: 7.6px;
  }
  
  .serviM .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .serviM .text-wrapper-13 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .serviM .element-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .serviM .line-2 {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .serviM .component-70 {
    left: 108px !important;
    top: 23px !important;
    width: 212px !important;
  }