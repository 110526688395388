.svp {
  /* box-shadow: 0px 10px 50px #ffffff80; */
  height: 100px;
  position: relative;
  width: 250px;
}

.svp .component-275 {
  left: -18px !important;
  top: -15px !important;
}
