.plasmam {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .plasmam .div {
    background-color: #ffffff;
    height: 1200px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .plasmam .overlap {
    height: 258px;
    left: -18px;
    position: absolute;
    top: -54px;
    width: 338px;
  }
  
  .plasmam .rectangle {
    height: 155px;
    left: 18px;
    position: absolute;
    top: 54px;
    width: 320px;
  }
  
  .plasmam .rectangle-2 {
    border: 6px solid;
    border-color: #fecc00;
    height: 90px;
    left: 92px;
    position: absolute;
    top: 159px;
    width: 182px;
  }
  
  .plasmam .text-wrapper-2 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 10px;
    left: 254px;
    letter-spacing: -0.4px;
    line-height: normal;
    position: absolute;
    top: 67px;
  }
  
  .plasmam .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 169px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
    width: 33px;
  }
  
  .plasmam .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 210px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .plasmam .text-wrapper-5 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 112px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .plasmam .component-1 {
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
  }
  
  .plasmam .text-wrapper-6 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    height: 30px;
    left: 111px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 176px;
    white-space: nowrap;
  }
  
  .plasmam .text-wrapper-7 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 15px;
    font-weight: 400;
    height: 30px;
    left: 112px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 214px;
    width: 110px;
  }
  
  .plasmam .text-wrapper-8 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 9px;
    left: 53px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 209px;
    white-space: nowrap;
  }
  
  .plasmam .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 300;
    height: 70px;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 237px;
    width: 282px;
  }
  
  .plasmam .overlap-2 {
    background-color: #03133d;
    height: 73px;
    left: 0;
    position: absolute;
    top: 327px;
    width: 320px;
  }
  
  .plasmam .p {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 48px;
    left: 87px;
    letter-spacing: 0;
    line-height: 12px;
    position: absolute;
    top: 10px;
  }
  
  .plasmam .text-wrapper-9 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 18px;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 26px;
    width: 57px;
  }
  
  .plasmam .plazmennaya-rezka {
    height: 100px;
    left: 0;
    position: absolute;
    top: 407px;
    width: 320px;
  }
  
  .plasmam .text-wrapper-10 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 12px;
    left: 24px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 536px;
    white-space: nowrap;
  }
  
  .plasmam .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 99px;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 557px;
    width: 283px;
  }
  
  .plasmam .span {
    font-weight: 300;
  }
  
  .plasmam .text-wrapper-11 {
    font-weight: 300;
    text-decoration: underline;
  }
  
  .plasmam .text-wrapper-12 {
    font-weight: 600;
  }
  
  .plasmam .overlap-3 {
    height: 204px;
    left: 11px;
    position: absolute;
    top: 687px;
    width: 300px;
  }
  
  .plasmam .view {
    height: 204px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }
  
  .plasmam .div-wrapper {
    background-color: #03133d;
    border-radius: 10px;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 54px;
  }
  
  .plasmam .text-wrapper-13 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 5px;
    font-weight: 500;
    height: 7px;
    left: 7px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 5px;
    width: 39px;
  }
  
  .plasmam .overlap-4 {
    background-color: #02133c;
    border-radius: 10px;
    height: 128px;
    left: 0;
    position: absolute;
    top: 20px;
    width: 54px;
  }
  
  .plasmam .text-wrapper-14 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 500;
    height: 7px;
    left: 8px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 56px;
    transform: rotate(-90deg);
    width: 37px;
  }
  
  .plasmam .overlap-5 {
    background-color: #03133d;
    border-radius: 10px;
    height: 54px;
    left: 0;
    position: absolute;
    top: 149px;
    width: 54px;
  }
  
  .plasmam .text-wrapper-15 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 500;
    height: 7px;
    left: -2px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 24px;
    transform: rotate(-90deg);
    width: 54px;
  }
  
  .plasmam .overlap-6 {
    background-color: #03133d;
    border-radius: 10px;
    height: 18px;
    left: 56px;
    position: absolute;
    top: 0;
    width: 54px;
  }
  
  .plasmam .text-wrapper-16 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 500;
    height: 14px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 2px;
  }
  
  .plasmam .rectangle-3 {
    background-color: #03133d;
    border-radius: 10px;
    height: 18px;
    left: 111px;
    position: absolute;
    top: 0;
    width: 82px;
  }
  
  .plasmam .overlap-7 {
    background-color: #03133d;
    border-radius: 10px;
    height: 18px;
    left: 196px;
    position: absolute;
    top: 0;
    width: 94px;
  }
  
  .plasmam .text-wrapper-17 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 500;
    height: 14px;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 2px;
  }
  
  .plasmam .rectangle-4 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 20px;
    width: 54px;
  }
  
  .plasmam .rectangle-5 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 20px;
    width: 82px;
  }
  
  .plasmam .rectangle-6 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 29px;
    width: 82px;
  }
  
  .plasmam .rectangle-7 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 39px;
    width: 82px;
  }
  
  .plasmam .rectangle-8 {
       background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 48px;
    width: 82px;
  }
  
  .plasmam .rectangle-9 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 57px;
    width: 82px;
  }
  
  .plasmam .rectangle-10 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 66px;
    width: 82px;
  }
  
  .plasmam .rectangle-11 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 75px;
    width: 82px;
  }
  
  .plasmam .rectangle-12 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 84px;
    width: 82px;
  }
  
  .plasmam .rectangle-13 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 94px;
    width: 82px;
  }
  
  .plasmam .rectangle-14 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 103px;
    width: 82px;
  }
  
  .plasmam .rectangle-15 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 112px;
    width: 82px;
  }
  
  .plasmam .rectangle-16 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 121px;
    width: 82px;
  }
  
  .plasmam .rectangle-17 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 130px;
    width: 82px;
  }
  
  .plasmam .rectangle-18 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 139px;
    width: 82px;
  }
  
  .plasmam .rectangle-19 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 149px;
    width: 82px;
  }
  
  .plasmam .rectangle-20 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 159px;
    width: 82px;
  }
  
  .plasmam .rectangle-21 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 168px;
    width: 82px;
  }
  
  .plasmam .rectangle-22 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 177px;
    width: 82px;
  }
  
  .plasmam .rectangle-23 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 186px;
    width: 82px;
  }
  
  .plasmam .rectangle-24 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 111px;
    position: absolute;
    top: 195px;
    width: 82px;
  }
  
  .plasmam .rectangle-25 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 20px;
    width: 94px;
  }
  
  .plasmam .rectangle-26 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 29px;
    width: 94px;
  }
  
  .plasmam .rectangle-27 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 39px;
    width: 94px;
  }
  
  .plasmam .rectangle-28 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 48px;
    width: 94px;
  }
  
  .plasmam .rectangle-29 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 57px;
    width: 94px;
  }
  
  .plasmam .rectangle-30 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 66px;
    width: 94px;
  }
  
  .plasmam .rectangle-31 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 75px;
    width: 94px;
  }
  
  .plasmam .rectangle-32 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 84px;
    width: 94px;
  }
  
  .plasmam .rectangle-33 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 94px;
    width: 94px;
  }
  
  .plasmam .rectangle-34 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 103px;
    width: 94px;
  }
  
  .plasmam .rectangle-35 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 112px;
    width: 94px;
  }
  
  .plasmam .rectangle-36 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 121px;
    width: 94px;
  }
  
  .plasmam .rectangle-37 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 130px;
    width: 94px;
  }
  
  .plasmam .rectangle-38 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 139px;
    width: 94px;
  }
  
  .plasmam .rectangle-39 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 149px;
    width: 94px;
  }
  
  .plasmam .rectangle-40 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 159px;
    width: 94px;
  }
  
  .plasmam .rectangle-41 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 168px;
    width: 94px;
  }
  
  .plasmam .rectangle-42 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 177px;
    width: 94px;
  }
  
  .plasmam .rectangle-43 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 186px;
    width: 94px;
  }
  
  .plasmam .rectangle-44 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 196px;
    position: absolute;
    top: 195px;
    width: 94px;
  }
  
  .plasmam .rectangle-45 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 29px;
    width: 54px;
  }
  
  .plasmam .rectangle-46 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 39px;
    width: 54px;
  }
  
  .plasmam .rectangle-47 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 48px;
    width: 54px;
  }
  
  .plasmam .rectangle-48 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 57px;
    width: 54px;
  }
  
  .plasmam .rectangle-49 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 66px;
    width: 54px;
  }
  
  .plasmam .rectangle-50 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 75px;
    width: 54px;
  }
  
  .plasmam .rectangle-51 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 84px;
    width: 54px;
  }
  
  .plasmam .rectangle-52 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 94px;
    width: 54px;
  }
  
  .plasmam .rectangle-53 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 103px;
    width: 54px;
  }
  
  .plasmam .rectangle-54 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 112px;
    width: 54px;
  }
  
  .plasmam .rectangle-55 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 121px;
    width: 54px;
  }
  
  .plasmam .rectangle-56 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 130px;
    width: 54px;
  }
  
  .plasmam .rectangle-57 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 139px;
    width: 54px;
  }
  
  .plasmam .rectangle-58 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 149px;
    width: 54px;
  }
  
  .plasmam .rectangle-59 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 159px;
    width: 54px;
  }
  
  .plasmam .rectangle-60 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 168px;
    width: 54px;
  }
  
  .plasmam .rectangle-61 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 177px;
    width: 54px;
  }
  
  .plasmam .rectangle-62 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 186px;
    width: 54px;
  }
  
  .plasmam .rectangle-63 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 8px;
    left: 56px;
    position: absolute;
    top: 195px;
    width: 54px;
  }
  
  .plasmam .text-wrapper-18 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 500;
    height: 14px;
    left: 121px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 1px;
  }
  
  .plasmam .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 19px;
  }
  
  .plasmam .text-wrapper-20 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 28px;
  }
  
  .plasmam .text-wrapper-21 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 38px;
  }
  
  .plasmam .text-wrapper-22 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 47px;
  }
  
  .plasmam .text-wrapper-23 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 56px;
  }
  
  .plasmam .text-wrapper-24 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 65px;
  }
  
  .plasmam .text-wrapper-25 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 74px;
  }
  
  .plasmam .text-wrapper-26 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 83px;
  }
  
  .plasmam .text-wrapper-27 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 93px;
  }
  
  .plasmam .text-wrapper-28 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 102px;
  }
  
  .plasmam .text-wrapper-29 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 111px;
  }
  
  .plasmam .text-wrapper-30 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 120px;
  }
  
  .plasmam .text-wrapper-31 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 129px;
  }
  
  .plasmam .text-wrapper-32 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 138px;
  }
  
  .plasmam .text-wrapper-33 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    -ms-transform: rotate(1deg); /* IE 9 */
  -webkit-transform: rotate(1deg); /* Chrome, Safari, Opera */
  transform: rotate(1deg);
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 148px;
  }
  
  .plasmam .text-wrapper-34 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    -ms-transform: rotate(1deg); /* IE 9 */
    -webkit-transform: rotate(1deg); /* Chrome, Safari, Opera */
    transform: rotate(1deg);
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 159px;
  }
  
  .plasmam .text-wrapper-35 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    -ms-transform: rotate(1deg); /* IE 9 */
    -webkit-transform: rotate(1deg); /* Chrome, Safari, Opera */
    transform: rotate(1deg);
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 168px;
  }
  
  .plasmam .text-wrapper-36 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    -ms-transform: rotate(1deg); /* IE 9 */
    -webkit-transform: rotate(1deg); /* Chrome, Safari, Opera */
    transform: rotate(1deg);
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 177px;
  }
  
  .plasmam .text-wrapper-37 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 185px;
  }
  
  .plasmam .text-wrapper-38 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 78px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 194px;
  }
  
  .plasmam .text-wrapper-39 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 19px;
  }
  
  .plasmam .text-wrapper-40 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 28px;
  }
  
  .plasmam .text-wrapper-41 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 37px;
  }
  
  .plasmam .text-wrapper-42 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 46px;
  }
  
  .plasmam .text-wrapper-43 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 144px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 55px;
  }
  
  .plasmam .text-wrapper-44 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 65px;
  }
  
  .plasmam .text-wrapper-45 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 74px;
  }
  
  .plasmam .text-wrapper-46 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 83px;
  }
  
  .plasmam .text-wrapper-47 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 92px;
  }
  
  .plasmam .text-wrapper-48 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 101px;
  }
  
  .plasmam .text-wrapper-49 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 110px;
  }
  
  .plasmam .text-wrapper-50 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 119px;
  }
  
  .plasmam .text-wrapper-51 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 129px;
  }
  
  .plasmam .text-wrapper-52 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 144px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 138px;
  }
  
  .plasmam .text-wrapper-53 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 148px;
  }
  
  .plasmam .text-wrapper-54 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 141px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 157px;
  }
  
  .plasmam .text-wrapper-55 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 141px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 166px;
  }
  
  .plasmam .text-wrapper-56 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 141px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 175px;
  }
  
  .plasmam .text-wrapper-57 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 142px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 185px;
  }
  
  .plasmam .text-wrapper-58 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 142px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 194px;
  }
  
  .plasmam .text-wrapper-59 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 236px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 19px;
  }
  
  .plasmam .text-wrapper-60 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 28px;
  }
  
  .plasmam .text-wrapper-61 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 37px;
  }
  
  .plasmam .text-wrapper-62 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 47px;
  }
  
  .plasmam .text-wrapper-63 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 56px;
  }
  
  .plasmam .text-wrapper-64 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 65px;
  }
  
  .plasmam .text-wrapper-65 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 74px;
  }
  
  .plasmam .text-wrapper-66 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 83px;
  }
  
  .plasmam .text-wrapper-67 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 92px;
  }
  
  .plasmam .text-wrapper-68 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 101px;
  }
  
  .plasmam .text-wrapper-69 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 233px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 111px;
  }
  
  .plasmam .text-wrapper-70 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 234px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 120px;
  }
  
  .plasmam .text-wrapper-71 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 234px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 129px;
  }
  
  .plasmam .text-wrapper-72 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 233px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 138px;
  }
  
  .plasmam .text-wrapper-73 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 233px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 148px;
  }
  
  .plasmam .text-wrapper-74 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 234px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 157px;
  }
  
  .plasmam .text-wrapper-75 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 233px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 166px;
  }
  
  .plasmam .text-wrapper-76 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 233px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 175px;
  }
  
  .plasmam .text-wrapper-77 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 233px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 184px;
  }
  
  .plasmam .text-wrapper-78 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    height: 9px;
    left: 234px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 194px;
  }
  
  .plasmam .text-wrapper-79 {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 25px;
    font-weight: 700;
    height: 30px;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 907px;
    white-space: nowrap;
  }
  
  .plasmam .promgrupp-pc-gmail-wrapper {
    background-image: url(../../mobImages/imagesPlasmaCutting/rectangle-88.svg);
    background-size: 100% 100%;
    height: 72px;
    left: 0;
    position: absolute;
    top: 938px;
    width: 320px;
  }
  
  .plasmam .promgrupp-pc-gmail {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 30px;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 20px;
    width: 297px;
  }
  
  .plasmam .overlap-8 {
    height: 171px;
    left: -9px;
    position: absolute;
    top: 1056px;
    width: 329px;
  }
  
  .plasmam .img {
    height: 139px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 227px;
  }
  
  .plasmam .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .plasmam .rectangle-64 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .plasmam .text-wrapper-80 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .plasmam .text-wrapper-81 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .plasmam .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .plasmam .text-wrapper-82 {
    line-height: 7.6px;
  }
  
  .plasmam .text-wrapper-83 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .plasmam .text-wrapper-84 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .plasmam .element-4 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .plasmam .line {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .plasmam .image {
    left: 108px !important;
    top: 23px !important;
    width: 221px !important;
  }