.dvp {
  height: 154px;
  width: 258px;
}

.dvp .div {
  height: 144px;
  position: relative;
  top: 25px;
  width: 391px;
}

.dvp .text-wrapper {
  color: #02133c;
  font-family: "Montserrat", Helvetica;
  font-size: 100px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 258px;
}

.dvp .p {
  color: #082e8a;
  font-family: "Montserrat", Helvetica;
  font-size: 22px;
  font-weight: 800;
  left: 0px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 117px;
}
