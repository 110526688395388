.LLC-FPC-m {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .LLC-FPC-m .div {
    background-color: #ffffff;
    height: 974px;
    overflow: hidden;
    position: relative;
    width: 316px;
  }
  
  .LLC-FPC-m .element {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 100px;
    left: 975px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1206px;
    width: 378px;
  }
  
  .LLC-FPC-m .p {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 141px;
    left: 974px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1360px;
    width: 380px;
  }
  
  .LLC-FPC-m .image {
    height: 26px;
    left: -29996px;
    position: absolute;
    top: -1254px;
    width: 40px;
  }
  
  .LLC-FPC-m .view {
    left: 497px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .LLC-FPC-m .component-84 {
    color: #ffffff !important;
  }
  
  .LLC-FPC-m .component-84-instance {
    left: 673px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .LLC-FPC-m .design-component-instance-node {
    left: 809px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .LLC-FPC-m .view-2 {
    left: 924px !important;
    position: absolute !important;
    top: 47px !important;
  }
  
  .LLC-FPC-m .photo {
    height: 120px;
    left: 201px;
    object-fit: cover;
    position: absolute;
    top: 688px;
    width: 80px;
  }
  
  .LLC-FPC-m .overlap {
    height: 281px;
    left: 0;
    /* position: absolute; */
    top: 0;
    width: 316px;
  }
  
  .LLC-FPC-m .rectangle {
    height: 187px;
    left: 0;
    position: absolute;
    top: 0;
    width: 316px;
  }
  
  .LLC-FPC-m .img {
    height: 118px;
    left: 34px;
    position: absolute;
    top: 146px;
    width: 245px;
  }
  
  .LLC-FPC-m .text-wrapper-2 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat-BlackItalic", Helvetica;
    font-size: 30px;
    font-style: italic;
    font-weight: 900;
    height: 20px;
    left: 75px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 158px;
    width: 173px;
  }
  
  .LLC-FPC-m .text-wrapper-3 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 50px;
    left: 71px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 186px;
    width: 173px;
  }
  
  .LLC-FPC-m .group {
    height: 77px;
    left: 0;
    position: absolute;
    top: 0;
    width: 110px;
  }
  
  .LLC-FPC-m .element-2 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 20px;
    left: 29px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    text-shadow: 0px 4px 4px #00000040;
    top: 261px;
    width: 250px;
  }
  
  .LLC-FPC-m .text-wrapper-4 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 11px;
    font-weight: 300;
    height: 43px;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 308px;
    width: 267px;
  }
  
  .LLC-FPC-m .overlap-group {
    height: 188px;
    left: 0;
    position: absolute;
    top: 367px;
    width: 317px;
  }
  
  .LLC-FPC-m .rectangle-2 {
    background-color: #03133d;
    height: 163px;
    left: 0;
    position: absolute;
    top: 0;
    width: 317px;
  }
  
  .LLC-FPC-m .element-3 {
    height: 113px;
    left: 0;
    position: absolute;
    top: 56px;
    width: 141px;
  }
  
  .LLC-FPC-m .text-wrapper-5 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 18px;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 26px;
    width: 147px;
  }
  
  .LLC-FPC-m .rectangle-3 {
    border: 1px solid;
    border-color: #ffffff;
    height: 50px;
    left: 143px;
    position: absolute;
    top: 22px;
    width: 80px;
  }
  
  .LLC-FPC-m .rectangle-4 {
    border: 1px solid;
    border-color: #ffffff;
    height: 50px;
    left: 229px;
    opacity: 0.8;
    position: absolute;
    top: 22px;
    width: 80px;
  }
  
  .LLC-FPC-m .element-4 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 5px;
    font-weight: 400;
    height: 30px;
    left: 232px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 20px;
    width: 73px;
  }
  
  .LLC-FPC-m .text-wrapper-6 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 5px;
    font-weight: 400;
    height: 45px;
    left: 146px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 81px;
    width: 74px;
  }
  
  .LLC-FPC-m .rectangle-5 {
    border: 1px solid;
    border-color: #ffffff;
    height: 50px;
    left: 143px;
    position: absolute;
    top: 84px;
    width: 80px;
  }
  
  .LLC-FPC-m .rectangle-6 {
    border: 1px solid;
    border-color: #ffffff;
    height: 50px;
    left: 229px;
    position: absolute;
    top: 84px;
    width: 81px;
  }
  
  .LLC-FPC-m .text-wrapper-7 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 5px;
    font-weight: 400;
    height: 35px;
    left: 232px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 81px;
    width: 73px;
  }
  
  .LLC-FPC-m .element-5 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 5px;
    font-weight: 400;
    height: 47px;
    left: 146px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 20px;
    width: 89px;
  }
  
  .LLC-FPC-m .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 9px;
    font-weight: 700;
    height: 32px;
    left: 66px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 156px;
    width: 214px;
  }
  
  .LLC-FPC-m .photo-2 {
    height: 120px;
    left: 117px;
    position: absolute;
    top: 688px;
    width: 80px;
  }
  
  .LLC-FPC-m .text-wrapper-9 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 10px;
    font-weight: 300;
    height: 100px;
    left: 6px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 559px;
    width: 304px;
  }
  
  .LLC-FPC-m .rectangle-7 {
    background-color: #03133d;
    height: 120px;
    left: 0;
    position: absolute;
    top: 688px;
    width: 29px;
  }
  
  .LLC-FPC-m .rectangle-8 {
    background-color: #03133d;
    height: 120px;
    left: 285px;
    position: absolute;
    top: 688px;
    width: 31px;
  }
  
  .LLC-FPC-m .viber {
    height: 120px;
    left: 33px;
    position: absolute;
    top: 688px;
    width: 80px;
  }
  
  .LLC-FPC-m .overlap-group-2 {
    height: 172px;
    left: -3px;
    position: absolute;
    top: 823px;
    width: 320px;
  }
  
  .LLC-FPC-m .rectangle-9 {
    height: 148px;
    left: 103px;
    position: absolute;
    top: 3px;
    width: 216px;
  }
  
  .LLC-FPC-m .rectangle-10 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 1px;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .LLC-FPC-m .text-wrapper-10 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat-MediumItalic", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 16px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 25px;
    width: 259px;
  }
  
  .LLC-FPC-m .text-wrapper-11 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 218px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 93px;
    white-space: nowrap;
  }
  
  .LLC-FPC-m .div-2 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 17px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 27px;
  }
  
  .LLC-FPC-m .span {
    line-height: 6.7px;
  }
  
  .LLC-FPC-m .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 8px;
  }
  
  .LLC-FPC-m .element-6 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 24px;
  }
  
  .LLC-FPC-m .text-wrapper-13 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 206px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 73px;
    white-space: nowrap;
  }
  
  .LLC-FPC-m .element-7 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 131px;
    width: 320px;
  }
  
  .LLC-FPC-m .line {
    height: 2px;
    left: 80px;
    position: absolute;
    top: 129px;
    width: 175px;
  }
  
  .LLC-FPC-m .component-85 {
    left: 96px !important;
    top: 17px !important;
    width: 223px !important;
  }
  
  .LLC-FPC-m .line-2 {
    height: 2px;
    left: 3px;
    position: absolute;
    top: 0;
    width: 316px;
  }
  