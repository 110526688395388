.about {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .about .div {
    background-color: #ffffff;
    border: 1px none;
    height: 4324px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .about .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 1065px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2205px;
    width: 1226px;
  }
  
  .about .textWarapper {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 180px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1059px;
    width: 1196px;
  }
  
 .view {
    height: 400px;
    left: 0;
    position: absolute;
    
    top: 3924px;
    width: 1452px;
  }
  
  .about .overlap-group {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .about .rectangle {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .about .rectangle-2 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .about .text-wrapper-2 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .about .text-wrapper-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .about .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .about .span {
    line-height: 21.8px;
  }
  
  .about .text-wrapper-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .about .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .about .text-wrapper-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .about .element-3 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .about .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .about .img {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .about .image {
    left: 586px !important;
    top: 99px !important;
  }
  
  .about .overlap {
    height: 758px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .about .component-117 {
    height: 204px !important;
    width: 343px !important;
  }
  
  .about .element-4 {
    height: 608px;
    left: 720px;
    position: absolute;
    top: 150px;
    width: 720px;
  }
  
  .about .rectangle-3 {
    background-color: #03133d;
    height: 608px;
    left: 0;
    position: absolute;
    top: 150px;
    width: 720px;
  }
  
  .about .photo {
    height: 608px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 150px;
    width: 720px;
  }
  
  .about .text-wrapper-6 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 70px;
    font-weight: 400;
    height: 192px;
    left: 197px;
    letter-spacing: -3.5px;
    line-height: 190px;
    position: absolute;
    top: 324px;
    white-space: nowrap;
    width: 568px;
  }
  
  .about .text-wrapper-7 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 100px;
    left: 204px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 447px;
    white-space: nowrap;
  }
  
  .about .rectangle-4 {
    background-color: #d9d9d980;
    height: 360px;
    left: 720px;
    position: absolute;
    top: 283px;
    width: 183px;
  }
  
  .about .rectangle-5 {
    border: 5px solid;
    border-color: #ffffff;
    height: 360px;
    left: 98px;
    position: absolute;
    top: 278px;
    width: 800px;
  }
  
  .about .line-2 {
    height: 2px;
    left: 141px;
    position: absolute;
    top: 470px;
    width: 560px;
  }
  
  .about .text-wrapper-8 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
   
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    left: 723px;
    letter-spacing: -1px;
    line-height: 20px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 276px;
    width: 169px;
  }
  
  .about .image-2 {
    height: 28px;
    left: 1345px;
    position: absolute;
    top: 51px;
    width: 42px;
  }
  
  .about .text-wrapper-9 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 70px;
    left: 516px;
    letter-spacing: -1.1px;
    line-height: 70px;
    position: absolute;
    top: 34px;
    white-space: nowrap;
  }
  
  .about .text-wrapper-10 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 70px;
    left: 692px;
    letter-spacing: -1.1px;
    line-height: 70px;
    position: absolute;
    top: 34px;
    white-space: nowrap;
  }
  
  .about .text-wrapper-11 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 70px;
    left: 828px;
    letter-spacing: -1.1px;
    line-height: 70px;
    position: absolute;
    top: 34px;
    white-space: nowrap;
  }
  
  .about .text-wrapper-12 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 70px;
    left: 943px;
    letter-spacing: -1.1px;
    line-height: 70px;
    position: absolute;
    top: 34px;
    white-space: nowrap;
  }
  
  .about .text-wrapper-13 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 50px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2155px;
  }
  
  .about .text-wrapper-14 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 50px;
    left: 132px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 963px;
  }
  
  .about .overlap-2 {
    height: 404px;
    left: 0;
    position: absolute;
    top: 1264px;
    width: 1307px;
  }
  
  .about .text-wrapper-15 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 50px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 334px;
  }
  
  .about .overlap-wrapper {
    height: 404px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1307px;
  }
  
  .about .overlap-3 {
    height: 404px;
    position: relative;
    width: 1305px;
  }
  
  .about .rectangle-6 {
    background-color: #03133d;
    box-shadow: 0px 6px 6px #00000040;
    height: 250px;
    left: 0;
    position: absolute;
    top: 47px;
    width: 836px;
  }
  
  .about .element-removebg-preview {
    height: 404px;
    left: 680px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 625px;
  }
  
  .about .text-wrapper-16 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 192px;
    left: 174px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 67px;
    width: 596px;
  }
  
  .about .text-wrapper-17 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 105px;
    left: 130px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: 829px;
    width: 873px;
  }
  
  .about .view-2 {
    height: 412px;
    left: 118px;
    position: absolute;
    top: 1680px;
    width: 1266px;
  }
  
  .about .div-wrapper {
    background-color: #03133d;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 78px;
    left: 942px;
    position: absolute;
    top: 334px;
    width: 300px;
  }
  
  .about .text-wrapper-18 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 70px;
    font-weight: 700;
    height: 74px;
    left: 76px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;

  }
  
  .about .overlap-4 {
    background-color: #03133d;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 78px;
    left: 628px;
    position: absolute;
    top: 333px;
    width: 300px;
  }
  
  .about .text-wrapper-19 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 70px;
    font-weight: 700;
    height: 74px;
    left: 73px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    
  }
  
  .about .overlap-5 {
    background-color: #03133d;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 78px;
    left: 314px;
    position: absolute;
    top: 334px;
    width: 300px;
  }
  
  .about .text-wrapper-20 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 70px;
    font-weight: 700;
    height: 74px;
    left: 70px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;

  }
  
  .about .overlap-6 {
    background-color: #03133d;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 78px;
    left: 0;
    position: absolute;
    top: 334px;
    width: 300px;
  }
  
  .about .text-wrapper-21 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 70px;
    font-weight: 700;
    height: 74px;
    left: 70px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
 
  }
  
  .about .overlap-7 {
    height: 221px;
    left: 26px;
    position: absolute;
    top: 0;
    width: 270px;
  }
  
  .about .text-wrapper-22 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 144px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 270px;
  }
  
  .about .text-wrapper-23 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 800;
    height: 80px;
   margin-left: 35px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .about .overlap-8 {
    height: 173px;
    left: 310px;
    position: absolute;
    top: 0;
    width: 303px;
  }
  
  .about .text-wrapper-24 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 96px;
    left: 29px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 270px;
  }
  
  .about .overlap-9 {
    height: 317px;
    left: 650px;
    position: absolute;
    top: 0;
    width: 270px;
  }
  
  .about .text-wrapper-25 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 240px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 270px;
  }
  
  .about .text-wrapper-26 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 800;
    height: 80px;
    left: 30px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .about .overlap-10 {
    height: 245px;
    left: 960px;
    position: absolute;
    top: 0;
    width: 270px;
  }
  
  .about .text-wrapper-27 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 168px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 270px;
  }
  
  .about .text-wrapper-28 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 800;
    height: 80px;
    left: 28px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }



  .about .line-3 {
    height: 220px;
    left: 305px;
    object-fit: cover;
    position: absolute;
    top: 72px;
    width: 1px;
  }
  
  .about .line-4 {
    height: 220px;
    left: 621px;
    object-fit: cover;
    position: absolute;
    top: 72px;
    width: 1px;
  }
  
  .about .line-5 {
    height: 220px;
    left: 941px;
    object-fit: cover;
    position: absolute;
    top: 72px;
    width: 1px;
  }
  
  .about .line-6 {
    height: 200px;
    left: 1241px;
    object-fit: cover;
    position: absolute;
    top: 77px;
    width: 1px;
  }
  
  .about .line-7 {
    height: 200px;
    left: -1px;
    object-fit: cover;
    position: absolute;
    top: 82px;
    width: 1px;
  }
  
  .about .element-5 {
    height: 1px;
    left: -7915px;
    object-fit: cover;
    position: absolute;
    top: 4276px;
    width: 1px;
  }
  
  .about .IMG {
    height: 285px;
    left: 780px;
    position: absolute;
    top: 3286px;
    width: 425px;
  }
  
  .about .element-6 {
    height: 260px;
    left: 546px;
    position: absolute;
    top: 3595px;
    width: 713px;
  }
  
  .about .element-a {
    height: 260px;
    left: 0;
    position: absolute;
    top: 3595px;
    width: 276px;
  }
  
  .about .image-3 {
    height: 285px;
    left: 261px;
    position: absolute;
    top: 3286px;
    width: 494px;
  }
  
  .about .photo-2 {
    height: 260px;
    left: 1284px;
    position: absolute;
    top: 3595px;
    width: 138px;
  }
  
  .about .dscn {
    height: 285px;
    left: 27px;
    position: absolute;
    top: 3286px;
    width: 209px;
  }
  
  .about .rectangle-7 {
    background-color: #03133d;
    height: 285px;
    left: 1230px;
    position: absolute;
    top: 3286px;
    width: 210px;
  }
  
  .about .rectangle-8 {
    background-color: #03133d;
    height: 260px;
    left: 301px;
    position: absolute;
    top: 3595px;
    width: 220px;
  }
  