.div-15 {
  /* box-shadow: 0px 10px 50px #ffffff80; */
  height: 100px;
  position: relative;
  width: 250px;
}

.div-15 .component-83 {
  left: -18px !important;
  top: -15px !important;
}
