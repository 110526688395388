.manufacturingRM {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .manufacturingRM .div {
    background-color: #ffffff;
    height: 707px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .manufacturingRM .overlap {
    height: 253px;
    left: -18px;
    position: absolute;
    top: -54px;
    width: 338px;
  }
  
  .manufacturingRM .rectangle {
    height: 155px;
    left: 18px;
    position: absolute;
    top: 54px;
    width: 320px;
  }
  
  .manufacturingRM .text-wrapper-2 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    height: 30px;
    left: 114px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 171px;
    white-space: nowrap;
  }
  
  .manufacturingRM .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 10px;
    left: 254px;
    letter-spacing: -0.4px;
    line-height: normal;
    position: absolute;
    top: 67px;
  }
  
  .manufacturingRM .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 169px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
    width: 33px;
  }
  
  .manufacturingRM .text-wrapper-5 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 210px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .manufacturingRM .text-wrapper-6 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 112px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .manufacturingRM .component-1 {
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
  }
  
  .manufacturingRM .rectangle-2 {
    border: 6px solid;
    border-color: #fecc00;
    height: 93px;
    left: 85px;
    position: absolute;
    top: 154px;
    width: 175px;
  }
  
  .manufacturingRM .text-wrapper-7 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 11px;
    font-weight: 400;
    height: 22px;
    left: 107px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 214px;
  }
  
  .manufacturingRM .text-wrapper-8 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 10px;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 205px;
    width: 222px;
  }
  
  .manufacturingRM .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 315px;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 225px;
    width: 285px;
  }
  
  .manufacturingRM .overlap-2 {
    height: 171px;
    left: -6px;
    position: absolute;
    top: 561px;
    width: 326px;
  }
  
  .manufacturingRM .img {
    height: 141px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 224px;
  }
  
  .manufacturingRM .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .manufacturingRM .rectangle-3 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 154px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .manufacturingRM .text-wrapper-9 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .manufacturingRM .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .manufacturingRM .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .manufacturingRM .span {
    line-height: 7.6px;
  }
  
  .manufacturingRM .text-wrapper-11 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .manufacturingRM .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .manufacturingRM .element-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .manufacturingRM .line {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .manufacturingRM .image {
    left: 108px !important;
    top: 23px !important;
    width: 218px !important;
  }