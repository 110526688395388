.aboutm {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .aboutm .div {
    background-color: #ffffff;
    height: 1416px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .aboutm .overlap {
    height: 227px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 320px;
  }
  
  .aboutm .overlap-group {
    height: 227px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }
  
  .aboutm .text-wrapper {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-style: italic;
    font-weight: 300;
    left: -18px;
    letter-spacing: -0.35px;
    line-height: 5.9px;
    position: absolute;
    top: 22px;
    transform: rotate(-90deg);
    width: 51px;
  }
  
  .aboutm .UP {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Black Ops One", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 15px;
    letter-spacing: -1px;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 180px;
  }
  
  .aboutm .m {
    color: #03133d;
    font-family: "Black Ops One", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 13px;
    left: 61px;
    letter-spacing: -1.1px;
    line-height: normal;
    position: absolute;
    top: 28px;
    white-space: nowrap;
    width: 25px;
  }
  
  .aboutm .PR {
    height: 14px;
    left: 13px;
    position: absolute;
    top: 20px;
    width: 26px;
  }
  
  .aboutm .GR {
    height: 14px;
    left: 12px;
    position: absolute;
    top: 38px;
    width: 26px;
  }
  
  .aboutm .mask-group {
    height: 47px;
    left: 25px;
    position: absolute;
    top: 11px;
    width: 50px;
  }
  
  .aboutm .text-wrapper-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 105px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .aboutm .text-wrapper-3 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 159px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .aboutm .rectangle {
    background-color: #03133d;
    height: 147px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .aboutm .line {
    height: 1px;
    left: 29px;
    object-fit: cover;
    position: absolute;
    top: 160px;
    width: 125px;
  }
  
  .aboutm .text-wrapper-4 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-style: italic;
    font-weight: 500;
    left: 153px;
    letter-spacing: -0.3px;
    line-height: 4.8px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 99px;
    width: 49px;
  }
  
  .aboutm .photo {
    height: 147px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .aboutm .element {
    height: 147px;
    left: 160px;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .aboutm .text-wrapper-5 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 21px;
    font-weight: 400;
    height: 21px;
    left: 26px;
    letter-spacing: -1.05px;
    line-height: normal;
    position: absolute;
    top: 124px;
    white-space: nowrap;
  }
  
  .aboutm .rectangle-2 {
    border: 2px solid;
    border-color: #ffffff;
    height: 92px;
    left: 19px;
    position: absolute;
    top: 101px;
    width: 176px;
  }
  
  .aboutm .text-wrapper-6 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 6px;
    font-weight: 400;
    height: 6px;
    left: 33px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 167px;
    white-space: nowrap;
  }
  
  .aboutm .text-wrapper-7 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 4px;
    font-style: italic;
    font-weight: 500;
    left: 153px;
    letter-spacing: -0.2px;
    line-height: normal;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 102px;
    width: 43px;
  }
  
  .aboutm .rectangle-3 {
    background-color: #d9d9d980;
    height: 90px;
    left: 160px;
    position: absolute;
    top: 101px;
    width: 33px;
  }
  
  .aboutm .text-wrapper-8 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 200px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .aboutm .text-wrapper-9 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 234px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .aboutm .text-wrapper-10 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 13px;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 228px;
    white-space: nowrap;
    width: 227px;
  }
  
  .aboutm .text-wrapper-11 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 13px;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 270px;
    white-space: nowrap;
  }
  
  .aboutm .overlap-2 {
    height: 202px;
    left: 0;
    position: absolute;
    top: 290px;
    width: 299px;
  }
  
  .aboutm .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 300;
    height: 80px;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 283px;
  }
  
  .aboutm .rectangle-4 {
    background-color: #03133d;
    box-shadow: 0px 6px 6px #00000040;
    height: 61px;
    left: 0;
    position: absolute;
    top: 97px;
    width: 189px;
  }
  
  .aboutm .element-removebg-preview {
    height: 128px;
    left: 140px;
    object-fit: cover;
    position: absolute;
    top: 74px;
    width: 159px;
  }
  
  .aboutm .text-wrapper-12 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 42px;
    left: 22px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 90px;
  }
  
  .aboutm .text-wrapper-13 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 15px;
    font-weight: 400;
    height: 15px;
    left: 24px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 496px;
    white-space: nowrap;
    width: 117px;
  }
  
  .aboutm .text-wrapper-14 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 8px;
    left: 20px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 542px;
    white-space: nowrap;
  }
  
  .aboutm .text-wrapper-15 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 8px;
    left: 88px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 543px;
    white-space: nowrap;
  }
  
  .aboutm .text-wrapper-16 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 8px;
    left: 176px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 543px;
    white-space: nowrap;
  }
  
  .aboutm .text-wrapper-17 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 8px;
    left: 253px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 543px;
    white-space: nowrap;
  }
  
  .aboutm .text-wrapper-18 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 56px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 556px;
    width: 61px;
  }
  
  .aboutm .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 28px;
    left: 86px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 557px;
    width: 64px;
  }
  
  .aboutm .text-wrapper-20 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 119px;
    left: 173px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 556px;
    width: 63px;
  }
  
  .aboutm .text-wrapper-21 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 63px;
    left: 251px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 556px;
    width: 67px;
  }
  
  .aboutm .img {
    height: 70px;
    left: 74px;
    object-fit: cover;
    position: absolute;
    top: 558px;
    width: 1px;
  }
  
  .aboutm .line-2 {
    height: 70px;
    left: 160px;
    object-fit: cover;
    position: absolute;
    top: 558px;
    width: 1px;
  }
  
  .aboutm .line-3 {
    height: 68px;
    left: 245px;
    object-fit: cover;
    position: absolute;
    top: 558px;
    width: 1px;
  }
  
  .aboutm .overlap-3 {
    height: 26px;
    left: 9px;
    position: absolute;
    top: 689px;
    width: 65px;
  }
  
  .aboutm .rectangle-5 {
    background-color: #03133d;
    box-shadow: 0px 2px 3px 1px #00000040;
    height: 25px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 65px;
  }
  
  .aboutm .text-wrapper-22 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 25px;
    left: 6px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .aboutm .overlap-4 {
    height: 26px;
    left: 86px;
    position: absolute;
    top: 689px;
    width: 65px;
  }
  
  .aboutm .text-wrapper-23 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 25px;
    left: 7px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .aboutm .overlap-group-2 {
    height: 26px;
    left: 169px;
    position: absolute;
    top: 689px;
    width: 65px;
  }
  
  .aboutm .overlap-5 {
    height: 26px;
    left: 251px;
    position: absolute;
    top: 689px;
    width: 65px;
  }
  
  .aboutm .text-wrapper-24 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 25px;
    left: 5px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .aboutm .overlap-6 {
    height: 341px;
    left: 11px;
    position: absolute;
    top: 749px;
    width: 287px;
  }
  
  .aboutm .text-wrapper-25 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 15px;
    font-weight: 400;
    height: 15px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .aboutm .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 333px;
    left: 3px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 8px;
    width: 284px;
  }
  
  .aboutm .dscn {
    height: 66px;
    left: 9px;
    position: absolute;
    top: 1117px;
    width: 52px;
  }
  
  .aboutm .image {
    height: 66px;
    left: 64px;
    position: absolute;
    top: 1117px;
    width: 125px;
  }
  
  .aboutm .IMG {
    height: 66px;
    left: 193px;
    position: absolute;
    top: 1117px;
    width: 79px;
  }
  
  .aboutm .rectangle-6 {
    height: 66px;
    left: 278px;
    position: absolute;
    top: 1117px;
    width: 42px;
  }
  
  .aboutm .rectangle-7 {
    height: 66px;
    left: 67px;
    position: absolute;
    top: 1187px;
    width: 42px;
  }
  
  .aboutm .element-a {
    height: 66px;
    left: 0;
    position: absolute;
    top: 1187px;
    width: 63px;
  }
  
  .aboutm .element-3 {
    height: 66px;
    left: 113px;
    position: absolute;
    top: 1187px;
    width: 146px;
  }
  
  .aboutm .photo-2 {
    height: 66px;
    left: 263px;
    position: absolute;
    top: 1187px;
    width: 49px;
  }
  
  .aboutm .overlap-7 {
    height: 171px;
    left: -6px;
    position: absolute;
    top: 1267px;
    width: 326px;
  }
  
  .aboutm .rectangle-8 {
    height: 144px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 224px;
  }
  
  .aboutm .element-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .aboutm .rectangle-9 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .aboutm .text-wrapper-26 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .aboutm .text-wrapper-27 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .aboutm .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .aboutm .span {
    line-height: 7.6px;
  }
  
  .aboutm .text-wrapper-28 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .aboutm .text-wrapper-29 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .aboutm .element-5 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .aboutm .line-4 {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .aboutm .component-119 {
    left: 108px !important;
    top: 23px !important;
    width: 218px !important;
  }