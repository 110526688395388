.partnersm {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .partnersm .div {
    background-color: #ffffff;
    height: 1000px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .partnersm .overlap {
    height: 227px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 320px;
  }
  
  .partnersm .overlap-group {
    height: 227px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }
  
  .partnersm .text-wrapper {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-style: italic;
    font-weight: 300;
    left: -18px;
    letter-spacing: -0.35px;
    line-height: 5.9px;
    position: absolute;
    top: 22px;
    transform: rotate(-90deg);
    width: 51px;
  }
  
  .partnersm .UP {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Black Ops One", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 15px;
    letter-spacing: -1px;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 180px;
  }
  
  .partnersm .m {
    color: #03133d;
    font-family: "Black Ops One", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 13px;
    left: 61px;
    letter-spacing: -1.1px;
    line-height: normal;
    position: absolute;
    top: 28px;
    white-space: nowrap;
    width: 25px;
  }
  
  .partnersm .PR {
    height: 14px;
    left: 13px;
    position: absolute;
    top: 20px;
    width: 26px;
  }
  
  .partnersm .GR {
    height: 14px;
    left: 12px;
    position: absolute;
    top: 38px;
    width: 26px;
  }
  
  .partnersm .mask-group {
    height: 47px;
    left: 25px;
    position: absolute;
    top: 11px;
    width: 50px;
  }
  
  .partnersm .text-wrapper-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 105px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .partnersm .text-wrapper-3 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 159px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .partnersm .rectangle {
    background-color: #03133d;
    height: 147px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .partnersm .line {
    height: 1px;
    left: 29px;
    object-fit: cover;
    position: absolute;
    top: 160px;
    width: 125px;
  }
  
  .partnersm .text-wrapper-4 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-style: italic;
    font-weight: 500;
    left: 153px;
    letter-spacing: -0.3px;
    line-height: 4.8px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 99px;
    width: 49px;
  }
  
  .partnersm .getty {
    height: 147px;
    left: 160px;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .partnersm .text-wrapper-5 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 21px;
    font-weight: 400;
    height: 21px;
    left: 26px;
    letter-spacing: -1.05px;
    line-height: normal;
    position: absolute;
    top: 124px;
    white-space: nowrap;
  }
  
  .partnersm .rectangle-2 {
    border: 2px solid;
    border-color: #ffffff;
    height: 92px;
    left: 19px;
    position: absolute;
    top: 101px;
    width: 176px;
  }
  
  .partnersm .text-wrapper-6 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 6px;
    font-weight: 400;
    height: 6px;
    left: 33px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 167px;
    white-space: nowrap;
  }
  
  .partnersm .text-wrapper-7 {
    /* -webkit-background-clip: text !important; */
    /* -webkit-text-fill-color: transparent; */
    -webkit-text-stroke: 1px #000000;
    /* background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    ); */
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 4px;
    font-style: italic;
    font-weight: 500;
    left: 156px;
    letter-spacing: -0.2px;
    line-height: normal;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    /* text-fill-color: transparent; */
    top: 102px;
    width: 43px;
  }
  
  .partnersm .rectangle-3 {
    background-color: #d9d9d980;
    height: 94px;
    left: 160px;
    position: absolute;
    top: 101px;
    width: 37px;
  }
  
  .partnersm .img {
    height: 147px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .partnersm .text-wrapper-8 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 200px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .partnersm .text-wrapper-9 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 234px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .partnersm .p {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 11px;
    font-weight: 400;
    height: 22px;
    left: 13px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 223px;
    width: 248px;
  }
  
  .partnersm .dtek-logo {
    height: 25px;
    left: 11px;
    object-fit: cover;
    position: absolute;
    top: 276px;
    width: 74px;
  }
  
  .partnersm .logo-removebg {
    height: 33px;
    left: 159px;
    position: absolute;
    top: 272px;
    width: 74px;
  }
  
  .partnersm .text-wrapper-10 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 9px;
    left: 92px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 283px;
    white-space: nowrap;
  }
  
  .partnersm .text-wrapper-11 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 36px;
    left: 240px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 269px;
  }
  
  .partnersm .line-2 {
    height: 1px;
    left: 4px;
    object-fit: cover;
    position: absolute;
    top: 314px;
    width: 140px;
  }
  
  .partnersm .line-3 {
    height: 1px;
    left: 160px;
    object-fit: cover;
    position: absolute;
    top: 452px;
    width: 140px;
  }
  
  .partnersm .line-4 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 452px;
    width: 140px;
  }
  
  .partnersm .line-5 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 568px;
    width: 140px;
  }
  
  .partnersm .line-6 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 682px;
    width: 140px;
  }
  
  .partnersm .line-7 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 783px;
    width: 140px;
  }
  
  .partnersm .line-8 {
    height: 1px;
    left: 160px;
    object-fit: cover;
    position: absolute;
    top: 783px;
    width: 140px;
  }
  
  .partnersm .line-9 {
    height: 1px;
    left: 160px;
    object-fit: cover;
    position: absolute;
    top: 682px;
    width: 140px;
  }
  
  .partnersm .overlap-2 {
    height: 52px;
    left: 161px;
    position: absolute;
    top: 517px;
    width: 142px;
  }
  
  .partnersm .overlap-3 {
    height: 52px;
    left: 0;
    position: absolute;
    top: 0;
    width: 140px;
  }
  
  .partnersm .line-10 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 51px;
    width: 140px;
  }
  
  .partnersm .element {
    height: 52px;
    left: 3px;
    position: absolute;
    top: 0;
    width: 59px;
  }
  
  .partnersm .text-wrapper-12 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 36px;
    left: 70px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 7px;
  }
  
  .partnersm .line-11 {
    height: 1px;
    left: 164px;
    object-fit: cover;
    position: absolute;
    top: 314px;
    width: 140px;
  }
  
  .partnersm .removebg-preview {
    height: 33px;
    left: 16px;
    object-fit: cover;
    position: absolute;
    top: 401px;
    width: 39px;
  }
  
  .partnersm .text-wrapper-13 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 27px;
    left: 69px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 403px;
  }
  
  .partnersm .element-2 {
    height: 49px;
    left: 168px;
    object-fit: cover;
    position: absolute;
    top: 393px;
    width: 41px;
  }
  
  .partnersm .removebg-preview-2 {
    height: 32px;
    left: 14px;
    object-fit: cover;
    position: absolute;
    top: 525px;
    width: 50px;
  }
  
  .partnersm .text-wrapper-14 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 36px;
    left: 66px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 520px;
  }
  
  .partnersm .i {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 49px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 319px;
    width: 141px;
  }
  
  .partnersm .text-wrapper-15 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 63px;
    left: 169px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 319px;
    width: 134px;
  }
  
  .partnersm .text-wrapper-16 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 42px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 457px;
    width: 141px;
  }
  
  .partnersm .text-wrapper-17 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 42px;
    left: 168px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 456px;
    width: 143px;
  }
  
  .partnersm .text-wrapper-18 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 35px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 571px;
    width: 125px;
  }
  
  .partnersm .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 42px;
    left: 168px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 571px;
    width: 135px;
  }
  
  .partnersm .NZF-logo {
    height: 35px;
    left: 11px;
    position: absolute;
    top: 640px;
    width: 43px;
  }
  
  .partnersm .ifcem-logo-main-reg {
    height: 28px;
    left: 160px;
    object-fit: cover;
    position: absolute;
    top: 647px;
    width: 91px;
  }
  
  .partnersm .text-wrapper-20 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 27px;
    left: 58px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 643px;
  }
  
  .partnersm .text-wrapper-21 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 36px;
    left: 257px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 643px;
    width: 69px;
  }
  
  .partnersm .span-wrapper {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 49px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 685px;
    width: 123px;
  }
  
  .partnersm .span {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    letter-spacing: 0;
  }
  
  .partnersm .text-wrapper-22 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 49px;
    left: 169px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 685px;
    width: 126px;
  }
  
  .partnersm .ferrexpo-removebg {
    height: 20px;
    left: 11px;
    position: absolute;
    top: 756px;
    width: 68px;
  }
  
  .partnersm .metinvest-logo {
    height: 14px;
    left: 165px;
    object-fit: cover;
    position: absolute;
    top: 761px;
    width: 82px;
  }
  
  .partnersm .ferrexpo {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 18px;
    left: 86px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 756px;
  }
  
  .partnersm .text-wrapper-23 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 18px;
    left: 257px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 758px;
  }
  
  .partnersm .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 49px;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 790px;
    width: 121px;
  }
  
  .partnersm .overlap-4 {
    height: 171px;
    left: 0;
    position: absolute;
    top: 852px;
    width: 324px;
  }
  
  .partnersm .rectangle-4 {
    height: 143px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 218px;
  }
  
  .partnersm .element-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .partnersm .rectangle-5 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .partnersm .text-wrapper-24 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .partnersm .text-wrapper-25 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .partnersm .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .partnersm .text-wrapper-26 {
    line-height: 7.6px;
  }
  
  .partnersm .text-wrapper-27 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .partnersm .text-wrapper-28 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .partnersm .element-5 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .partnersm .line-12 {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .partnersm .image {
    left: 108px !important;
    top: 23px !important;
    width: 212px !important;
  }
  
  .partnersm .text-wrapper-29 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    height: 36px;
    left: 235px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 398px;
  }
  
  .partnersm .text-wrapper-30 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 42px;
    left: 168px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 788px;
    width: 133px;
  }