.beneficationM {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .beneficationM .div {
    background-color: #ffffff;
    height: 2023px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .beneficationM .overlap {
    height: 253px;
    left: -18px;
    position: absolute;
    top: -54px;
    width: 338px;
  }
  
  .beneficationM .rectangle {
    height: 155px;
    left: 18px;
    position: absolute;
    top: 54px;
    width: 320px;
  }
  
  .beneficationM .text-wrapper-2 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 10px;
    left: 254px;
    letter-spacing: -0.4px;
    line-height: normal;
    position: absolute;
    top: 67px;
  }
  
  .beneficationM .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 169px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
    width: 33px;
  }
  
  .beneficationM .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 210px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .beneficationM .text-wrapper-5 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 112px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .beneficationM .component-1 {
    left: 20px !important;
    position: absolute !important;
    top: 60px!important;
    width:85px;
    height: 35px;
  }
  
  .beneficationM .text-wrapper-6 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    height: 30px;
    left: 96px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 177px;
    white-space: nowrap;
  }
  
  .beneficationM .rectangle-2 {
    border: 6px solid;
    border-color: #fecc00;
    height: 99px;
    left: 82px;
    position: absolute;
    top: 144px;
    width: 182px;
  }
  
  .beneficationM .text-wrapper-7 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 39px;
    left: 104px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 202px;
    width: 178px;
  }
  
  .beneficationM .text-wrapper-8 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 10px;
    left: 42px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 205px;
    width: 222px;
  }
  
  .beneficationM .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 300;
    height: 90px;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 233px;
    width: 285px;
  }
  
  .beneficationM .overlap-2 {
    height: 153px;
    left: 13px;
    position: absolute;
    top: 342px;
    width: 309px;
  }
  
  .beneficationM .rectangle-3 {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 151px;
  }
  
  .beneficationM .img {
    height: 110px;
    left: 9px;
    position: absolute;
    top: 13px;
    width: 134px;
  }
  
  .beneficationM .rectangle-4 {
    background-color: #03133d;
    height: 38px;
    left: 122px;
    position: absolute;
    top: 7px;
    width: 143px;
  }
  
  .beneficationM .text-wrapper-9 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
    white-space: nowrap;
  }
  
  .beneficationM .text-wrapper-10 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    white-space: nowrap;
  }
  
  .beneficationM .view {
    height: 23px;
    left: 222px;
    position: absolute;
    top: 35px;
    width: 87px;
  }
  
  .beneficationM .div-wrapper {
    background-color: #fecc00;
    border-radius: 40px;
    height: 23px;
    position: relative;
    width: 85px;
  }
  
  .beneficationM .text-wrapper-11 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    left: 5px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    text-decoration: underline;
    top: 7px;
  }
  
  .beneficationM .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 155px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 54px;
  }
  
  .beneficationM .overlap-3 {
    height: 153px;
    left: 17px;
    position: absolute;
    top: 520px;
    width: 305px;
  }
  
  .beneficationM .image {
    height: 97px;
    left: 8px;
    position: absolute;
    top: 23px;
    width: 136px;
  }
  
  .beneficationM .overlap-wrapper {
    height: 23px;
    left: 218px;
    position: absolute;
    top: 34px;
    width: 87px;
  }
  
  .beneficationM .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 156px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 56px;
  }
  
  .beneficationM .overlap-4 {
    height: 153px;
    left: 15px;
    position: absolute;
    top: 876px;
    width: 286px;
  }
  
  .beneficationM .overlap-5 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 265px;
  }
  
  .beneficationM .viber {
    height: 106px;
    left: 10px;
    position: absolute;
    top: 22px;
    width: 134px;
  }
  
  .beneficationM .text-wrapper-12 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 125px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
    white-space: nowrap;
  }
  
  .beneficationM .text-wrapper-13 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 125px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    white-space: nowrap;
  }
  
  .beneficationM .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 160px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 52px;
  }
  
  .beneficationM .overlap-6 {
    height: 153px;
    left: 15px;
    position: absolute;
    top: 1053px;
    width: 271px;
  }
  
  .beneficationM .element-4 {
    height: 118px;
    left: 10px;
    position: absolute;
    top: 12px;
    width: 130px;
  }
  
  .beneficationM .text-wrapper-14 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
    width: 131px;
  }
  
  .beneficationM .element-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 155px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 49px;
  }
  
  .beneficationM .overlap-7 {
    height: 153px;
    left: 15px;
    position: absolute;
    top: 1230px;
    width: 299px;
  }
  
  .beneficationM .element-x-image {
    height: 119px;
    left: 9px;
    position: absolute;
    top: 17px;
    width: 131px;
  }
  
  .beneficationM .text-wrapper-15 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
    white-space: nowrap;
  }
  
  .beneficationM .text-wrapper-16 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    white-space: nowrap;
  }
  
  .beneficationM .element-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 158px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 49px;
  }
  
  .beneficationM .overlap-8 {
    height: 153px;
    left: 15px;
    position: absolute;
    top: 698px;
    width: 311px;
  }
  
  .beneficationM .overlap-9 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 311px;
  }
  
  .beneficationM .photo {
    height: 104px;
    left: 7px;
    position: absolute;
    top: 25px;
    width: 136px;
  }
  
  .beneficationM .overlap-group-wrapper {
    height: 23px;
    left: 224px;
    position: absolute;
    top: 29px;
    width: 87px;
  }
  
  .beneficationM .text-wrapper-17 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 500;
    left: 6px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    text-decoration: underline;
    top: 7px;
  }
  
  .beneficationM .overlap-10 {
    height: 153px;
    left: 15px;
    position: absolute;
    top: 1410px;
    width: 299px;
  }
  
  .beneficationM .element-7 {
    height: 118px;
    left: 9px;
    position: absolute;
    top: 13px;
    width: 133px;
  }
  
  .beneficationM .text-wrapper-18 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    width: 122px;
  }
  
  .beneficationM .element-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 49px;
  }
  
  .beneficationM .overlap-11 {
    height: 153px;
    left: 16px;
    position: absolute;
    top: 1588px;
    width: 299px;
  }
  
  .beneficationM .overlap-12 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 273px;
  }
  
  .beneficationM .element-vtulka {
    height: 108px;
    left: 8px;
    position: absolute;
    top: 18px;
    width: 136px;
  }
  
  .beneficationM .text-wrapper-19 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 145px;
  }
  
  .beneficationM .text-wrapper-20 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 34px;
    white-space: nowrap;
  }
  
  .beneficationM .text-wrapper-21 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 90px;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1761px;
    width: 283px;
  }
  
  .beneficationM .overlap-13 {
    height: 171px;
    left: -4px;
    position: absolute;
    top: 1876px;
    width: 324px;
  }
  
  .beneficationM .rectangle-5 {
    height: 142px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 222px;
  }
  
  .beneficationM .element-9 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .beneficationM .rectangle-6 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .beneficationM .text-wrapper-22 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .beneficationM .text-wrapper-23 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .beneficationM .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .beneficationM .span {
    line-height: 7.6px;
  }
  
  .beneficationM .text-wrapper-24 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .beneficationM .text-wrapper-25 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .beneficationM .element-10 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .beneficationM .line {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .beneficationM .component-169 {
    left: 108px !important;
    top: 23px !important;
    width: 216px !important;
  }