.dark{
    text-decoration: none;
    color:black;
    
}
.dark:hover{
    color:#fecc00
}

.light{
    text-decoration: none;
    color:white;
    
}
.light:hover{
    color:#fecc00
}

.wrp{
    margin-left: 540px;
    height: 100px;
    position: absolute;
    font-weight: 400;
    font-size: 22px;
    margin-top: 25px;
}

.wrp2{
    margin-left: 540px;
    height: 100px;
    position: absolute;
    font-weight: 400;
    font-size: 22px;
    margin-top: 65px;
}

.switcher{
    margin-left:750px;
    width: 150px;
    height: 100px;
}

.butDark{
    position: relative;
    color: white;
    margin-left: 5px;
    background-color: transparent;
    text-decoration: none;
    border: 1px solid transparent;
}

.butLight{
    position: relative;
    color: rgb(0, 0, 0);
    margin-left: 5px;
    background-color: transparent;
    text-decoration: none;
    border: 1px solid transparent;
}

.butDark:hover, .butLight:hover {
    color: #fecc00;
    border-color: #fecc00;  /* Только цвет границы меняется */
    border-radius: 4px;
}


/* .butLight:hover{
    color: #fecc00;
    border: 1px solid;
    
}

.butDark:hover{
    color: #fecc00;
    border: 1px solid;
    border-radius: 4px;
} */

.swit{
    display: inline-block;
}
/* const style = {
    textColor: "black",
    marginLeft:'540px',
    height:'100px',
    position:'absolute',
    fontWeight:'400',
    fontSize:'22px',
    textDecoration:'none',
    marginTop:'25px'
  }; */