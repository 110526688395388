.specialized {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .specialized .div {
    background-color: #ffffff;
    border: 1px none;
    height: 6224px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .specialized .view {
    height: 540px;
    left: 62px;
    position: absolute;
    top: 1422px;
    width: 1246px;
  }
  
  .specialized .overlap {
    height: 540px;
    position: relative;
    width: 1240px;
  }
  
  .specialized .overlap-group {
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1209px;
  }
  
  .specialized .rectangle {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 640px;
  }
  
  .specialized .WP {
    height: 430px;
    left: 34px;
    position: absolute;
    top: 55px;
    width: 570px;
  }
  
  .specialized .rectangle-2 {
    background-color: #03133d;
    height: 140px;
    left: 569px;
    position: absolute;
    top: 13px;
    width: 640px;
  }
  
  .specialized .text-wrapper-2 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 658px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 33px;
    width: 532px;
  }
  
  .specialized .p {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 35px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 269px;
  }
  
  .specialized .span {
    font-style: italic;
  }
  
  .specialized .text-wrapper-3 {
    color: #ffffff;
    font-style: italic;
  }
  
  .specialized .d-h {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 669px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 167px;
  }
  
  .specialized .overlap-wrapper {
    height: 540px;
    left: 63px;
    position: absolute;
    top: 2602px;
    width: 1224px;
  }
  
  .specialized .overlap-2 {
    height: 552px;
    left: -6px;
    position: relative;
    top: -2px;
  }
  
  .specialized .overlap-3 {
    height: 552px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1224px;
  }
  
  .specialized .img {
    height: 552px;
    left: 0;
    position: absolute;
    top: 0;
    width: 652px;
  }
  
  .specialized .photo {
    height: 430px;
    left: 39px;
    position: absolute;
    top: 41px;
    width: 570px;
  }
  
  .specialized .rectangle-3 {
    background-color: #03133d;
    height: 140px;
    left: 584px;
    position: absolute;
    top: 26px;
    width: 640px;
  }
  
  .specialized .div-2 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;

    font-weight: 400;
    left: 666px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 484px;
  }
  
  .specialized .text-wrapper-4 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .specialized .text-wrapper-5 {
    font-size: 20px;
  }
  
  .specialized .div-3 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 40px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 255px;
  }
  
  .specialized .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 676px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 199px;
  }
  
  .specialized .overlap-group-wrapper {
    height: 540px;
    left: 63px;
    position: absolute;
    top: 3192px;
    width: 1224px;
  }
  
  .specialized .viber {
    height: 430px;
    left: 41px;
    position: absolute;
    top: 41px;
    width: 570px;
  }
  
  .specialized .div-4 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 42px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 255px;
  }
  
  .specialized .view-2 {
    height: 540px;
    left: 63px;
    position: absolute;
    top: 3782px;
    width: 1224px;
  }
  
  .specialized .overlap-4 {
    height: 540px;
    position: relative;
    width: 1218px;
  }
  
  .specialized .overlap-5 {
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1218px;
  }
  
  .specialized .img-2 {
    height: 430px;
    left: 37px;
    position: absolute;
    top: 55px;
    width: 570px;
  }
  
  .specialized .rectangle-4 {
    background-color: #03133d;
    height: 140px;
    left: 578px;
    position: absolute;
    top: 37px;
    width: 640px;
  }
  
  .specialized .text-wrapper-6 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 640px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 40px;
    width: 562px;
  }
  
  .specialized .div-5 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 38px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 269px;
  }
  
  .specialized .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 679px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 195px;
  }
  
  .specialized .view-3 {
    height: 540px;
    left: 63px;
    position: absolute;
    top: 4372px;
    width: 1224px;
  }
  
  .specialized .rectangle-5 {
    background-color: #03133d;
    height: 140px;
    left: 578px;
    position: absolute;
    top: 32px;
    width: 640px;
  }
  
  .specialized .text-wrapper-7 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 604px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
    width: 610px;
  }
  
  .specialized .div-6 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 36px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 269px;
  }
  
  .specialized .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 667px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 181px;
    width: 551px;
  }
  
  .specialized .view-4 {
    height: 547px;
    left: 62px;
    position: absolute;
    top: 2012px;
    width: 1224px;
  }
  
  .specialized .overlap-6 {
    height: 547px;
    position: relative;
    width: 1218px;
  }
  
  .specialized .photo-2 {
    height: 430px;
    left: 35px;
    position: absolute;
    top: 55px;
    width: 570px;
  }
  
  .specialized .rectangle-6 {
    background-color: #03133d;
    height: 140px;
    left: 578px;
    position: absolute;
    top: 40px;
    width: 640px;
  }
  
  .specialized .div-7 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;

    font-weight: 400;
    left: 644px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 608px;
  }
  
  .specialized .element-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 677px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 195px;
  }
  
  .specialized .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 298px;
    left: 70px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 5005px;
    width: 1191px;
  }
  
  .specialized .view-5 {
    height: 400px;
    left: 0;
    position: absolute;
    top: 5824px;
    width: 1452px;
  }
  
  .specialized .overlap-7 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .specialized .rectangle-7 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .specialized .rectangle-8 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .specialized .text-wrapper-9 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .specialized .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .specialized .div-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .specialized .text-wrapper-11 {
    line-height: 21.8px;
  }
  
  .specialized .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .specialized .element-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .specialized .text-wrapper-13 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .specialized .element-6 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .specialized .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .specialized .line-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .specialized .image {
    left: 586px !important;
    top: 99px !important;
  }
  
  .specialized .view-6 {
    height: 415px;
    left: -15px;
    position: absolute;
    top: 5386px;
    width: 1473px;
  }
  
  .specialized .overlap-8 {
    background-image: url(../imagesSpecialized/rectangle-90.svg);
    background-size: 100% 100%;
    height: 415px;
    left: 15px;
    position: relative;
    width: 1440px;
  }
  
  .specialized .text-wrapper-14 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 91px;
    left: 96px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 185px;
    width: 1192px;
  }
  
  .specialized .text-wrapper-15 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 9px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 42px;
    white-space: nowrap;
  }
  
  .specialized .div-9 {
    height: 1389px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1448px;
  }
  
  .specialized .overlap-9 {
    height: 1109px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .specialized .rectangle-9 {
    height: 686px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .specialized .text-wrapper-16 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 70px;
    font-weight: 400;
    height: 205px;
    left: 316px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 679px;
    width: 812px;
  }
  
  .specialized .text-wrapper-17 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 170px;
    font-style: italic;
    font-weight: 900;
    height: 300px;
    left: 192px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 449px;
    width: 1014px;
  }
  
  .specialized .rectangle-10 {
    border: 40px solid;
    border-color: #fecc00;
    height: 516px;
    left: 272px;
    position: absolute;
    top: 428px;
    width: 825px;
  }
  
  .specialized .text-wrapper-18 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 109px;
    left: 480px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 1000px;
    white-space: nowrap;
    width: 525px;
  }
  
  .specialized .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 175px;
    left: 88px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1154px;
    width: 1156px;
  }
  
  .specialized .component-192 {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .specialized .image-2 {
    height: 28px;
    left: 1344px;
    position: absolute;
    top: 43px;
    width: 42px;
  }
  
  .specialized .component-193-instance {
    left: 515px !important;
    position: absolute !important;
    top: 27px !important;
  }
  
  .specialized .design-component-instance-node {
    color: #ffffff !important;
  }
  
  .specialized .view-7 {
    left: 691px !important;
    position: absolute !important;
    top: 27px !important;
  }
  
  .specialized .view-8 {
    left: 827px !important;
    position: absolute !important;
    top: 27px !important;
  }
  
  .specialized .view-9 {
    left: 942px !important;
    position: absolute !important;
    top: 27px !important;
  }
  