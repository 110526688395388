.over {
    height: 402px;
    background-image: linear-gradient(to right, #505B77 1%, #FBFAF4 6%, #FFE680 60%);
    position: relative;
    top: -2px;
    width: 100%;
  }

  .rec8 {
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }

  .rec9 {
    
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }

  .txtwpr46 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 55.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat-MediumItalic", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 1px;
    width: 816px;
  }
  
.txtwpr47 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }

  .vid4 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }

  .ele21 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }

  .txtwpr50 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }

  .li3 {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
.li4 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }

  .comp334 {
    left: 586px !important;
    top: 99px !important;
  }

.txtwpr49{
  color: #000000;
  font-family: "Montserrat-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: -1.3px;
  line-height: 26px;
  background: none;
  border: none;
  text-decoration: none;
}

.txtwpr49:hover{
  color:#fecc00
}

.ele22 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 418px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 331px;
    width: 624px;
  }