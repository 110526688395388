.heat-treatment {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .heat-treatment .div {
    background-color: #ffffff;
    border: 1px none;
    height: 4424px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .heat-treatment .overlap {
    height: 145px;
    left: 0;
    position: absolute;
    top: 1063px;
    width: 1440px;
  }
  
  .heat-treatment .rectangle {
    background-color: #03133d;
    height: 145px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .heat-treatment .p {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 71px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0px;
    width: 1215px;
  }
  
  .heat-treatment .overlap-group {
    height: 1256px;
    left: 0;
    position: absolute;
    top: 1208px;
    width: 1440px;
  }
  
  .heat-treatment .overlap-2 {
    height: 1256px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .heat-treatment .rectangle-2 {
    background-color: #03133d;
    height: 50px;
    left: 0;
    position: absolute;
    top: 689px;
    width: 1440px;
  }
  
  .heat-treatment .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 493px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 763px;
    width: 1191px;
  }
  
  .heat-treatment .span {
    font-weight: 300;
  }
  
  .heat-treatment .text-wrapper-2 {
    font-weight: 700;
  }
  
  .heat-treatment .photo {
    height: 577px;
    left: 1007px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 433px;
  }
  
  .heat-treatment .removebg-preview {
    height: 547px;
    left: 1062px;
    position: absolute;
    top: 383px;
    width: 378px;
  }
  
  .heat-treatment .group {
    height: 635px;
    left: 164px;
    position: absolute;
    top: 38px;
    width: 737px;
  }
  
  .heat-treatment .text-wrapper-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 71px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 20px;
    width: 663px;
  }
  
  .heat-treatment .text-wrapper-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 46px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 145px;
    width: 663px;
  }
  
  .heat-treatment .text-wrapper-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 46px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 240px;
    width: 663px;
  }
  
  .heat-treatment .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 46px;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 339px;
    width: 663px;
  }
  
  .heat-treatment .text-wrapper-7 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 46px;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 440px;
    width: 663px;
  }
  
  .heat-treatment .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 75px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 540px;
    width: 663px;
  }
  
  .heat-treatment .text-wrapper-9 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 40px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 20;
    width: 352px;
  }
  
  .heat-treatment .text-wrapper-10 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 40px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 120px;
    width: 215px;
  }
  
  .heat-treatment .text-wrapper-11 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 40px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 220px;
    width: 215px;
  }
  
  .heat-treatment .text-wrapper-12 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 40px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 415px;
    width: 299px;
  }
  
  .heat-treatment .text-wrapper-13 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 40px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 315px;
    width: 299px;
  }
  
  .heat-treatment .text-wrapper-14 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 40px;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 515px;
    width: 299px;
  }
  
  .heat-treatment .gear-icon-gear-icon {
    height: 31px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 3px;
    width: 48px;
  }
  
  .heat-treatment .img {
    height: 31px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 124px;
    width: 48px;
  }
  
  .heat-treatment .gear-icon-gear-icon-2 {
    height: 31px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 225px;
    width: 48px;
  }
  
  .heat-treatment .gear-icon-gear-icon-3 {
    height: 31px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 319px;
    width: 48px;
  }
  
  .heat-treatment .gear-icon-gear-icon-4 {
    height: 31px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 419px;
    width: 48px;
  }
  
  .heat-treatment .gear-icon-gear-icon-5 {
    height: 31px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 519px;
    width: 48px;
  }
  
  .heat-treatment .view {
    height: 400px;
    left: 0;
    position: absolute;
    top: 4024px;
    width: 1452px;
  }
  
  .heat-treatment .overlap-group-2 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .heat-treatment .rectangle-3 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .heat-treatment .rectangle-4 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .heat-treatment .text-wrapper-15 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
 
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .heat-treatment .text-wrapper-16 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .heat-treatment .div-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .heat-treatment .text-wrapper-17 {
    line-height: 21.8px;
  }
  
  .heat-treatment .text-wrapper-18 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .heat-treatment .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .heat-treatment .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .heat-treatment .element-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .heat-treatment .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .heat-treatment .line-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .heat-treatment .image {
    left: 586px !important;
    top: 99px !important;
  }
  
  .heat-treatment .div-4 {
    height: 1044px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1446px;
  }
  
  .heat-treatment .overlap-3 {
    height: 1044px;
    position: relative;
    width: 1440px;
  }
  
  .heat-treatment .rectangle-5 {
    height: 693px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .heat-treatment .text-wrapper-20 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 80px;
    font-weight: 400;
    height: 189px;
    left: 350px;
    letter-spacing: 0;
    line-height: 90px;
    position: absolute;
    top: 719px;
    width: 780px;
  }
  
  .heat-treatment .text-wrapper-21 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 160px;
    font-style: italic;
    font-weight: 900;
    height: 300px;
    left: 303px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 480px;
    width: 811px;
  }
  
  .heat-treatment .rectangle-6 {
    border: 40px solid;
    border-color: #fecc00;
    height: 460px;
    left: 272px;
    position: absolute;
    top: 418px;
    width: 835px;
  }
  
  .heat-treatment .text-wrapper-22 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 100px;
    left: 510px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 924px;
    white-space: nowrap;
  }
  
  .heat-treatment .component-139 {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .heat-treatment .image-2 {
    height: 28px;
    left: 1323px;
    position: absolute;
    top: 51px;
    width: 42px;
  }
  
  .heat-treatment .component-140-instance {
    left: 494px !important;
    position: absolute !important;
    top: 35px !important;
  }
  
  .heat-treatment .design-component-instance-node {
    color: #ffffff !important;
  }
  
  .heat-treatment .view-2 {
    left: 670px !important;
    position: absolute !important;
    top: 35px !important;
  }
  
  .heat-treatment .view-3 {
    left: 806px !important;
    position: absolute !important;
    top: 35px !important;
  }
  
  .heat-treatment .view-4 {
    left: 921px !important;
    position: absolute !important;
    top: 35px !important;
  }
  
  .heat-treatment .overlap-wrapper {
    height: 289px;
    left: -6px;
    position: absolute;
    top: 3722px;
    width: 1456px;
  }
  
  .heat-treatment .overlap-4 {
    height: 290px;
    left: 6px;
    position: relative;
    top: -1px;
    width: 1440px;
  }
  
  .heat-treatment .rectangle-7 {
    height: 217px;
    left: 0;
    position: absolute;
    top: 73px;
    width: 1440px;
  }
  
  .heat-treatment .text-wrapper-23 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 69px;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 151px;
    width: 1101px;
  }
  
  .heat-treatment .text-wrapper-24 {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 21px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    width: 1212px;
  }
  
  .heat-treatment .element-HRC {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 737px;
    left: 745px;
    letter-spacing: 1px;
    line-height:31px;
    position: absolute;
    top: 2910px;
    width: 639px;
  }
  
  .heat-treatment .view-5 {
    height: 415px;
    left: 153px;
    position: absolute;
    top: 2489px;
    width: 1138px;
  }
  
  .heat-treatment .overlap-5 {
    background-image: url(../imagesHeatTr/heat-treatments-for-cnc-hero-2048x1365-1.png);
    background-size: 100% 100%;
    height: 415px;
    left: 574px;
    position: absolute;
    top: 0;
    width: 560px;
  }
  
  .heat-treatment .rectangle-8 {
    background-color: #d9d9d980;
    height: 86px;
    left: 0;
    position: absolute;
    top: 329px;
    width: 560px;
  }
  
  .heat-treatment .text-wrapper-25 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 700;
    height: 47px;
    left: 182px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 357px;
    width: 240px;
  }
  
  .heat-treatment .overlap-6 {
    background-image: url(../imagesHeatTr/heat-treat-1024x763-1.png);
    background-size: 100% 100%;
    height: 415px;
    left: 0;
    position: absolute;
    top: 0;
    width: 560px;
  }
  
  .heat-treatment .text-wrapper-26 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 700;
    height: 39px;
    left: 222px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 360px;
    width: 116px;
  }
  
  .heat-treatment .view-6 {
    height: 708px;
    left: 0;
    position: absolute;
    top: 2955px;
    width: 725px;
  }
  
  .heat-treatment .element-wrapper {
    background-color: #03133d;
    height: 130px;
    left: 0;
    position: absolute;
    top: 143px;
    width: 715px;
  }
  
  .heat-treatment .element-3 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 22px;
    font-weight: 300;
    height: 79px;
    left: 134px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 373px;
  }
  
  .heat-treatment .overlap-7 {
    background-color: #03133d;
    height: 130px;
    left: 0;
    position: absolute;
    top: 288px;
    width: 715px;
  }
  
  .heat-treatment .element-4 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 22px;
    font-weight: 300;
    height: 80px;
    left: 134px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 3px;
    width: 431px;
  }
  
  .heat-treatment .overlap-8 {
    background-color: #03133d;
    height: 130px;
    left: 0;
    position: absolute;
    top: 578px;
    width: 715px;
  }
  
  .heat-treatment .text-wrapper-27 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 22px;
    font-weight: 300;
    height: 79px;
    left: 134px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 8px;
    width: 468px;
  }
  
  .heat-treatment .overlap-9 {
    background-color: #03133d;
    height: 130px;
    left: 0;
    position: absolute;
    top: 433px;
    width: 715px;
  }
  
  .heat-treatment .element-5 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 22px;
    font-weight: 300;
    height: 80px;
    left: 134px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 3px;
    width: 456px;
  }
  
  .heat-treatment .text-wrapper-28 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 36px;
    font-weight: 400;
    height: 128px;
    left: 134px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -30px;
    width: 560px;
  }
  
  .heat-treatment .rectangle-9 {
    background-color: #03133d;
    height: 415px;
    left: 0;
    position: absolute;
    top: 2489px;
    width: 140px;
  }
  
  .heat-treatment .rectangle-10 {
    background-color: #03133d;
    height: 415px;
    left: 1300px;
    position: absolute;
    top: 2489px;
    width: 140px;
  }
  