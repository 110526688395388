.serv {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.serv .div {
  background-color: #ffffff;
  border: 1px none;
  height: 2950px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.serv .view {
  height: 400px;
  left: 0;
  position: absolute;
  top: 7624px;
  width: 1452px;
}

.serv .overlap-group {
  height: 401px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 1440px;
}

.serv .rectangle {
  background: linear-gradient(180deg, rgba(254, 204, 0, 0.3) 0%, rgba(217, 217, 217, 0) 100%);
  height: 400px;
  left: 247px;
  position: absolute;
  top: 1px;
  width: 1193px;
}

.serv .rectangle-2 {
  background: linear-gradient(180deg, rgba(8.86, 46.56, 138.13, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
  height: 400px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(-180deg);
  width: 284px;
}

.serv .text-wrapper-2 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #000000;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 54.17%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 500;
  left: 289px;
  letter-spacing: -1.8px;
  line-height: 36px;
  position: absolute;
  text-align: center;
  text-fill-color: transparent;
  top: 0;
  width: 816px;
}

.serv .text-wrapper-3 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 21.8px;
  position: absolute;
  top: 271px;
  white-space: nowrap;
}

.serv .p {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 45px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 41px;
}

.serv .span {
  line-height: 21.8px;
}

.serv .text-wrapper-4 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: -1.3px;
  line-height: 26px;
}

.serv .element {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 39px;
}

.serv .text-wrapper-5 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 196px;
  white-space: nowrap;
}

.serv .element-2 {
  color: #082e8a;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 408px;
  letter-spacing: -0.9px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 350px;
  width: 624px;
}

.serv .line {
  height: 1px;
  left: -5684px;
  position: absolute;
  top: 865px;
  width: 820px;
}

.serv .img {
  height: 1px;
  left: -6022px;
  position: absolute;
  top: 528px;
  width: 1440px;
}

.serv .image {
  height: 100px !important;
  left: -5419px !important;
  top: 649px !important;
  width: 250px !important;
}

.serv .text-wrapper-6 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 300;
  height: 175px;
  left: 130px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1049px;
  width: 1173px;
}

.serv .text-wrapper-7 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 86px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1233px;
}

.serv .text-wrapper-8 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 547px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1233px;
}

.serv .text-wrapper-9 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 989px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1233px;
}

.serv .text-wrapper-10 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 1000px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1853px;
}

.serv .text-wrapper-11 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 86px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1853px;
}

.serv .text-wrapper-12 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 547px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1853px;
}

.serv .text-wrapper-13 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 300;
  left: 93px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1410px;
  width: 336px;
}

.serv .text-wrapper-14 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 300;
  left: 547px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1410px;
  width: 336px;
}

.serv .text-wrapper-15 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 300;
  left: 989px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1412px;
  width: 336px;
}

.serv .text-wrapper-16 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 300;
  left: 86px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2034px;
  width: 336px;
}

.serv .text-wrapper-17 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 300;
  left: 551px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2034px;
  width: 336px;
}

.serv .text-wrapper-18 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 300;
  left: 1000px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2034px;
  width: 336px;
}

.serv .overlap-wrapper {
  height: 60px;
  left: 111px;
  position: absolute;
  top: 1751px;
  width: 222px;
}

.serv .overlap {
  background-color: #02133c;
  border-radius: 40px;
  height: 60px;
  position: relative;
  width: 220px;
}

.serv .text-wrapper-19 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 500;
  left: 30px;
  letter-spacing: -1.3px;
  line-height: normal;
  position: absolute;
  top: 10px;
  width: 160px;
}

.serv .overlap-group-wrapper {
  height: 60px;
  left: 111px;
  position: absolute;
  top: 2345px;
  width: 222px;
}

.serv .overlap-wrapper-2 {
  height: 60px;
  left: 590px;
  position: absolute;
  top: 2345px;
  width: 222px;
}

.serv .text-wrapper-20 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 500;
  left: 34px;
  letter-spacing: -1.3px;
  line-height: normal;
  position: absolute;
  top: 10px;
  width: 160px;
}

.serv .overlap-wrapper-3 {
  height: 60px;
  left: 1029px;
  position: absolute;
  top: 2345px;
  width: 222px;
}

.serv .overlap-wrapper-4 {
  height: 60px;
  left: 590px;
  position: absolute;
  top: 1751px;
  width: 222px;
}

.serv .overlap-wrapper-5 {
  height: 60px;
  left: 1029px;
  position: absolute;
  top: 1751px;
  width: 222px;
}

.serv .view-2 {
  height: 400px;
  left: 0;
  position: absolute;
  top: 2550px;
  width: 1452px;
}

.serv .overlap-2 {
  height: 402px;
  position: relative;
  top: -2px;
  width: 1440px;
}

.serv .rectangle-3 {
  background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
  height: 400px;
  left: 247px;
  position: absolute;
  top: 2px;
  width: 1193px;
}

.serv .rectangle-4 {
  background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
  height: 400px;
  left: 0;
  position: absolute;
  top: 2px;
  transform: rotate(-180deg);
  width: 284px;
}

.serv .text-wrapper-21 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #000000;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 54.17%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 500;
  left: 289px;
  letter-spacing: -1.8px;
  line-height: 36px;
  position: absolute;
  text-align: center;
  text-fill-color: transparent;
  top: 1px;
  width: 816px;
}

.serv .text-wrapper-22 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 21.8px;
  position: absolute;
  top: 272px;
  white-space: nowrap;
}

.serv .div-2 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 67px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 58px;
}

.serv .element-3 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 40px;
}

.serv .text-wrapper-23 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 197px;
  white-space: nowrap;
}

.serv .element-4 {
  color: #03133d;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 408px;
  letter-spacing: -0.9px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 351px;
  width: 624px;
}

.serv .line-2 {
  height: 2px;
  left: 338px;
  position: absolute;
  top: 336px;
  width: 820px;
}

.serv .line-3 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.serv .component-132 {
  left: 586px !important;
  top: 99px !important;
}

.serv .overlap-3 {
  height: 758px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.serv .rectangle-5 {
  background-color: #03133d;
  height: 608px;
  left: 0;
  position: absolute;
  top: 150px;
  width: 720px;
}

.serv .rectangle-6 {
  height: 608px;
  left: 720px;
  position: absolute;
  top: 150px;
  width: 720px;
}

.serv .rectangle-7 {
  height: 608px;
  left: 0;
  position: absolute;
  top: 150px;
  width: 720px;
}

.serv .rectangle-8 {
  background-color: #d9d9d980;
  height: 365px;
  left: 720px;
  position: absolute;
  top: 274px;
  width: 183px;
}

.serv .rectangle-9 {
  border: 5px solid;
  border-color: #ffffff;
  height: 360px;
  left: 98px;
  position: absolute;
  top: 274px;
  width: 800px;
}

.serv .text-wrapper-24 {
  color: #ffffff;
  font-family: "Unbounded", Helvetica;
  font-size: 70px;
  font-weight: 400;
  height: 106px;
  left: 276px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 339px;
  width: 396px;
}

.serv .text-wrapper-25 {
  color: #ffffff;
  font-family: "Unbounded", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 85px;
  left: 167px;
  letter-spacing: 0;
  line-height: 100px;
  position: absolute;
  top: 457px;
  white-space: nowrap;
  width: 564px;
}

.serv .component-132-instance {
  height: 204px !important;
  width: 344px !important;
}

.serv .line-4 {
  height: 2px;
  left: 141px;
  position: absolute;
  top: 466px;
  width: 560px;
}

.serv .text-wrapper-26 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 54.17%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  left: 723px;
  letter-spacing: -1px;
  line-height: 20px;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  color: transparent;
  top: 272px;
  width: 169px;
}

.serv .image-2 {
  height: 28px;
  left: 1346px;
  position: absolute;
  top: 45px;
  width: 42px;
}

.serv .component-133 {
  left: 517px !important;
  position: absolute !important;
  top: 29px !important;
}

.serv .component-133-instance {
  color: #03133d !important;
}

.serv .design-component-instance-node {
  left: 693px !important;
  position: absolute !important;
  top: 29px !important;
}

.serv .view-3 {
  left: 829px !important;
  position: absolute !important;
  top: 29px !important;
}

.serv .view-4 {
  left: 944px !important;
  position: absolute !important;
  top: 29px !important;
}

.serv .text-wrapper-27 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  height: 105px;
  left: 130px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 789px;
  width: 873px;
}

.link{
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 500;
  left: 15px;
  letter-spacing: -1.3px;
  line-height: normal;
  position: absolute;
  top: 5px;
  width: 160px;
  text-decoration: none;
}

.link:hover{
  color:#fecc00;
}