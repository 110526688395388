.mining-benefication{
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .mining-benefication .div {
    background-color: #ffffff;
    border: 1px none;
    height: 7384px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .mining-benefication .view {
    height: 540px;
    left: 62px;
    position: absolute;
    top: 2012px;
    width: 1346px;
  }
  
  .mining-benefication .overlap {
    height: 540px;
    position: relative;
    width: 1342px;
  }
  
  .mining-benefication .rectangle {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 640px;
  }
  
  .mining-benefication .image {
    height: 430px;
    left: 35px;
    position: absolute;
    top: 23px;
    width: 570px;
  }
  
  .mining-benefication .rectangle-2 {
    background-color: #03133d;
    height: 140px;
    left: 569px;
    position: absolute;
    top: 19px;
    width: 640px;
  }
  
  .mining-benefication .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 658px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 172px;
  }
  
  .mining-benefication .p {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
   
    font-weight: 400;
    left: 616px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 40px;
    width: 608px;
  }
  
  .mining-benefication .span {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .mining-benefication .text-wrapper-2 {
    font-size: 20px;
  }
  
  .mining-benefication .overlap-group-wrapper {
    height: 80px;
    left: 1000px;
    position: absolute;
    top: 118px;
    width: 342px;
  }
  
  .mining-benefication .overlap-group {
    background-color: #fecc00;
    border-radius: 40px;
    height: 80px;
    position: relative;
    width: 340px;
  }
  
  .mining-benefication .text-wrapper-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    text-decoration: underline;
    top: 24px;
    width: 282px;
  }
  
  .mining-benefication .div-2 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 36px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 269px;
  }
  
  .mining-benefication .text-wrapper-4 {
    font-style: italic;
  }
  
  .mining-benefication .text-wrapper-5 {
    color: #ffffff;
    font-style: italic;
  }
  
  .mining-benefication .overlap-wrapper {
    height: 540px;
    left: 62px;
    position: absolute;
    top: 1422px;
    width: 1346px;
  }
  
  .mining-benefication .img {
    height: 430px;
    left: 35px;
    position: absolute;
    top: 55px;
    width: 570px;
  }
  
  .mining-benefication .rectangle-3 {
    background-color: #03133d;
    height: 140px;
    left: 569px;
    position: absolute;
    top: 13px;
    width: 640px;
  }
  
  .mining-benefication .div-3 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;

    font-weight: 400;
    left: 631px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 36px;
    width: 516px;
  }
  
  .mining-benefication .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 659px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 168px;
  }
  
  .mining-benefication .view-2 {
    height: 80px;
    left: 1000px;
    position: absolute;
    top: 112px;
    width: 342px;
  }
  
  .mining-benefication .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    text-decoration: underline;
    top: 24px;
    width: 281px;
  }
  
  .mining-benefication .view-3 {
    height: 540px;
    left: 62px;
    position: absolute;
    top: 3208px;
    width: 1231px;
  }
  
  .mining-benefication .overlap-2 {
    height: 552px;
    left: -6px;
    position: relative;
    top: -2px;
  }
  
  .mining-benefication .overlap-3 {
    height: 552px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1224px;
  }
  
  .mining-benefication .rectangle-4 {
    height: 552px;
    left: 0;
    position: absolute;
    top: 0;
    width: 652px;
  }
  
  .mining-benefication .viber {
    height: 430px;
    left: 41px;
    position: absolute;
    top: 73px;
    width: 570px;
  }
  
  .mining-benefication .rectangle-5 {
    background-color: #03133d;
    height: 140px;
    left: 584px;
    position: absolute;
    top: 26px;
    width: 640px;
  }
  
  .mining-benefication .div-4 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
   
    font-weight: 400;
    left: 639px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 47px;
    width: 491px;
  }
  
  .mining-benefication .div-5 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 40px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 287px;
  }
  
  .mining-benefication .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 685px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 199px;
  }
  
  .mining-benefication .view-4 {
    height: 540px;
    left: 62px;
    position: absolute;
    top: 3798px;
    width: 1224px;
  }
  
  .mining-benefication .overlap-4 {
    height: 540px;
    position: relative;
    width: 1218px;
  }
  
  .mining-benefication .overlap-5 {
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1218px;
  }
  
  .mining-benefication .rectangle-6 {
    background-color: #03133d;
    height: 140px;
    left: 578px;
    position: absolute;
    top: 37px;
    width: 640px;
  }
  
  .mining-benefication .div-6 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
  
    font-weight: 400;
    left: 629px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 562px;
  }
  
  .mining-benefication .element-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 679px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 195px;
  }
  
  .mining-benefication .view-5 {
    height: 540px;
    left: 62px;
    position: absolute;
    top: 5585px;
    width: 1326px;
  }
  
  .mining-benefication .overlap-6 {
    height: 540px;
    position: relative;
    width: 1320px;
  }
  
  .mining-benefication .rectangle-7 {
    background-color: #03133d;
    height: 140px;
    left: 578px;
    position: absolute;
    top: 32px;
    width: 640px;
  }
  
  .mining-benefication .element-5 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;

    font-weight: 400;
    left: 599px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 610px;
  }
  
  .mining-benefication .element-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 667px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 181px;
    width: 653px;
  }
  
  .mining-benefication .view-6 {
    height: 547px;
    left: 62px;
    position: absolute;
    top: 2602px;
    width: 1346px;
  }
  
  .mining-benefication .overlap-7 {
    height: 547px;
    position: relative;
    width: 1342px;
  }
  
  .mining-benefication .photo {
    height: 430px;
    left: 33px;
    position: absolute;
    top: 55px;
    width: 570px;
  }
  
  .mining-benefication .rectangle-8 {
    background-color: #03133d;
    height: 140px;
    left: 578px;
    position: absolute;
    top: 40px;
    width: 640px;
  }
  
  .mining-benefication .element-7 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 658px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 195px;
  }
  
  .mining-benefication .div-7 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
  
    font-weight: 400;
    left: 616px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
    width: 608px;
  }
  
  .mining-benefication .view-7 {
    height: 80px;
    left: 1000px;
    position: absolute;
    top: 124px;
    width: 342px;
  }
  
  .mining-benefication .text-wrapper-7 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    text-decoration: underline;
    top: 24px;
    width: 282px;
  }
  
  .mining-benefication .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 327px;
    left: 89px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 6174px;
    width: 1191px;
  }
  
  .mining-benefication .view-8 {
    height: 400px;
    left: 0;
    position: absolute;
    top: 6984px;
    width: 1452px;
  }
  
  .mining-benefication .overlap-8 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .mining-benefication .rectangle-9 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .mining-benefication .rectangle-10 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .mining-benefication .text-wrapper-9 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .mining-benefication .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .mining-benefication .div-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .mining-benefication .text-wrapper-11 {
    line-height: 21.8px;
  }
  
  .mining-benefication .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .mining-benefication .element-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .mining-benefication .text-wrapper-13 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .mining-benefication .element-9 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .mining-benefication .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .mining-benefication .line-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mining-benefication .component-253-instance {
    left: 586px !important;
    top: 99px !important;
  }
  
  .mining-benefication .view-9 {
    height: 415px;
    left: -15px;
    position: absolute;
    top: 6546px;
    width: 1473px;
  }
  
  .mining-benefication .overlap-9 {
    background-image: url(../imagesMiningBene/rectangle-90.svg);
    background-size: 100% 100%;
    height: 415px;
    left: 15px;
    position: relative;
    width: 1440px;
  }
  
  .mining-benefication .text-wrapper-14 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 143px;
    left: 100px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 175px;
    width: 1192px;
  }
  
  .mining-benefication .text-wrapper-15 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 9px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 42px;
    white-space: nowrap;
  }
  
  .mining-benefication .view-10 {
    height: 557px;
    left: 62px;
    position: absolute;
    top: 4388px;
    width: 1285px;
  }
  
  .mining-benefication .overlap-10 {
    height: 557px;
    position: relative;
    width: 1279px;
  }
  
  .mining-benefication .overlap-11 {
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1224px;
  }
  
  .mining-benefication .element-10 {
    height: 430px;
    left: 34px;
    position: absolute;
    top: 55px;
    width: 570px;
  }
  
  .mining-benefication .div-9 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 800;
    height: 1px;
    left: 35px;
    letter-spacing: 0;
    line-height: 0.1px;
    opacity: 0.5;
    position: absolute;
    top: 269px;
  }
  
  .mining-benefication .element-11 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 668px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 173px;
  }
  
  .mining-benefication .view-11 {
    height: 540px;
    left: 62px;
    position: absolute;
    top: 4995px;
    width: 1289px;
  }
  
  .mining-benefication .overlap-12 {
    height: 540px;
    position: relative;
    width: 1283px;
  }
  
  .mining-benefication .overlap-13 {
    height: 540px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1209px;
  }
  
  .mining-benefication .element-12 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    
    font-weight: 400;
    left: 602px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 45px;
    width: 595px;
  }
  
  .mining-benefication .element-13 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 669px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 181px;
  }
  
  .mining-benefication .div-10 {
    height: 1389px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1448px;
  }
  
  .mining-benefication .overlap-14 {
    height: 1109px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mining-benefication .rectangle-11 {
    height: 686px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .mining-benefication .text-wrapper-16 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 80px;
    font-weight: 400;
    height: 205px;
    left: 330px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 579px;
    width: 780px;
  }
  
  .mining-benefication .text-wrapper-17 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    position: absolute;
    font-family: "Montserrat", Helvetica;
    font-size: 180px;
    font-style: italic;
    font-weight: 900;
    height: 300px;
    left: 173px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 463px;
    width: 1014px;
  }
  
  .mining-benefication .rectangle-12 {
    border: 40px solid;
    border-color: #fecc00;
    height: 506px;
    left: 272px;
    position: absolute;
    top: 428px;
    width: 805px;
  }
  
  .mining-benefication .text-wrapper-18 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 109px;
    left: 450px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 980px;
    white-space: nowrap;
    width: 522px;
  }
  
  .mining-benefication .element-14 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 231px;
    left: 88px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1158px;
    width: 1156px;
  }
  
  .mining-benefication .image-2 {
    height: 28px;
    left: 1347px;
    position: absolute;
    top: 44px;
    width: 42px;
  }
  
  .mining-benefication .component-254 {
    left: 518px !important;
    position: absolute !important;
    top: 28px !important;
  }
  
  .mining-benefication .component-254-instance {
    color: #ffffff !important;
  }
  
  .mining-benefication .design-component-instance-node {
    left: 694px !important;
    position: absolute !important;
    top: 28px !important;
  }
  
  .mining-benefication .view-12 {
    left: 830px !important;
    position: absolute !important;
    top: 28px !important;
  }
  
  .mining-benefication .view-13 {
    left: 945px !important;
    position: absolute !important;
    top: 28px !important;
  }
  
  .mining-benefication .component-255-instance {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  