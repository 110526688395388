.design {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .design .div {
    background-color: #ffffff;
    border: 1px none;
    height: 2344px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .design .overlap {
    background-color: #d9d9d9;
    height: 400px;
    left: 0;
    position: absolute;
    top: 1100px;
    width: 720px;
  }
  
  .design .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 27px;
    font-weight: 300;
    height: 216px;
    left: 45px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 98px;
    width: 622px;
  }
  
  .design .text-wrapper-2 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 26px;
    font-weight: 500;
    height: 32px;
    left: 45px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 50px;
  }
  
  .design .overlap-group {
    height: 408px;
    left: 0;
    position: absolute;
    top: 1500px;
    width: 1440px;
  }
  
  .design .rectangle {
    background-color: #03133d;
    height: 400px;
    left: 720px;
    position: absolute;
    top: 0;
    width: 720px;
  }
  
  .design .text-wrapper-3 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 32px;
    left: 765px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 28px;
  }
  
  .design .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 200;
    height: 296px;
    left: 765px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 70px;
    width: 637px;
  }
  
  .design .raschety-na-nagruzki {
    height: 408px;
    left: 356px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 368px;
  }
  
  .design .photo {
    height: 400px;
    left: 0;
    position: absolute;
    top: 0;
    width: 360px;
  }
  
  .design .view {
    height: 400px;
    left: 0;
    position: absolute;
    top: 1944px;
    width: 1452px;
  }
  
  .design .overlap-group-2 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .design .rectangle-2 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .design .rectangle-3 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .design .text-wrapper-5 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .design .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .design .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .design .span {
    line-height: 21.8px;
  }
  
  .design .text-wrapper-7 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .design .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .design .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .design .element-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .design .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 337px;
    width: 820px;
  }
  
  .design .img {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .design .image {
    left: 586px !important;
    top: 99px !important;
  }
  
  .design .div-3 {
    height: 1044px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1446px;
  }
  
  .design .overlap-2 {
    height: 1044px;
    position: relative;
    width: 1300px;
  }
  
  .design .rectangle-4 {
    height: 768px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .design .text-wrapper-9 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 80px;
    font-weight: 400;
    height: 189px;
    left: 330px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 764px;
    width: 780px;
  }
  
  .design .text-wrapper-10 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 160px;
    font-style: italic;
    font-weight: 900;
    height: 300px;
    left: 331px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 520px;
    width: 811px;
  }
  
  .design .rectangle-5 {
    border: 40px solid;
    border-color: #fecc00;
    height: 400px;
    left: 272px;
    position: absolute;
    top: 520px;
    width: 895px;
  }
  
  .design .text-wrapper-11 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 100px;
    left: 500px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 974px;
    white-space: nowrap;
  }
  
  .design .component-74 {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .design .image-2 {
    height: 28px;
    left: 1337px;
    position: absolute;
    top: 39px;
    width: 42px;
  }
  
  .design .component-75-instance {
    left: 508px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .design .design-component-instance-node {
    color: #ffffff !important;
  }
  
  .design .view-2 {
    left: 684px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .design .view-3 {
    left: 820px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .design .view-4 {
    left: 935px !important;
    position: absolute !important;
    top: 23px !important;
  }
  
  .design .element-3 {
    height: 400px;
    left: 720px;
    position: absolute;
    top: 1100px;
    width: 360px;
  }
  
  .design .image-3 {
    height: 400px;
    left: 1080px;
    position: absolute;
    top: 1100px;
    width: 360px;
  }
  