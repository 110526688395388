.screen {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .screen .div-2 {
    background-color: #ffffff;
    border: 1px none;
    height: 8600px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .screen .overlap {
    height: 2556px;
    left: -7px;
    position: absolute;
    top: 0;
    width: 1606px;
  }
  
  .screen .view {
    height: 1026px;
    left: 0;
    position: absolute;
    top: 1530px;
    width: 1469px;
  }
  
  .screen .text-wrapper-3 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 40px;
    font-weight: 500;
    left: 76px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 564px;
    white-space: nowrap;
    width: 798px;
  }
  
  .screen .overlap-group {
    height: 339px;
    left: 0;
    position: absolute;
    top: 210px;
    width: 1453px;
  }
  
  .screen .text-wrapper-4 {
    color: #0c0c0c;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 22px;
    font-weight: 300;
    left: 81px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 553px;
  }
  
  .screen .overlap-group-wrapper {
    height: 291px;
    left: 0;
    position: absolute;
    top: 48px;
    width: 1453px;
  }
  
  .screen .overlap-group-2 {
    background-image: url(../images/rectangle-89.svg);
    background-size: 100% 100%;
    height: 291px;
    left: 7px;
    position: relative;
    width: 1440px;
  }
  
  .screen .group {
    height: 161px;
    left: 150px;
    position: absolute;
    top: 59px;
    width: 114px;
  }
  
  .screen .text-wrapper-5 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 113px;
  }
  
  .screen .element {
    height: 112px;
    left: 0;
    position: absolute;
    top: 0;
    width: 112px;
  }
  
  .screen .frameq {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 7px;
    justify-content: center;
    left: 472px;
    position: absolute;
    top: 56px;
  }
  
  .screen .element-design {
    height: 112px;
    position: relative;
    width: 112px;
  }
  
  .screen .textWarapper-6 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .screen .group-2 {
    height: 163px;
    left: 849px;
    position: absolute;
    top: 58px;
    width: 138px;
  }
  
  .screen .element-create {
    height: 112px;
    left: 16px;
    position: absolute;
    top: 0;
    width: 112px;
  }
  
  .screen .text-wrapper-7 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 115px;
  }
  
  .screen .group-3 {
    height: 166px;
    left: 1197px;
    position: absolute;
    top: 56px;
    width: 126px;
  }
  
  .screen .element-logistic {
    height: 112px;
    left: 6px;
    position: absolute;
    top: 0;
    width: 112px;
  }
  
  .screen .text-wrapper-8 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 118px;
  }
  
  .screen .line {
    height: 100px;
    left: 369px;
    object-fit: cover;
    position: absolute;
    top: 84px;
    width: 1px;
  }
  
  .screen .img {
    height: 100px;
    left: 772px;
    object-fit: cover;
    position: absolute;
    top: 84px;
    width: 1px;
  }
  
  .screen .line-2 {
    height: 100px;
    left: 1104px;
    object-fit: cover;
    position: absolute;
    top: 86px;
    width: 1px;
  }
  
  .screen .overlap-2 {
    height: 202px;
    left: 81px;
    position: absolute;
    top: 617px;
    width: 1295px;
  }
  
  .screen .text-wrapper-9 {
    color: #0c0c0c;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 22px;
    font-weight: 300;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -5px;
    width: 684px;
  }
  
  .screen .view-2 {
    height: 154px;
    left: 38px;
    position: absolute;
    top: 48px;
    width: 1257px;
  }
  
  .screen .component-332 {
    border-radius: 0px 199px 0px 0px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
  }
  
  .screen .component-332-instance {
    height: 171px !important;
    width: 277px !important;
  }
  
  .screen .design-component-instance-node {
    color: #03133d !important;
    font-family: "Unbounded-Bold", Helvetica !important;
    font-weight: 700 !important;
  }
  
  .screen .view-3 {
    color: #000000 !important;
    font-family: "Montserrat-Medium", Helvetica !important;
    font-weight: 500 !important;
  }
  
  .screen .view-4 {
    border-radius: 0px 199px 0px 0px !important;
    left: 453px !important;
    position: absolute !important;
    top: 0 !important;
    width: 302px !important;
  }
  
  .screen .view-5 {
    width: 306px !important;
  }
  
  .screen .view-6 {
    color: #03133d !important;
    font-family: "Unbounded-Bold", Helvetica !important;
    font-weight: 700 !important;
    width: 302px !important;
  }
  
  .screen .view-7 {
    border-radius: 0px 199px 0px 0px !important;
    left: 952px !important;
    position: absolute !important;
    top: 0 !important;
    width: 435px !important;
  }
  
  .screen .view-8 {
    width: 439px !important;
  }
  
  .screen .view-9 {
    color: #03133d !important;
    font-family: "Unbounded-Bold", Helvetica !important;
    font-weight: 700 !important;
    width: 435px !important;
  }
  
  .screen .text-wrapper-10 {
    color: #0c0c0c;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 22px;
    font-weight: 300;
    left: 82px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 971px;
    width: 656px;
  }
  
  .screen .text-wrapper-11 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 40px;
    font-weight: 500;
    left: 81px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 918px;
    white-space: nowrap;
    width: 798px;
  }
  
  .screen .overlap-3 {
    height: 154px;
    left: 76px;
    position: absolute;
    top: 0;
    width: 1371px;
  }
  
  .screen .text-wrapper-12 {
    color: #080808;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 80px;
    left: 0;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .screen .text-wrapper-13 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 100px;
    font-style: italic;
    font-weight: 700;
    height: 100px;
    left: 476px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    text-align: right;
    top: 54px;
    white-space: nowrap;
  }
  
  .screen .text-wrapper-14 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 40px;
    font-weight: 500;
    left: 76px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 155px;
    white-space: nowrap;
    width: 900px;
  }
  
  .screen .overlap-wrapper {
    height: 1618px;
    left: 7px;
    position: absolute;
    top: 0;
    width: 1599px;
  }
  
  .screen .overlap-4 {
    height: 1618px;
    position: relative;
    width: 1593px;
  }
  
  .screen .rectangle {
    height: 1196px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .screen .image {
    height: 28px;
    left: 1346px;
    position: absolute;
    top: 40px;
    width: 42px;
  }
  
  .screen .rectangle-2 {
    height: 80px;
    left: 422px;
    position: absolute;
    top: 0;
    width: 730px;
  }
  
  .screen .component-333-instance {
    left: 526px !important;
    position: absolute !important;
    top: 11px !important;
  }
  
  .screen .view-10 {
    color: #03133d !important;
  }
  
  .screen .view-11 {
    left: 704px !important;
    position: absolute !important;
    top: 11px !important;
  }
  
  .screen .view-12 {
    left: 840px !important;
    position: absolute !important;
    top: 11px !important;
  }
  
  .screen .view-13 {
    left: 955px !important;
    position: absolute !important;
    top: 11px !important;
  }
  
  .screen .text-wrapper-15 {
    color: #ffffff;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 96px;
    font-weight: 700;
    height: 80px;
    left: 387px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    text-shadow: 0px 4px 50px #ffffff40;
    top: 409px;
    white-space: nowrap;
  }
  
  .screen .text-wrapper-16 {
    color: #ffffff;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 80px;
    left: 322px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    text-shadow: 0px 4px 50px #ffffff40;
    top: 410px;
    transform: rotate(-90deg);
    white-space: nowrap;
  }
  
  .screen .text-wrapper-17 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 36px;
    font-weight: 300;
    height: 91px;
    left: 304px;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 491px;
    width: 902px;
  }
  
  .screen .overlap-group-wrapper-2 {
    height: 60px;
    left: 645px;
    position: absolute;
    top: 672px;
    width: 222px;
  }
  
  .screen .overlap-group-3 {
    background-color: #ffffff;
    border-radius: 40px;
    height: 60px;
    position: relative;
    width: 220px;
  }
  
  .screen .text-wrapper-18 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 28px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 13px;
    width: 164px;
  }
  
  .screen .component-335-instance {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .screen .view-14 {
    height: 320px;
    left: 67px;
    position: absolute;
    top: 1149px;
    width: 590px;
  }
  
  .screen .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 23px;
    font-weight: 300;
    height: 242px;
    left: 2px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 78px;
    width: 584px;
  }
  
  .screen .text-wrapper-20 {
    color: #000000;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 26px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 0;
    width: 572px;
  }
  
  .screen .view-15 {
    height: 367px;
    left: 737px;
    position: absolute;
    top: 1149px;
    width: 253px;
  }
  
  .screen .text-wrapper-21 {
    color: #000000;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 26px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 0;
    width: 241px;
  }
  
  .screen .overlap-5 {
    height: 289px;
    left: 0;
    position: absolute;
    top: 78px;
    width: 249px;
  }
  
  .screen .flexcontainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 289px;
    left: 0;
    position: absolute;
    top: 0;
    width: 249px;
  }
  
  .screen .text {
    align-self: stretch;
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .screen .span {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 0;
  }
  
  .screen .gear-icon-gear-icon {
    height: 23px;
    left: 85px;
    object-fit: cover;
    position: absolute;
    top: 86px;
    width: 36px;
  }
  
  .screen .gear-icon-gear-icon-2 {
    height: 23px;
    left: 85px;
    object-fit: cover;
    position: absolute;
    top: 176px;
    width: 36px;
  }
  
  .screen .gear-icon-gear-icon-3 {
    height: 23px;
    left: 85px;
    object-fit: cover;
    position: absolute;
    top: 260px;
    width: 36px;
  }
  
  .screen .overlap-wrapper-2 {
    height: 599px;
    left: 926px;
    position: absolute;
    top: 1019px;
    width: 667px;
  }
  
  .screen .overlap-6 {
    height: 615px;
    position: relative;
    top: -16px;
    width: 580px;
  }
  
  .screen .ellipse {
    border: 1px solid;
    border-color: #03133d4c;
    border-radius: 257.18px 139.52px;
    height: 279px;
    left: 33px;
    position: absolute;
    top: 211px;
    transform: rotate(36.17deg);
    width: 514px;
  }
  
  .screen .ellipse-2 {
    border: 1px solid;
    border-color: #03133d66;
    border-radius: 238.35px 114.24px;
    height: 228px;
    left: 63px;
    position: absolute;
    top: 285px;
    transform: rotate(-19.49deg);
    width: 477px;
  }
  
  .screen .element-2 {
    height: 551px;
    left: 44px;
    position: absolute;
    top: 0;
    width: 470px;
  }
  
  .screen .overlap-7 {
    height: 4588px;
    left: -38px;
    position: absolute;
    top: 3589px;
    width: 1496px;
  }
  
  .screen .view-16 {
    height: 1172px;
    left: 0;
    position: absolute;
    top: 3151px;
    width: 1482px;
  }
  
  .screen .overlap-8 {
    height: 1172px;
    position: relative;
    width: 1478px;
  }
  
  .screen .image-2 {
    height: 620px;
    left: 233px;
    object-fit: cover;
    position: absolute;
    top: 255px;
    width: 440px;
  }
  
  .screen .element-3 {
    height: 620px;
    left: 931px;
    object-fit: cover;
    position: absolute;
    top: 254px;
    width: 440px;
  }
  
  .screen .view-17 {
    height: 1172px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1478px;
  }
  
  .screen .overlap-group-4 {
    height: 1172px;
    position: relative;
  }
  
  .screen .ellipse-3 {
    border: 1px solid;
    border-color: #03133d66;
    border-radius: 713.56px 229.26px;
    height: 459px;
    left: 26px;
    position: absolute;
    top: 357px;
    transform: rotate(27.33deg);
    width: 1427px;
  }
  
  .screen .ellipse-4 {
    border: 1px solid;
    border-color: #03133d4c;
    border-radius: 713.56px 229.26px;
    height: 459px;
    left: 26px;
    position: absolute;
    top: 357px;
    transform: rotate(30.17deg);
    width: 1427px;
  }
  
  .screen .ellipse-5 {
    border: 1px solid;
    border-color: #03133d33;
    border-radius: 713.56px 229.26px;
    height: 459px;
    left: 26px;
    position: absolute;
    top: 357px;
    transform: rotate(33.62deg);
    width: 1427px;
  }
  
  .screen .ellipse-6 {
    border: 2px solid;
    border-color: #03133d80;
    border-radius: 689.81px 114.24px;
    height: 228px;
    left: 98px;
    position: absolute;
    top: 515px;
    transform: rotate(-19.49deg);
    width: 1380px;
  }
  
  .screen .ellipse-7 {
    border: 2px solid;
    border-color: #03133d4c;
    border-radius: 689.81px 114.24px;
    height: 228px;
    left: 98px;
    position: absolute;
    top: 515px;
    transform: rotate(-23.97deg);
    width: 1380px;
  }
  
  .screen .rectangle-3 {
    background-color: #03133d;
    box-shadow: 0px 4px 20px 2px #00000040;
    height: 760px;
    left: 486px;
    position: absolute;
    top: 181px;
    width: 600px;
  }
  
  .screen .image-3 {
    height: 700px;
    left: 536px;
    object-fit: cover;
    position: absolute;
    top: 211px;
    width: 500px;
  }
  
  .screen .text-wrapper-22 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 150px;
    font-style: italic;
    font-weight: 700;
    height: 100px;
    left: 786px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    text-align: right;
    top: 44px;
    white-space: nowrap;
  }
  
  .screen .text-wrapper-23 {
    color: #080808;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 80px;
    left: 123px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 55px;
    white-space: nowrap;
  }
  
  .screen .view-18 {
    height: 415px;
    left: 23px;
    position: absolute;
    top: 4173px;
    width: 1473px;
  }
  
  .screen .overlap-9 {
    background-image: url(../images/rectangle-90.svg);
    background-size: 100% 100%;
    height: 415px;
    left: 15px;
    position: relative;
    width: 1440px;
  }
  
  .screen .text-wrapper-24 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 167px;
    left: 97px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 150px;
    width: 969px;
  }
  
  .screen .text-wrapper-25 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 10px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 42px;
    white-space: nowrap;
  }
  
  .screen .overlap-group-wrapper-3 {
    height: 60px;
    left: 1154px;
    position: absolute;
    top: 201px;
    width: 222px;
  }
  
  .screen .text-wrapper-26 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 33px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 13px;
    width: 164px;
  }
  
  .screen .view-19 {
    height: 3193px;
    left: 91px;
    position: absolute;
    top: 0;
    width: 1400px;
  }
  
  .screen .element-4 {
    height: 700px;
    left: 0;
    position: absolute;
    top: 2493px;
    width: 1346px;
  }
  
  .screen .overlap-group-5 {
    height: 700px;
    position: relative;
    width: 1336px;
  }
  
  .screen .rectangle-4 {
    background-color: #03133d;
    height: 698px;
    left: 370px;
    position: absolute;
    top: 2px;
    width: 966px;
  }
  
  .screen .rectangle-5 {
    border: 40px solid;
    border-color: #fecc00;
    height: 303px;
    left: 0;
    position: absolute;
    top: 351px;
    width: 370px;
  }
  
  .screen .text-wrapper-27 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 797px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 250px;
    width: 484px;
  }
  
  .screen .text-wrapper-28 {
    color: #03133d;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 160px;
    font-weight: 400;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 402px;
  }
  
  .screen .element-5 {
    color: transparent;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 795px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .screen .text-wrapper-29 {
    color: #ffffff;
  }
  
  .screen .text-wrapper-30 {
    color: #082e8a;
  }
  
  .screen .text-wrapper-31 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 797px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 600px;
  }
  
  .screen .element-6 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 795px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 458px;
  }
  
  .screen .element-removebg {
    height: 391px;
    left: 680px;
    object-fit: cover;
    position: absolute;
    top: 190px;
    width: 638px;
  }
  
  .screen .photo {
    height: 303px;
    left: 370px;
    position: absolute;
    top: 352px;
    width: 400px;
  }
  
  .screen .element-7 {
    height: 311px;
    left: 0;
    position: absolute;
    top: 41px;
    width: 278px;
  }
  
  .screen .element-8 {
    height: 311px;
    left: 278px;
    position: absolute;
    top: 41px;
    width: 492px;
  }
  
  .screen .element-9 {
    height: 698px;
    left: 2px;
    position: absolute;
    top: 1765px;
    width: 1348px;
  }
  
  .screen .div-3 {
    background-color: #03133d;
    height: 698px;
    left: 0;
    position: absolute;
    top: 0;
    width: 966px;
  }
  
  .screen .element-10 {
    height: 441px;
    left: 2px;
    object-fit: cover;
    position: absolute;
    top: 181px;
    width: 566px;
  }
  
  .screen .element-11 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 492px;
  }
  
  .screen .text-wrapper-32 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 330px;
    width: 478px;
  }
  
  .screen .element-12 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 276px;
    width: 478px;
  }
  
  .screen .text-wrapper-33 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 380px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 588px;
  }
  
  .screen .element-13 {
    color: transparent;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
  }
  
  .screen .text-wrapper-34 {
    color: #092f8a;
  }
  
  .screen .photo-2 {
    height: 614px;
    left: 547px;
    position: absolute;
    top: 38px;
    width: 419px;
  }
  
  .screen .overlap-10 {
    border: 40px solid;
    border-color: #fecc00;
    height: 303px;
    left: 966px;
    position: absolute;
    top: 349px;
    width: 370px;
  }
  
  .screen .text-wrapper-35 {
    color: #03133d;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 160px;
    font-weight: 400;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 13px;
  }
  
  .screen .photo-3 {
    height: 311px;
    left: 966px;
    position: absolute;
    top: 38px;
    width: 370px;
  }
  
  .screen .element-14 {
    height: 735px;
    left: 0;
    position: absolute;
    top: 1000px;
    width: 1406px;
  }
  
  .screen .overlap-11 {
    height: 735px;
    position: relative;
    width: 1387px;
  }
  
  .screen .rectangle-6 {
    height: 698px;
    left: 370px;
    position: absolute;
    top: 17px;
    width: 966px;
  }
  
  .screen .rectangle-7 {
    border: 40px solid;
    border-color: #fecc00;
    height: 303px;
    left: 0;
    position: absolute;
    top: 366px;
    width: 370px;
  }
  
  .screen .element-removebg-2 {
    height: 735px;
    left: 640px;
    position: absolute;
    top: 0;
    width: 747px;
  }
  
  .screen .text-wrapper-36 {
    color: #03133d;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 160px;
    font-weight: 400;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 417px;
  }
  
  .screen .element-15 {
    color: transparent;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 795px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
  }
  
  .screen .bonfiglioli {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    left: 795px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 205px;
    width: 481px;
  }
  
  .screen .text-wrapper-37 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 795px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 626px;
  }
  
  .screen .element-16 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 795px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 471px;
  }
  
  .screen .text-wrapper-38 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 792px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 297px;
    width: 484px;
  }
  
  .screen .photo-4 {
    height: 311px;
    left: 278px;
    position: absolute;
    top: 56px;
    width: 492px;
  }
  
  .screen .photo-5 {
    height: 303px;
    left: 370px;
    object-fit: cover;
    position: absolute;
    top: 367px;
    width: 400px;
  }
  
  .screen .photo-6 {
    height: 311px;
    left: 0;
    position: absolute;
    top: 56px;
    width: 278px;
  }
  
  .screen .element-17 {
    height: 698px;
    left: 2px;
    position: absolute;
    top: 272px;
    width: 1348px;
  }
  
  .screen .overlap-12 {
    height: 698px;
    position: relative;
    width: 1336px;
  }
  
  .screen .overlap-13 {
    height: 698px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1336px;
  }
  
  .screen .element-18 {
    height: 585px;
    left: 12px;
    object-fit: cover;
    position: absolute;
    top: 67px;
    width: 582px;
  }
  
  .screen .element-19 {
    color: transparent;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .screen .text-wrapper-39 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 26px;
    font-weight: 300;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 267px;
  }
  
  .screen .text-wrapper-40 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 303px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 580px;
  }
  
  .screen .photo-7 {
    height: 311px;
    left: 844px;
    position: absolute;
    top: 38px;
    width: 492px;
  }
  
  .screen .photo-8 {
    height: 311px;
    left: 566px;
    object-fit: cover;
    position: absolute;
    top: 38px;
    width: 278px;
  }
  
  .screen .photo-9 {
    height: 303px;
    left: 566px;
    position: absolute;
    top: 349px;
    width: 400px;
  }
  
  .screen .element-20 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 56px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 501px;
  }
  
  .screen .text-wrapper-41 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 342px;
    width: 484px;
  }
  
  .screen .text-wrapper-42 {
    color: #03133d;
    font-family: "Unbounded-Regular", Helvetica;
    font-size: 160px;
    font-weight: 400;
    left: 36px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 13px;
  }
  
  .screen .text-wrapper-43 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 130px;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 104px;
    width: 1324px;
  }
  
  .screen .text-wrapper-44 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 130px;
    font-style: italic;
    font-weight: 700;
    height: 100px;
    left: 731px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    text-align: right;
    top: 4px;
    white-space: nowrap;
  }
  
  .screen .text-wrapper-45 {
    color: #080808;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 80px;
    left: 14px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .screen .view-20 {
    height: 400px;
    background-image: linear-gradient(to right, #505B77 1%, #FBFAF4 6%, #FFE680 60%);

    left: 0;
    position: absolute;
    top: 8200px;
    width: 100%;
  }
  
 


  
  .screen .text-wrapper-48 {
    line-height: 21.8px;
  }
  
.element-22 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  

  
  .screen .view-21 {
    height: 949px;
    left: 0;
    position: absolute;
    top: 2596px;
    width: 1452px;
  }
  
  .screen .overlap-15 {
    background-color: #03133d;
    height: 840px;
    left: 0;
    position: absolute;
    top: 109px;
    width: 1440px;
  }
  
  .screen .overlap-16 {
    height: 842px;
    left: 0;
    position: absolute;
    top: 83px;
    width: 1440px;
  }
  
  .screen .IMG {
    height: 620px;
    left: 0;
    position: absolute;
    top: 0;
    width: 360px;
  }
  
  .screen .IMG-2 {
    height: 620px;
    left: 360px;
    position: absolute;
    top: 0;
    width: 360px;
  }
  
  .screen .photo-10 {
    height: 620px;
    left: 1080px;
    position: absolute;
    top: 0;
    width: 360px;
  }
  
  .screen .viber {
    height: 620px;
    left: 720px;
    position: absolute;
    top: 0;
    width: 360px;
  }
  
  .screen .line-5 {
    height: 580px;
    left: 20px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 1px;
  }
  
  .screen .line-6 {
    height: 580px;
    left: 380px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 1px;
  }
  
  .screen .line-7 {
    height: 580px;
    left: 740px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 1px;
  }
  
  .screen .line-8 {
    height: 580px;
    left: 1100px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 1px;
  }
  
  .screen .text-wrapper-51 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 36px;
    font-weight: 200;
    left: 144px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    top: 406px;
    transform: rotate(-90deg);
    width: 311px;
  }
  
  .screen .text-wrapper-52 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 36px;
    font-weight: 200;
    left: 375px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    top: 280px;
    transform: rotate(-90deg);
    width: 562px;
  }
  
  .screen .text-wrapper-53 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 36px;
    font-weight: 200;
    left: 742px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    top: 284px;
    transform: rotate(-90deg);
    width: 555px;
  }
  
  .screen .text-wrapper-54 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 36px;
    font-weight: 200;
    left: 1109px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    top: 286px;
    transform: rotate(-90deg);
    width: 550px;
  }
  
  .screen .line-9 {
    height: -200px;
    left: 719px;
    object-fit: cover;
    position: absolute;
    top: 719px;
    width: 1px;
    background-color: white;
  }
  
  .screen .line-10 {
    height: 100px;
    left: 1079px;
    object-fit: cover;
    position: absolute;
    top: 719px;
    width: 1px;
  }
  
  .screen .line-11 {
    height: 100px;
    left: 359px;
    object-fit: cover;
    position: absolute;
    top: 719px;
    width: 1px;
  }
  
  .screen .overlap-group-wrapper-4 {
    height: 60px;
    left: 431px;
    position: absolute;
    top: 739px;
    width: 222px;
  }
  
  .screen .text-wrapper-55 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 37px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 164px;
  }

  .screen .text-wrapper-90 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 25px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 164px;
  }
  
  .screen .overlap-wrapper-3 {
    height: 60px;
    left: 786px;
    position: absolute;
    top: 739px;
    width: 222px;
  }
  
  .screen .overlap-wrapper-4 {
    height: 60px;
    left: 76px;
    position: absolute;
    top: 739px;
    width: 222px;
  }
  
  .screen .overlap-wrapper-5 {
    height: 60px;
    left: 1141px;
    position: absolute;
    top: 739px;
    width: 222px;
  }
  
  .screen .text-wrapper-56 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 100px;
    font-style: italic;
    font-weight: 700;
    height: 100px;
    left: 673px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    text-align: right;
    top: 4px;
    white-space: nowrap;
  }
  
  .screen .text-wrapper-57 {
    color: #080808;
    font-family: "Unbounded-Bold", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 80px;
    left: 55px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .box {
    border: 0px none;
  }
  
  .box .rectangle {
    height: 1196px;
    left: 0;
    position: fixed;
    top: 0;
    width: 1440px;
  }

  .butt {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 3px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 5px;
    width: 164px;
    border:none;
    background: none;
    text-decoration: none;
  }

  .butt:hover{
    color:#fecc00
  }

  .buttFooter {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 5px;
    
    border:none;
    background: none;
  }

  .buttFooter:hover{
    color:#fecc00
  }


  .aboutComp {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    position: absolute;
    z-index: 10;
  }
  
  .aboutComp .txt-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -1.1px;
    line-height: 70px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    text-decoration: none;
  }
  