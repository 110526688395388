.designM {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .designM .div {
    background-color: #ffffff;
    height: 594px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .designM .overlap {
    height: 253px;
    left: -18px;
    position: absolute;
    top: -54px;
    width: 338px;
  }
  
  .designM .rectangle {
    height: 155px;
    left: 18px;
    position: absolute;
    top: 54px;
    width: 320px;
  }
  
  .designM .text-wrapper-2 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    height: 30px;
    left: 114px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 171px;
    white-space: nowrap;
  }
  
  .designM .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 10px;
    left: 254px;
    letter-spacing: -0.4px;
    line-height: normal;
    position: absolute;
    top: 67px;
  }
  
  .designM .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 169px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
    width: 33px;
  }
  
  .designM .text-wrapper-5 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 210px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .designM .text-wrapper-6 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 112px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .designM .component-1 {
    margin-top: 40px;
    left: 20px;
    position: absolute !important;
    top: 20px;
    width: 80px;
    height: 32px;
  }
  
  .designM .rectangle-2 {
    border: 6px solid;
    border-color: #fecc00;
    height: 90px;
    left: 87px;
    position: absolute;
    top: 154px;
    width: 175px;
  }
  
  .designM .text-wrapper-7 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 11px;
    font-weight: 400;
    height: 22px;
    left: 106px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 215px;
  }
  
  .designM .text-wrapper-8 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 10px;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 205px;
    width: 222px;
  }
  
  .designM .overlap-2 {
    background-color: #d9d9d9;
    height: 90px;
    left: 0;
    position: absolute;
    top: 239px;
    width: 160px;
  }
  
  .designM .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 49px;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 22px;
    width: 139px;
  }
  
  .designM .text-wrapper-9 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 8px;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 8px;
    white-space: nowrap;
  }
  
  .designM .element {
    height: 90px;
    left: 160px;
    position: absolute;
    top: 239px;
    width: 80px;
  }
  
  .designM .image {
    height: 90px;
    left: 240px;
    position: absolute;
    top: 239px;
    width: 80px;
  }
  
  .designM .overlap-3 {
    height: 98px;
    left: 0;
    position: absolute;
    top: 329px;
    width: 320px;
  }
  
  .designM .photo {
    height: 90px;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px;
  }
  
  .designM .raschety-na-nagruzki {
    height: 98px;
    left: 76px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 88px;
  }
  
  .designM .rectangle-3 {
    background-color: #03133d;
    height: 90px;
    left: 160px;
    position: absolute;
    top: 0;
    width: 160px;
  }
  
  .designM .text-wrapper-10 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    height: 70px;
    left: 175px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    width: 135px;
  }
  
  .designM .text-wrapper-11 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 8px;
    left: 175px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 4px;
    transform: rotate(0.48deg);
    white-space: nowrap;
  }
  
  .designM .overlap-4 {
    height: 171px;
    left: -7px;
    position: absolute;
    top: 441px;
    width: 327px;
  }
  
  .designM .img {
    height: 148px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 225px;
  }
  
  .designM .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .designM .rectangle-4 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 154px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .designM .text-wrapper-12 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .designM .text-wrapper-13 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .designM .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .designM .span {
    line-height: 7.6px;
  }
  
  .designM .text-wrapper-14 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .designM .text-wrapper-15 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .designM .element-3 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .designM .line {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .designM .component-82 {
    margin-left: 110px;
    margin-top: 50px;
    left: 138px !important;
    top: 25px !important;
    width: 90px !important;
    height: 40px;
  }