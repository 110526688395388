.servi {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .servi .div {
    background-color: #ffffff;
    height: 870px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .servi .overlap {
    height: 233px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 320px;
  }
  
  .servi .overlap-group {
    height: 233px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }
  
  .servi .text-wrapper {
    color: #03133d;
    font-family: "Montserrat-LightItalic", Helvetica;
    font-size: 7px;
    font-style: italic;
    font-weight: 300;
    left: -18px;
    letter-spacing: -0.35px;
    line-height: 5.9px;
    position: absolute;
    top: 22px;
    transform: rotate(-90deg);
    width: 51px;
  }
  
  .servi .UP {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Black Ops One-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 15px;
    letter-spacing: -1px;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 180px;
  }
  
  .servi .m {
    color: #03133d;
    font-family: "Black Ops One-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 14px;
    left: 61px;
    letter-spacing: -1.1px;
    line-height: normal;
    position: absolute;
    top: 29px;
    white-space: nowrap;
    width: 25px;
  }
  
  .servi .PR {
    height: 14px;
    left: 13px;
    position: absolute;
    top: 20px;
    width: 26px;
  }
  
  .servi .GR {
    height: 14px;
    left: 12px;
    position: absolute;
    top: 38px;
    width: 26px;
  }
  
  .servi .mask-group {
    height: 47px;
    left: 25px;
    position: absolute;
    top: 11px;
    width: 50px;
  }
  
  .servi .text-wrapper-2 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 105px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .servi .text-wrapper-3 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 159px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .servi .rectangle {
    background-color: #03133d;
    height: 164px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .servi .img {
    height: 165px;
    left: 0;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .servi .text-wrapper-4 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 27px;
    left: 45px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 122px;
    white-space: nowrap;
    width: 100px;
  }
  
  .servi .rectangle-2 {
    height: 165px;
    left: 160px;
    position: absolute;
    top: 68px;
    width: 160px;
  }
  
  .servi .rectangle-3 {
    border: 2px solid;
    border-color: #ffffff;
    height: 92px;
    left: 19px;
    position: absolute;
    top: 101px;
    width: 176px;
  }
  
  .servi .line {
    height: 1px;
    left: 29px;
    object-fit: cover;
    position: absolute;
    top: 160px;
    width: 125px;
  }
  
  .servi .text-wrapper-5 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat-MediumItalic", Helvetica;
    font-size: 6px;
    font-style: italic;
    font-weight: 500;
    left: 153px;
    letter-spacing: -0.3px;
    line-height: 4.8px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 99px;
    width: 49px;
  }
  
  .servi .text-wrapper-6 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 200px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .servi .text-wrapper-7 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 234px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 29px;
  }
  
  .servi .p {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 12px;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 235px;
    white-space: nowrap;
  }
  
  .servi .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    height: 60px;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 268px;
    width: 280px;
  }
  
  .servi .text-wrapper-9 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 342px;
  }
  
  .servi .text-wrapper-10 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 36px;
    left: 123px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 342px;
  }
  
  .servi .text-wrapper-11 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 232px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 341px;
  }
  
  .servi .text-wrapper-12 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 13px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 383px;
    width: 87px;
  }
  
  .servi .text-wrapper-13 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 90px;
    left: 123px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 384px;
    width: 85px;
  }
  
  .servi .text-wrapper-14 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 231px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 387px;
    width: 83px;
  }
  
  .servi .overlap-group-wrapper {
    height: 23px;
    left: 6px;
    position: absolute;
    top: 500px;
    width: 72px;
  }
  
  .servi .div-wrapper {
    background-color: #02133c;
    border-radius: 40px;
    height: 23px;
    position: relative;
    width: 70px;
  }
  
  .servi .text-wrapper-15 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 7px;
    font-weight: 500;
    left: 14px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 7px;
    width: 42px;
  }
  
  .servi .overlap-wrapper {
    height: 23px;
    left: 6px;
    position: absolute;
    top: 691px;
    width: 72px;
  }
  
  .servi .overlap-wrapper-2 {
    height: 23px;
    left: 123px;
    position: absolute;
    top: 691px;
    width: 72px;
  }
  
  .servi .overlap-wrapper-3 {
    height: 23px;
    left: 240px;
    position: absolute;
    top: 691px;
    width: 72px;
  }
  
  .servi .overlap-wrapper-4 {
    height: 23px;
    left: 123px;
    position: absolute;
    top: 500px;
    width: 72px;
  }
  
  .servi .overlap-wrapper-5 {
    height: 23px;
    left: 240px;
    position: absolute;
    top: 500px;
    width: 72px;
  }
  
  .servi .text-wrapper-16 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 546px;
  }
  
  .servi .text-wrapper-17 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 121px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 546px;
  }
  
  .servi .text-wrapper-18 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 231px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 545px;
  }
  
  .servi .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 591px;
    width: 86px;
  }
  
  .servi .text-wrapper-20 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 120px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 591px;
    width: 83px;
  }
  
  .servi .text-wrapper-21 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 231px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 591px;
    width: 83px;
  }
  
  .servi .overlap-2 {
    height: 171px;
    left: -3px;
    position: absolute;
    top: 723px;
    width: 324px;
  }
  
  .servi .rectangle-4 {
    height: 142px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 221px;
  }
  
  .servi .element {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .servi .rectangle-5 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .servi .text-wrapper-22 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat-MediumItalic", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .servi .text-wrapper-23 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .servi .div-2 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .servi .span {
    line-height: 7.6px;
  }
  
  .servi .text-wrapper-24 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .servi .text-wrapper-25 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .servi .element-2 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .servi .line-2 {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .servi .image {
    left: 108px !important;
    top: 23px !important;
    width: 215px !important;
  }
  