.frame {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .frame .div {
    background-color: #ffffff;
    height: 2632px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .frame .overlap {
    height: 1192px;
    left: -18px;
    position: absolute;
    top: -54px;
    width: 422px;
  }
  
  .frame .overlap-2 {
    height: 927px;
    left: 0;
    position: absolute;
    top: 0;
    width: 422px;
  }
  
  .frame .rectangle {
    height: 294px;
    left: 18px;
    position: absolute;
    top: 54px;
    width: 320px;
  }
  
  .frame .component-1 {
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
  }
  
  .frame .text-wrapper-2 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 63px;
    left: 97px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    text-shadow: 0px 4px 50px #ffffff40;
    top: 121px;
    white-space: nowrap;
    width: 186px;
  }
  
  .frame .text-wrapper-3 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 11px;
    font-weight: 400;
    height: 56px;
    left: 60px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    text-shadow: 0px 4px 50px #ffffff40;
    top: 130px;
    transform: rotate(-90deg);
    white-space: nowrap;
    width: 24px;
  }
  
  .frame .p {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 11px;
    font-weight: 300;
    height: 76px;
    left: 43px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 184px;
    width: 271px;
  }
  
  .frame .div-2 {
    height: 622px;
    left: 150px;
    position: absolute;
    top: 243px;
    width: 58px;
  }
  
  .frame .rectangle-2 {
    background-color: #ffffff;
    border-radius: 40px;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 56px;
  }
  
  .frame .text-wrapper-4 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 8px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 4px;
    width: 45px;
  }
  
  .frame .img {
    height: 28px;
    left: 103px;
    position: absolute;
    top: 55px;
    width: 163px;
  }
  
  .frame .text-wrapper-5 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 113px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 65px;
    width: 48px;
  }
  
  .frame .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 160px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 65px;
    width: 36px;
  }
  
  .frame .text-wrapper-7 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 196px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 65px;
    width: 25px;
  }
  
  .frame .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 221px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 65px;
    width: 32px;
  }
  
  .frame .text-wrapper-9 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 27px;
    letter-spacing: 0;
    line-height: 10px;
    position: absolute;
    top: 312px;
    width: 117px;
  }
  
  .frame .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 127px;
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 353px;
    width: 143px;
  }
  
  .frame .text-wrapper-11 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 182px;
    letter-spacing: 0;
    line-height: 10px;
    position: absolute;
    top: 317px;
    width: 69px;
  }
  
  .frame .navbar {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 95px;
    left: 182px;
    position: absolute;
    top: 344px;
    width: 113px;
  }
  
  .frame .text {
    align-self: stretch;
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .frame .span {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    letter-spacing: 0;
  }
  
  .frame .gear-icon-gear-icon {
    height: 15px;
    left: 202px;
    object-fit: cover;
    position: absolute;
    top: 367px;
    width: 18px;
  }
  
  .frame .gear-icon-gear-icon-2 {
    height: 15px;
    left: 202px;
    object-fit: cover;
    position: absolute;
    top: 405px;
    width: 18px;
  }
  
  .frame .gear-icon-gear-icon-3 {
    height: 15px;
    left: 202px;
    object-fit: cover;
    position: absolute;
    top: 444px;
    width: 18px;
  }
  
  .frame .overlap-wrapper {
    height: 157px;
    left: 234px;
    position: absolute;
    top: 324px;
    width: 188px;
  }
  
  .frame .overlap-3 {
    height: 174px;
    left: -2px;
    position: relative;
    top: -16px;
    width: 163px;
  }
  
  .frame .ellipse {
    border: 1px solid;
    border-color: #03133d4c;
    border-radius: 70.74px/37.54px;
    height: 75px;
    left: 13px;
    position: absolute;
    top: 66px;
    transform: rotate(34.2deg);
    width: 141px;
  }
  
  .frame .ellipse-2 {
    border: 1px solid;
    border-color: #03133d66;
    border-radius: 66.66px/30.19px;
    height: 60px;
    left: 20px;
    position: absolute;
    top: 86px;
    transform: rotate(-18.21deg);
    width: 133px;
  }
  
  .frame .element {
    height: 174px;
    left: 0;
    position: absolute;
    top: 0;
    width: 106px;
  }
  
  .frame .text-wrapper-12 {
    color: #080808;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 50px;
    left: 27px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 471px;
    white-space: nowrap;
    width: 173px;
  }
  
  .frame .text-wrapper-13 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    height: 73px;
    left: 149px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    text-align: right;
    top: 495px;
    white-space: nowrap;
    width: 179px;
  }
  
  .frame .text-wrapper-14 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 26px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 527px;
    width: 253px;
  }
  
  .frame .text-wrapper-15 {
    color: #0c0c0c;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 26px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 543px;
    width: 214px;
  }
  
  .frame .rectangle-3 {
    height: 122px;
    left: 18px;
    position: absolute;
    top: 577px;
    width: 320px;
  }
  
  .frame .text-wrapper-16 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 9px;
    font-weight: 300;
    left: 274px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 650px;
  }
  
  .frame .element-logistic {
    height: 62px;
    left: 278px;
    position: absolute;
    top: 597px;
    width: 51px;
  }
  
  .frame .text-wrapper-17 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 9px;
    font-weight: 300;
    left: 190px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 651px;
  }
  
  .frame .element-create {
    height: 46px;
    left: 196px;
    position: absolute;
    top: 605px;
    width: 50px;
  }
  
  .frame .text-wrapper-18 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 9px;
    font-weight: 300;
    left: 99px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 650px;
    width: 74px;
  }
  
  .frame .element-design {
    height: 48px;
    left: 111px;
    position: absolute;
    top: 604px;
    width: 43px;
  }
  
  .frame .line {
    height: 30px;
    left: 91px;
    object-fit: cover;
    position: absolute;
    top: 616px;
    width: 1px;
  }
  
  .frame .line-2 {
    height: 30px;
    left: 177px;
    object-fit: cover;
    position: absolute;
    top: 615px;
    width: 1px;
  }
  
  .frame .line-3 {
    height: 30px;
    left: 261px;
    object-fit: cover;
    position: absolute;
    top: 613px;
    width: 1px;
  }
  
  .frame .text-wrapper-19 {
    color: #ffffff;
    font-family: "Inter-Light", Helvetica;
    font-size: 9px;
    font-weight: 300;
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 650px;
    width: 54px;
  }
  
  .frame .vector {
    height: 40px;
    left: 35px;
    position: absolute;
    top: 609px;
    width: 34px;
  }
  
  .frame .text-wrapper-20 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 708px;
    width: 210px;
  }
  
  .frame .text-wrapper-21 {
    color: #0c0c0c;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 723px;
    width: 256px;
  }
  
  .frame .text-wrapper-22 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 35px;
    font-weight: 400;
    left: 136px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 761px;
    white-space: nowrap;
    width: 74px;
  }
  
  .frame .text-wrapper-23 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 7px;
    font-weight: 500;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 800px;
  }
  
  .frame .text-wrapper-24 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 843px;
    white-space: nowrap;
    width: 163px;
  }
  
  .frame .text-wrapper-25 {
    color: #0c0c0c;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 849px;
    width: 292px;
  }
  
  .frame .text-wrapper-26 {
    color: #080808;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 50px;
    left: 26px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 910px;
    width: 156px;
  }
  
  .frame .overlap-4 {
    height: 57px;
    left: 25px;
    position: absolute;
    top: 761px;
    width: 87px;
  }
  
  .frame .text-wrapper-27 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 35px;
    font-weight: 400;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 59px;
  }
  
  .frame .text-wrapper-28 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 7px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 39px;
  }
  
  .frame .text-wrapper-29 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 35px;
    font-weight: 400;
    left: 225px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 761px;
    white-space: nowrap;
    width: 108px;
  }
  
  .frame .text-wrapper-30 {
    color: #000000;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 7px;
    font-weight: 500;
    left: 238px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 800px;
  }
  
  .frame .text-wrapper-31 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    height: 30px;
    left: 182px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    text-align: right;
    top: 903px;
    white-space: nowrap;
    width: 149px;
  }
  
  .frame .overlap-5 {
    height: 262px;
    left: 18px;
    position: absolute;
    top: 930px;
    width: 397px;
  }
  
  .frame .rectangle-4 {
    height: 168px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 320px;
  }
  
  .frame .overlap-group-wrapper {
    height: 33px;
    left: 6px;
    position: absolute;
    top: 139px;
    width: 141px;
  }
  
  .frame .overlap-6 {
    height: 33px;
    position: relative;
    width: 139px;
  }
  
  .frame .rectangle-5 {
    background-color: #ffffff;
    border-radius: 40px;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .frame .text-wrapper-32 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 4px;
    width: 129px;
    border:none;
    background: none;
    text-decoration: none;
  }
  
  .frame .div-wrapper {
    height: 33px;
    left: 90px;
    position: absolute;
    top: 139px;
    width: 141px;
  }
  
  .frame .overlap-wrapper-2 {
    height: 33px;
    left: 174px;
    position: absolute;
    top: 139px;
    width: 141px;
  }
  
  .frame .overlap-wrapper-3 {
    height: 33px;
    left: 256px;
    position: absolute;
    top: 139px;
    width: 141px;
  }
  
  .frame .line-4 {
    height: 95px;
    left: 6px;
    object-fit: cover;
    position: absolute;
    top: 22px;
    width: 1px;
  }
  
  .frame .line-5 {
    height: 95px;
    left: 84px;
    object-fit: cover;
    position: absolute;
    top: 24px;
    width: 1px;
  }
  
  .frame .line-6 {
    height: 95px;
    left: 164px;
    object-fit: cover;
    position: absolute;
    top: 24px;
    width: 1px;
  }
  
  .frame .line-7 {
    height: 95px;
    left: 244px;
    object-fit: cover;
    position: absolute;
    top: 24px;
    width: 1px;
  }
  
  .frame .IMG {
    height: 110px;
    left: 0;
    position: absolute;
    top: 20px;
    width: 80px;
  }
  
  .frame .text-wrapper-33 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 8px;
    font-weight: 200;
    left: 30px;
    letter-spacing: 0;
    line-height: 10px;
    position: absolute;
    top: 72px;
    transform: rotate(-90deg);
    width: 77px;
  }
  
  .frame .IMG-2 {
    height: 110px;
    left: 80px;
    position: absolute;
    top: 20px;
    width: 80px;
  }
  
  .frame .viber {
    height: 110px;
    left: 160px;
    position: absolute;
    top: 20px;
    width: 80px;
  }
  
  .frame .photo {
    height: 110px;
    left: 240px;
    position: absolute;
    top: 20px;
    width: 80px;
  }
  
  .frame .text-wrapper-34 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 8px;
    font-weight: 200;
    left: 86px;
    letter-spacing: 0;
    line-height: 10px;
    position: absolute;
    top: 50px;
    transform: rotate(-90deg);
    width: 121px;
  }
  
  .frame .text-wrapper-35 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 8px;
    font-weight: 200;
    left: 167px;
    letter-spacing: 0;
    line-height: 10px;
    position: absolute;
    top: 54px;
    transform: rotate(-90deg);
    width: 110px;
  }
  
  .frame .text-wrapper-36 {
    color: #ffffff;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 8px;
    font-weight: 200;
    left: 248px;
    letter-spacing: 0;
    line-height: 10px;
    position: absolute;
    top: 56px;
    transform: rotate(-90deg);
    width: 104px;
  }
  
  .frame .text-wrapper-37 {
    color: #080808;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 20px;
    left: 9px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 187px;
    white-space: nowrap;
  }
  
  .frame .text-wrapper-38 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    height: 30px;
    left: 209px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    text-align: right;
    top: 190px;
    white-space: nowrap;
  }
  
  .frame .text-wrapper-39 {
    color: #000000;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 130px;
    left: 7px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 210px;
    width: 302px;
  }
  
  .frame .overlap-7 {
    height: 198px;
    left: 6px;
    position: absolute;
    top: 1146px;
    width: 303px;
  }
  
  .frame .overlap-8 {
    height: 198px;
    left: 0;
    position: absolute;
    top: 0;
    width: 303px;
  }
  
  .frame .rectangle-6 {
    background-color: #03133d;
    height: 198px;
    left: 0;
    position: absolute;
    top: 0;
    width: 230px;
  }
  
  .frame .photo-2 {
    height: 61px;
    left: 136px;
    position: absolute;
    top: 82px;
    width: 94px;
  }
  
  .frame .photo-3 {
    height: 75px;
    left: 136px;
    object-fit: cover;
    position: absolute;
    top: 10px;
    width: 62px;
  }
  
  .frame .element-2 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 157px;
  }
  
  .frame .text-wrapper-40 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
    width: 126px;
  }
  
  .frame .text-wrapper-41 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 9px;
    font-weight: 300;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 80px;
  }
  
  .frame .element-3 {
    color: transparent;
    font-family: "?????-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1px;
  }
  
  .frame .text-wrapper-42 {
    color: #ffffff;
  }
  
  .frame .text-wrapper-43 {
    color: #082e8a;
  }
  
  .frame .element-4 {
    height: 156px;
    left: 10px;
    object-fit: cover;
    position: absolute;
    top: 11px;
    width: 168px;
  }
  
  .frame .text-wrapper-44 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 106px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 163px;
  }
  
  .frame .photo-4 {
    height: 75px;
    left: 195px;
    position: absolute;
    top: 10px;
    width: 108px;
  }
  
  .frame .overlap-9 {
    border: 5px solid;
    border-color: #fecc00;
    height: 58px;
    left: 230px;
    position: absolute;
    top: 85px;
    width: 73px;
  }
  
  .frame .text-wrapper-45 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 30px;
    font-weight: 400;
    left: 3px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -1px;
    white-space: nowrap;
    width: 45px;
  }
  
  .frame .overlap-10 {
    height: 202px;
    left: 6px;
    position: absolute;
    top: 1353px;
    width: 315px;
  }
  
  .frame .overlap-11 {
    height: 202px;
    left: 0;
    position: absolute;
    top: 0;
    width: 315px;
  }
  
  .frame .rectangle-7 {
    height: 198px;
    left: 77px;
    position: absolute;
    top: 4px;
    width: 230px;
  }
  
  .frame .element-5 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 151px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 137px;
  }
  
  .frame .text-wrapper-46 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 151px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 76px;
    width: 156px;
  }
  
  .frame .bonfiglioli {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 9px;
    font-weight: 500;
    left: 151px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 58px;
    width: 164px;
  }
  
  .frame .element-6 {
    color: transparent;
    font-family: "?????-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 152px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 11px;
  }
  
  .frame .element-removebg {
    height: 178px;
    left: 104px;
    position: absolute;
    top: 0;
    width: 188px;
  }
  
  .frame .text-wrapper-47 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 220px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 173px;
  }
  
  .frame .photo-5 {
    height: 59px;
    left: 56px;
    position: absolute;
    top: 24px;
    width: 91px;
  }
  
  .frame .photo-6 {
    height: 59px;
    left: 0;
    position: absolute;
    top: 24px;
    width: 58px;
  }
  
  .frame .photo-7 {
    height: 62px;
    left: 58px;
    object-fit: cover;
    position: absolute;
    top: 81px;
    width: 89px;
  }
  
  .frame .overlap-12 {
    border: 5px solid;
    border-color: #fecc00;
    height: 50px;
    left: 0;
    position: absolute;
    top: 83px;
    width: 50px;
  }
  
  .frame .text-wrapper-48 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 30px;
    font-weight: 400;
    left: 13px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -4px;
    white-space: nowrap;
  }
  
  .frame .overlap-13 {
    height: 209px;
    left: 6px;
    position: absolute;
    top: 1572px;
    width: 304px;
  }
  
  .frame .rectangle-8 {
    border: 5px solid;
    border-color: #fecc00;
    height: 52px;
    left: 234px;
    position: absolute;
    top: 74px;
    width: 60px;
  }
  
  .frame .rectangle-9 {
    height: 209px;
    left: 0;
    position: absolute;
    top: 0;
    width: 230px;
  }
  
  .frame .photo-8 {
    height: 123px;
    left: 147px;
    position: absolute;
    top: 13px;
    width: 87px;
  }
  
  .frame .element-7 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 157px;
  }
  
  .frame .element-8 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 9px;
    font-weight: 300;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 80px;
    width: 158px;
  }
  
  .frame .element-9 {
    color: transparent;
    font-family: "?????-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 3px;
  }
  
  .frame .text-wrapper-49 {
    color: #092f8a;
  }
  
  .frame .element-10 {
    height: 146px;
    left: 26px;
    object-fit: cover;
    position: absolute;
    top: 23px;
    width: 172px;
  }
  
  .frame .text-wrapper-50 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 89px;
    width: 136px;
  }
  
  .frame .text-wrapper-51 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 111px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 179px;
  }
  
  .frame .text-wrapper-52 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 30px;
    font-weight: 400;
    left: 241px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 79px;
    white-space: nowrap;
  }
  
  .frame .photo-9 {
    height: 62px;
    left: 232px;
    position: absolute;
    top: 13px;
    width: 72px;
  }
  
  .frame .overlap-14 {
    height: 672px;
    left: -20px;
    position: absolute;
    top: 1798px;
    width: 340px;
  }
  
  .frame .overlap-15 {
    height: 672px;
    left: 0;
    position: absolute;
    top: 0;
    width: 340px;
  }
  
  .frame .rectangle-10 {
    height: 209px;
    left: 103px;
    position: absolute;
    top: 0;
    width: 230px;
  }
  
  .frame .photo-10 {
    height: 52px;
    left: 85px;
    position: absolute;
    top: 76px;
    width: 86px;
  }
  
  .frame .text-wrapper-53 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 8px;
    font-weight: 300;
    left: 282px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 180px;
  }
  
  .frame .element-11 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 175px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 135px;
  }
  
  .frame .text-wrapper-54 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    left: 176px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 65px;
    width: 149px;
  }
  
  .frame .element-12 {
    color: transparent;
    font-family: "?????-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 177px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 7px;
  }
  
  .frame .element-13 {
    height: 60px;
    left: 79px;
    position: absolute;
    top: 18px;
    width: 92px;
  }
  
  .frame .element-14 {
    height: 60px;
    left: 25px;
    position: absolute;
    top: 18px;
    width: 56px;
  }
  
  .frame .text-wrapper-55 {
    color: #080808;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 10px;
    left: 30px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 225px;
    white-space: nowrap;
  }
  
  .frame .view {
    height: 315px;
    left: 0;
    position: absolute;
    top: 226px;
    width: 340px;
  }
  
  .frame .overlap-16 {
    height: 315px;
    position: relative;
  }
  
  .frame .image {
    height: 167px;
    left: 54px;
    object-fit: cover;
    position: absolute;
    top: 68px;
    width: 101px;
  }
  
  .frame .element-15 {
    height: 167px;
    left: 214px;
    object-fit: cover;
    position: absolute;
    top: 68px;
    width: 101px;
  }
  
  .frame .view-2 {
    height: 315px;
    left: 0;
    position: absolute;
    top: 0;
    width: 340px;
  }
  
  .frame .overlap-group-2 {
    height: 306px;
    left: -7px;
    position: relative;
    top: 5px;
    width: 353px;
  }
  
  .frame .ellipse-3 {
    border: 1px solid;
    border-color: #03133d66;
    border-radius: 170.31px/59.85px;
    height: 120px;
    left: 6px;
    position: absolute;
    top: 93px;
    transform: rotate(31.12deg);
    width: 341px;
  }
  
  .frame .ellipse-4 {
    border: 1px solid;
    border-color: #03133d4c;
    border-radius: 171.52px/59.5px;
    height: 119px;
    left: 5px;
    position: absolute;
    top: 93px;
    transform: rotate(34.19deg);
    width: 343px;
  }
  
  .frame .ellipse-5 {
    border: 1px solid;
    border-color: #03133d33;
    border-radius: 173.06px/59.03px;
    height: 118px;
    left: 4px;
    position: absolute;
    top: 94px;
    transform: rotate(37.85deg);
    width: 346px;
  }
  
  .frame .ellipse-6 {
    border: 1px solid;
    border-color: #03133d80;
    border-radius: 161.84px/30.24px;
    height: 60px;
    left: 26px;
    position: absolute;
    top: 134px;
    transform: rotate(-22.47deg);
    width: 324px;
  }
  
  .frame .ellipse-7 {
    border: 1px solid;
    border-color: #03133d4c;
    border-radius: 163.36px/30.02px;
    height: 60px;
    left: 25px;
    position: absolute;
    top: 134px;
    transform: rotate(-27.45deg);
    width: 327px;
  }
  
  .frame .rectangle-11 {
    background-color: #03133d;
    box-shadow: 0px 4px 20px 2px #00000040;
    height: 204px;
    left: 112px;
    position: absolute;
    top: 49px;
    width: 138px;
  }
  
  .frame .image-2 {
    height: 188px;
    left: 123px;
    object-fit: cover;
    position: absolute;
    top: 57px;
    width: 115px;
  }
  
  .frame .text-wrapper-56 {
    -webkit-text-stroke: 1px #03133d33;
    color: transparent;
    font-family: "Montserrat-BoldItalic", Helvetica;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    height: 100px;
    left: 242px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    text-align: right;
    top: 199px;
    white-space: nowrap;
  }
  
  .frame .rectangle-12 {
    height: 160px;
    left: 20px;
    position: absolute;
    top: 512px;
    width: 320px;
  }
  
  .frame .text-wrapper-57 {
    color: #ffffff;
    font-family: "Montserrat-Light", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 99px;
    left: 33px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 554px;
    width: 226px;
  }
  
  .frame .text-wrapper-58 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 25px;
    font-weight: 700;
    height: 30px;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 528px;
    white-space: nowrap;
  }
  
  .frame .overlap-17 {
    border: 5px solid;
    border-color: #fecc00;
    height: 40px;
    left: 25px;
    position: absolute;
    top: 78px;
    width: 50px;
  }
  
  .frame .text-wrapper-59 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 30px;
    font-weight: 400;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -3px;
    white-space: nowrap;
  }
  
  .frame .overlap-18 {
    height: 172px;
    left: -9px;
    position: absolute;
    top: 2481px;
    width: 329px;
  }
  
  .frame .rectangle-13 {
    height: 148px;
    left: 102px;
    position: absolute;
    top: 3px;
    width: 227px;
  }
  
  .frame .rectangle-14 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 149px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .frame .text-wrapper-60 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat-MediumItalic", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 30px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 25px;
    width: 259px;
  }
  
  .frame .text-wrapper-61 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 239px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 107px;
    width: 85px;
  }
  
  .frame .div-3 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 16px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 27px;
    width: 62px;
  }
  
  .frame .text-wrapper-62 {
    line-height: 7.6px;
  }
  
  .frame .text-wrapper-63 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .frame .element-16 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 244px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 24px;
    width: 75px;
  }
  
  .frame .text-wrapper-64 {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 228px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 75px;
    width: 101px;
  }
  
  .frame .element-17 {
    color: #03133d;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 10px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 131px;
    width: 319px;
  }
  
  .frame .line-8 {
    height: 2px;
    left: 78px;
    position: absolute;
    top: 129px;
    width: 175px;
  }
  
  .frame .line-9 {
    height: 2px;
    left: 9px;
    position: absolute;
    top: 0;
    width: 320px;
  }
  
  .frame .component-310 {
    left: 113px !important;
    top: 24px !important;
    width: 216px !important;
  }
  
  .butt {
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 3px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 5px;
    width: 164px;
    border:none;
    background: none;
    text-decoration: none;
  }