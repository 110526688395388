.grinding-tr {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .grinding-tr .div {
    background-color: #ffffff;
    border: 1px none;
    height: 3020px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .grinding-tr .overlap {
    height: 997px;
    left: 0;
    position: absolute;
    top: 1079px;
    width: 1440px;
  }
  
  .grinding-tr .element-HRC-ra {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 712px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 1191px;
  }
  
  .grinding-tr .rectangle {
    background-color: #03133d;
    height: 238px;
    left: 0;
    position: absolute;
    top: 749px;
    width: 1440px;
  }
  
  .grinding-tr .element-l-m {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 22px;
    font-weight: 300;
    height: 130px;
    left: 736px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 786px;
    width: 297px;
  }
  
  .grinding-tr .l-b-h-m {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 22px;
    font-weight: 300;
    height: 157px;
    left: 1086px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 787px;
    width: 257px;
  }
  
  .grinding-tr .rectangle-2 {
    border: 1px solid;
    border-color: #ffffff;
    height: 170px;
    left: 726px;
    position: absolute;
    top: 787px;
    width: 317px;
  }
  
  .grinding-tr .rectangle-3 {
    border: 1px solid;
    border-color: #ffffff;
    height: 170px;
    left: 1056px;
    position: absolute;
    top: 787px;
    width: 317px;
  }
  
  .grinding-tr .p {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 28px;
    font-weight: 400;
    height: 128px;
    left: 75px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 776px;
    width: 384px;
  }
  
  .grinding-tr .view {
    height: 311px;
    left: 455px;
    position: absolute;
    top: 686px;
    width: 271px;
  }
  
  .grinding-tr .overlap-group {
    height: 311px;
    position: relative;
  }
  
  .grinding-tr .photo {
    height: 281px;
    left: 0;
    position: absolute;
    top: 0;
    width: 146px;
  }
  
  .grinding-tr .img {
    height: 306px;
    left: 66px;
    position: absolute;
    top: 5px;
    width: 205px;
  }
  
  .grinding-tr .text-wrapper-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 141px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2103px;
    width: 1191px;
  }
  
  .grinding-tr .overlap-group-wrapper {
    height: 400px;
    left: 0;
    position: absolute;
    top: 2620px;
    width: 1452px;
  }
  
  .grinding-tr .overlap-group-2 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .grinding-tr .rectangle-4 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .grinding-tr .rectangle-5 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .grinding-tr .text-wrapper-3 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .grinding-tr .text-wrapper-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .grinding-tr .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .grinding-tr .span {
    line-height: 21.8px;
  }
  
  .grinding-tr .text-wrapper-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .grinding-tr .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .grinding-tr .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .grinding-tr .element-2 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .grinding-tr .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .grinding-tr .line-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .grinding-tr .image {
    left: 586px !important;
    top: 99px !important;
  }
  
  .grinding-tr .div-3 {
    height: 1044px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1446px;
  }
  
  .grinding-tr .overlap-2 {
    height: 1044px;
    position: relative;
    width: 1440px;
  }
  
  .grinding-tr .rectangle-6 {
    height: 693px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .grinding-tr .text-wrapper-7 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 80px;
    font-weight: 400;
    height: 189px;
    left: 330px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 719px;
    width: 780px;
  }
  
  .grinding-tr .text-wrapper-8 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 170px;
    font-style: italic;
    font-weight: 900;
    height: 300px;
    left: 313px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 477px;
    width: 811px;
  }
  
  .grinding-tr .rectangle-7 {
    border: 40px solid;
    border-color: #fecc00;
    height: 470px;
    left: 272px;
    position: absolute;
    top: 418px;
    width: 835px;
  }
  
  .grinding-tr .text-wrapper-9 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 100px;
    left: 500px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 934px;
    white-space: nowrap;
  }
  
  .grinding-tr .component-84 {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .grinding-tr .image-2 {
    height: 28px;
    left: 1337px;
    position: absolute;
    top: 56px;
    width: 42px;
  }
  
  .grinding-tr .component-85-instance {
    left: 508px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .grinding-tr .design-component-instance-node {
    color: #ffffff !important;
  }
  
  .grinding-tr .view-2 {
    left: 684px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .grinding-tr .view-3 {
    left: 820px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .grinding-tr .view-4 {
    left: 935px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .grinding-tr .overlap-wrapper {
    height: 289px;
    left: -6px;
    position: absolute;
    top: 2313px;
    width: 1456px;
  }
  
  .grinding-tr .overlap-3 {
    height: 290px;
    left: 6px;
    position: relative;
    top: -1px;
    width: 1440px;
  }
  
  .grinding-tr .rectangle-8 {
    height: 217px;
    left: 0;
    position: absolute;
    top: 73px;
    width: 1440px;
  }
  
  .grinding-tr .text-wrapper-10 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 69px;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 151px;
    width: 1101px;
  }
  
  .grinding-tr .text-wrapper-11 {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 21px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    width: 1212px;
  }
  