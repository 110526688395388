.crf{
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .crf .dive {
    background-color: #ffffff;
    border: 1px none;
    height: 3850px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .crf .view {
    height: 400px;
    left: 0;
    position: absolute;
    top: 7624px;
    width: 1452px;
  }
  
  .crf .overlap-group {
    height: 401px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 1440px;
  }
  
  .crf .rectangle {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.3) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 1px;
    width: 1193px;
  }
  
  .crf .rectangle-2 {
    background: linear-gradient(180deg, rgba(8.86, 46.56, 138.13, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 1px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .crf .text-wrapper-2 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 0;
    width: 816px;
  }
  
  .crf .text-wrapper-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 271px;
    white-space: nowrap;
  }
  
  .crf .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 45px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 41px;
  }
  
  .crf .span {
    line-height: 21.8px;
  }
  
  .crf .text-wrapper-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .crf .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 39px;
  }
  
  .crf .text-wrapper-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 196px;
    white-space: nowrap;
  }
  
  .crf .element-2 {
    color: #082e8a;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 350px;
    width: 624px;
  }
  
  .crf .line {
    height: 1px;
    left: -11604px;
    position: absolute;
    top: 865px;
    width: 820px;
  }
  
  .crf .img {
    height: 1px;
    left: -11942px;
    position: absolute;
    top: 528px;
    width: 1440px;
  }
  
  .crf .image {
    height: 100px !important;
    left: -11339px !important;
    top: 649px !important;
    width: 250px !important;
  }
  
  .crf .ISO {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 480px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 959px;
    width: 1288px;
  }
  
  .crf .overlap {
    height: 837px;
    left: -116px;
    position: absolute;
    top: 2544px;
    width: 1657px;
  }
  
  .crf .rectangle-3 {
    background-color: #03133d;
    height: 640px;
    left: 29px;
    position: absolute;
    top: 115px;
    transform: rotate(-6deg);
    width: 1599px;
  }
  
  .crf .image-2 {
    height: 830px;
    left: 207px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 590px;
  }
  
  .crf .image-3 {
    height: 830px;
    left: 885px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 590px;
  }
  
  .crf .overlap-2 {
    height: 870px;
    left: -145px;
    position: absolute;
    top: 1558px;
    width: 1676px;
  }
  
  .crf .rectangle-4 {
    background-color: #03133d;
    height: 640px;
    left: 29px;
    position: absolute;
    top: 97px;
    transform: rotate(6deg);
    width: 1618px;
  }
  
  .crf .image-4 {
    height: 870px;
    left: 222px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 630px;
  }
  
  .crf .image-5 {
    height: 870px;
    left: 900px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 630px;
  }
  
  .crf .overlap-wrapper {
    height: 400px;
    left: 0;
    position: absolute;
    top: 3450px;
    width: 1452px;
  }
  
  .crf .overlap-3 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .crf .rectangle-5 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .crf .rectangle-6 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .crf .text-wrapper-6 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
   
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .crf .text-wrapper-7 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .crf .dive-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .crf .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .crf .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .crf .element-4 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .crf .line-2 {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .crf .line-3 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .crf .component-92 {
    left: 586px !important;
    top: 99px !important;
  }
  
  .crf .overlap-4 {
    height: 758px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .crf .iso {
    height: 608px;
    left: 720px;
    position: absolute;
    top: 150px;
    width: 720px;
  }
  
  .crf .rectangle-7 {
    background-color: #03133d;
    height: 608px;
    left: 0;
    position: absolute;
    top: 150px;
    width: 720px;
  }
  
  .crf .iso-2 {
    height: 608px;
    left: 0;
    position: absolute;
    top: 150px;
    width: 720px;
  }
  
  .crf .component-92-instance {
    height: 204px !important;
    width: 343px !important;
  }
  
  .crf .text-wrapper-9 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 100px;
    left: 200px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 453px;
    white-space: nowrap;
  }
  
  .crf .text-wrapper-10 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 70px;
    font-weight: 400;
    height: 129px;
    left: 200px;
    letter-spacing: -3.5px;
    line-height: normal;
    position: absolute;
    top: 341px;
    width: 567px;
  }
  
  .crf .rectangle-8 {
    background-color: #d9d9d980;
    height: 360px;
    left: 720px;
    position: absolute;
    top: 279px;
    width: 183px;
  }
  
  .crf .rectangle-9 {
    border: 5px solid;
    border-color: #ffffff;
    height: 360px;
    left: 98px;
    position: absolute;
    top: 274px;
    width: 800px;
  }
  
  .crf .line-4 {
    height: 2px;
    left: 138px;
    position: absolute;
    top: 477px;
    width: 560px;
  }
  
  .crf .text-wrapper-11 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    left: 719px;
    letter-spacing: -1px;
    line-height: 20px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    color: transparent;
    top: 272px;
    width: 169px;
  }
  
  .crf .image-6 {
    height: 28px;
    left: 1336px;
    position: absolute;
    top: 48px;
    width: 42px;
  }
  
  .crf .component-93 {
    left: 507px !important;
    position: absolute !important;
    top: 32px !important;
  }
  
  .crf .component-93-instance {
    color: #03133d !important;
  }
  
  .crf .design-component-instance-node {
    left: 683px !important;
    position: absolute !important;
    top: 32px !important;
  }
  
  .crf .view-2 {
    left: 819px !important;
    position: absolute !important;
    top: 32px !important;
  }
  
  .crf .view-3 {
    left: 934px !important;
    position: absolute !important;
    top: 32px !important;
  }
  
  .crf .text-wrapper-12 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 103px;
    left: 130px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: 839px;
    width: 843px;
  }
  