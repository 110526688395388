.metallurgicalM {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .metallurgicalM .div {
    background-color: #ffffff;
    height: 1326px;
    overflow: hidden;
    position: relative;
    width: 320px;
  }
  
  .metallurgicalM .overlap {
    height: 253px;
    left: -18px;
    position: absolute;
    top: -54px;
    width: 338px;
  }
  
  .metallurgicalM .rectangle {
    height: 155px;
    left: 18px;
    position: absolute;
    top: 54px;
    width: 320px;
  }
  
  .metallurgicalM .text-wrapper-2 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    height: 10px;
    left: 254px;
    letter-spacing: -0.4px;
    line-height: normal;
    position: absolute;
    top: 67px;
  }
  
  .metallurgicalM .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 169px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
    width: 33px;
  }
  
  .metallurgicalM .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 210px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .metallurgicalM .text-wrapper-5 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    height: 9px;
    left: 112px;
    letter-spacing: -0.35px;
    line-height: normal;
    position: absolute;
    top: 68px;
  }
  
  .metallurgicalM .component-1 {
    left: 20px !important;
    position: absolute !important;
    top: 60px !important;
    width:85px;
    height: 35px;
  }
  
  .metallurgicalM .text-wrapper-6 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    height: 30px;
    left: 103px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 177px;
    white-space: nowrap;
  }
  
  .metallurgicalM .rectangle-2 {
    border: 6px solid;
    border-color: #fecc00;
    height: 94px;
    left: 82px;
    position: absolute;
    top: 154px;
    width: 182px;
  }
  
  .metallurgicalM .text-wrapper-7 {
    color: #03133d;
    font-family: "?????-??", Helvetica;
    font-size: 11px;
    font-weight: 400;
    height: 33px;
    left: 102px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 210px;
    width: 88px;
  }
  
  .metallurgicalM .text-wrapper-8 {
    color: #000000;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    height: 10px;
    left: 45px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 205px;
    width: 222px;
  }
  
  .metallurgicalM .p {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 108px;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 231px;
    width: 280px;
  }
  
  .metallurgicalM .overlap-2 {
    height: 153px;
    left: 12px;
    position: absolute;
    top: 354px;
    width: 265px;
  }
  
  .metallurgicalM .overlap-3 {
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 265px;
  }
  
  .metallurgicalM .rectangle-3 {
    background-color: #ffffff;
    box-shadow: 0px 4px 6px 2px #00000040;
    height: 153px;
    left: 0;
    position: absolute;
    top: 0;
    width: 151px;
  }
  
  .metallurgicalM .viber {
    height: 111px;
    left: 10px;
    position: absolute;
    top: 13px;
    width: 130px;
  }
  
  .metallurgicalM .rectangle-4 {
    background-color: #03133d;
    height: 38px;
    left: 122px;
    position: absolute;
    top: 7px;
    width: 143px;
  }
  
  .metallurgicalM .text-wrapper-9 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    width: 125px;
  }
  
  .metallurgicalM .text-wrapper-10 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 131px;
  }
  
  .metallurgicalM .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 6px;
    font-weight: 300;
    left: 160px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 51px;
  }
  
  .metallurgicalM .overlap-4 {
    height: 153px;
    left: 12px;
    position: absolute;
    top: 534px;
    width: 265px;
  }
  
  .metallurgicalM .img {
    height: 118px;
    left: 11px;
    position: absolute;
    top: 13px;
    width: 130px;
  }
  
  .metallurgicalM .text-wrapper-11 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    white-space: nowrap;
  }
  
  .metallurgicalM .text-wrapper-12 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
    width: 131px;
  }
  
  .metallurgicalM .overlap-5 {
    height: 153px;
    left: 12px;
    position: absolute;
    top: 713px;
    width: 265px;
  }
  
  .metallurgicalM .element-IMG {
    height: 113px;
    left: 10px;
    position: absolute;
    top: 13px;
    width: 125px;
  }
  
  .metallurgicalM .text-wrapper-13 {
    color: #ffffff;
    font-family: "?????-??", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    width: 123px;
  }
  
  .metallurgicalM .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 99px;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 892px;
    width: 289px;
  }
  
  .metallurgicalM .overlap-6 {
    height: 115px;
    left: -15px;
    position: absolute;
    top: 1040px;
    width: 364px;
  }
  
  .metallurgicalM .rectangle-5 {
    background-color: #03133d;
    height: 96px;
    left: 2px;
    position: absolute;
    top: 10px;
    transform: rotate(3.15deg);
    width: 359px;
  }
  
  .metallurgicalM .text-wrapper-14 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 700;
    height: 24px;
    left: 20px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    white-space: nowrap;
  }
  
  .metallurgicalM .text-wrapper-15 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 300;
    height: 36px;
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 44px;
    width: 279px;
  }
  
  .metallurgicalM .overlap-7 {
    height: 171px;
    left: 0;
    position: absolute;
    top: 1174px;
    width: 324px;
  }
  
  .metallurgicalM .rectangle-6 {
    height: 147px;
    left: 102px;
    position: absolute;
    top: 5px;
    width: 218px;
  }
  
  .metallurgicalM .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 240px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 16px;
    width: 75px;
  }
  
  .metallurgicalM .rectangle-7 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 154px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 123px;
  }
  
  .metallurgicalM .text-wrapper-16 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #000000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    left: 25px;
    letter-spacing: -0.4px;
    line-height: 8px;
    opacity: 0.3;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 24px;
    width: 259px;
  }
  
  .metallurgicalM .text-wrapper-17 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 234px;
    letter-spacing: -0.45px;
    line-height: 7.6px;
    position: absolute;
    top: 106px;
    width: 85px;
  }
  
  .metallurgicalM .div-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.45px;
    line-height: 9px;
    position: absolute;
    top: 18px;
    width: 62px;
  }
  
  .metallurgicalM .span {
    line-height: 7.6px;
  }
  
  .metallurgicalM .text-wrapper-18 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: -0.45px;
    line-height: 9px;
  }
  
  .metallurgicalM .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-weight: 400;
    left: 223px;
    letter-spacing: -0.4px;
    line-height: 8px;
    position: absolute;
    top: 74px;
    width: 101px;
  }
  
  .metallurgicalM .element-4 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 7px;
    font-weight: 400;
    left: 5px;
    letter-spacing: -0.35px;
    line-height: 7px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 319px;
  }
  
  .metallurgicalM .line {
    height: 2px;
    left: 73px;
    position: absolute;
    top: 128px;
    width: 175px;
  }
  
  .metallurgicalM .image {
    left: 108px !important;
    top: 23px !important;
    width: 212px !important;
  }