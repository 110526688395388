.partner {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.partner .div {
  background-color: #ffffff;
  border: 1px none;
  height: 3324px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.partner .view {
  height: 400px;
  left: 0;
  position: absolute;
  top: 2924px;
  width: 1452px;
}

.partner .overlap-group {
  height: 402px;
  position: relative;
  top: -2px;
  width: 1440px;
}

.partner .rectangle {
  background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
  height: 400px;
  left: 247px;
  position: absolute;
  top: 2px;
  width: 1193px;
}

.partner .rectangle-2 {
  background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
  height: 400px;
  left: 0;
  position: absolute;
  top: 2px;
  transform: rotate(-180deg);
  width: 284px;
}

.partner .text-wrapper-2 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 54.17%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 500;
  left: 289px;
  letter-spacing: -1.8px;
  line-height: 36px;
  position: absolute;
  text-align: center;
  color: transparent;
  top: 1px;
  width: 816px;
}

.partner .text-wrapper-3 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 21.8px;
  position: absolute;
  top: 272px;
  white-space: nowrap;
}

.partner .p {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 67px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 58px;
}

.partner .span {
  line-height: 21.8px;
}

.partner .text-wrapper-4 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: -1.3px;
  line-height: 26px;
}

.partner .element {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 40px;
}

.partner .text-wrapper-5 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 1084px;
  letter-spacing: -1.3px;
  line-height: 26px;
  position: absolute;
  top: 197px;
  white-space: nowrap;
}

.partner .element-2 {
  color: #03133d;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 408px;
  letter-spacing: -0.9px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 351px;
  width: 624px;
}

.partner .line {
  height: 2px;
  left: 338px;
  position: absolute;
  top: 336px;
  width: 820px;
}

.partner .img {
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.partner .image {
  left: 586px !important;
  top: 99px !important;
}

.partner .overlap {
  height: 758px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.partner .getty {
  height: 608px;
  left: 720px;
  position: absolute;
  top: 150px;
  width: 720px;
}

.partner .rectangle-3 {
  background-color: #03133d;
  height: 608px;
  left: 0;
  position: absolute;
  top: 150px;
  width: 720px;
}

.partner .getty-2 {
  height: 608px;
  left: 0;
  position: absolute;
  top: 150px;
  width: 720px;
}

.partner .component-135 {
  height: 204px !important;
  width: 343px !important;
}

.partner .text-wrapper-6 {
  color: #ffffff;
  font-family: "Unbounded", Helvetica;
  font-size: 70px;
  font-weight: 400;
  height: 192px;
  left: 260px;
  letter-spacing: -3.5px;
  line-height: 190px;
  position: absolute;
  top: 330px;
  white-space: nowrap;
  width: 427px;
}

.partner .text-wrapper-7 {
  color: #ffffff;
  font-family: "Unbounded", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 100px;
  left: 158px;
  letter-spacing: 0;
  line-height: 100px;
  position: absolute;
  top: 453px;
  white-space: nowrap;
}

.partner .rectangle-4 {
  background-color: #d9d9d980;
  height: 360px;
  left: 720px;
  position: absolute;
  top: 289px;
  width: 183px;
}

.partner .rectangle-5 {
  border: 5px solid;
  border-color: #ffffff;
  height: 360px;
  left: 98px;
  position: absolute;
  top: 284px;
  width: 800px;
}

.partner .line-2 {
  height: 2px;
  left: 141px;
  position: absolute;
  top: 476px;
  width: 560px;
}

.partner .text-wrapper-8 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
 
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 54.17%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  left: 723px;
  letter-spacing: -1px;
  line-height: 20px;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  color: transparent;
  top: 282px;
  width: 169px;
}

.partner .image-2 {
  height: 28px;
  left: 1344px;
  position: absolute;
  top: 43px;
  width: 42px;
}

.partner .component-136 {
  left: 515px !important;
  position: absolute !important;
  top: 27px !important;
}

.partner .component-136-instance {
  color: #03133d !important;
}

.partner .design-component-instance-node {
  left: 691px !important;
  position: absolute !important;
  top: 27px !important;
}

.partner .view-2 {
  left: 827px !important;
  position: absolute !important;
  top: 27px !important;
}

.partner .view-3 {
  left: 942px !important;
  position: absolute !important;
  top: 27px !important;
}

.partner .text-wrapper-9 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 40px;
  font-weight: 400;
  height: 105px;
  left: 130px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 789px;
  width: 873px;
}

.partner .view-4 {
  height: 225px;
  left: 90px;
  position: absolute;
  top: 1103px;
  width: 616px;
}

.partner .dtek-logo {
  height: 91px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 258px;
}

.partner .i {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 96px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 129px;
  width: 612px;
}

.partner .line-3 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 108px;
  width: 600px;
}

.partner .text-wrapper-10 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 37px;
  left: 290px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 29px;
}

.partner .view-5 {
  height: 310px;
  left: 730px;
  position: absolute;
  top: 1066px;
  width: 640px;
}

.partner .logo-removebg {
  height: 113px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 348px;
}

.partner .text-wrapper-11 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 144px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 166px;
  width: 597px;
}

.partner .line-4 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 145px;
  width: 600px;
}

.partner .text-wrapper-12 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 128px;
  left: 370px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 266px;
}

.partner .view-6 {
  height: 280px;
  left: 90px;
  position: absolute;
  top: 1432px;
  width: 607px;
}

.partner .text-wrapper-13 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 96px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 184px;
  width: 603px;
}

.partner .removebg-preview {
  height: 163px;
  left: 54px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 163px;
}

.partner .line-5 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 167px;
  width: 600px;
}

.partner .text-wrapper-14 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 96px;
  left: 290px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 39px;
}

.partner .view-7 {
  height: 284px;
  left: 90px;
  position: absolute;
  top: 1816px;
  width: 604px;
}

.partner .overlap-2 {
  height: 171px;
  left: 0;
  position: absolute;
  top: 0;
  width: 600px;
}

.partner .removebg-preview-2 {
  height: 171px;
  left: 22px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 228px;
}

.partner .line-6 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 170px;
  width: 600px;
}

.partner .text-wrapper-15 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 128px;
  left: 290px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 22px;
}

.partner .text-wrapper-16 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 103px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 181px;
  width: 559px;
}

.partner .view-8 {
  height: 278px;
  left: 90px;
  position: absolute;
  top: 2213px;
  width: 604px;
}

.partner .span-wrapper {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 120px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 158px;
  width: 561px;
}

.partner .text-wrapper-17 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
}

.partner .overlap-3 {
  height: 143px;
  left: 0;
  position: absolute;
  top: 0;
  width: 600px;
}

.partner .NZF-logo {
  height: 143px;
  left: 54px;
  position: absolute;
  top: 0;
  width: 163px;
}

.partner .line-7 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 142px;
  width: 600px;
}

.partner .text-wrapper-18 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 96px;
  left: 290px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 28px;
}

.partner .view-9 {
  height: 235px;
  left: 90px;
  position: absolute;
  top: 2645px;
  width: 604px;
}

.partner .ferrexpo-removebg {
  height: 72px;
  left: 7px;
  position: absolute;
  top: 0;
  width: 257px;
}

.partner .element-3 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 117px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 118px;
  width: 519px;
}

.partner .line-8 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 94px;
  width: 600px;
}

.partner .ferrexpo {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 64px;
  left: 290px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.partner .view-10 {
  height: 210px;
  left: 730px;
  position: absolute;
  top: 2649px;
  width: 604px;
}

.partner .metinvest-logo {
  height: 51px;
  left: 22px;
  object-fit: cover;
  position: absolute;
  top: 17px;
  width: 300px;
}

.partner .text-wrapper-19 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 96px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 114px;
  width: 597px;
}

.partner .line-9 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 90px;
  width: 600px;
}

.partner .text-wrapper-20 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 64px;
  left: 370px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.partner .view-11 {
  height: 283px;
  left: 730px;
  position: absolute;
  top: 1822px;
  width: 640px;
}

.partner .text-wrapper-21 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 96px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 187px;
  width: 597px;
}

.partner .element-4 {
  height: 160px;
  left: 95px;
  position: absolute;
  top: 0;
  width: 168px;
}

.partner .line-10 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 164px;
  width: 600px;
}

.partner .text-wrapper-22 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 128px;
  left: 370px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 16px;
}

.partner .div-2 {
  height: 258px;
  left: 730px;
  position: absolute;
  top: 2233px;
  width: 659px;
}

.partner .ifcem-logo-main-reg {
  height: 104px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 338px;
}

.partner .text-wrapper-23 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 120px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 138px;
  width: 597px;
}

.partner .line-11 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 122px;
  width: 600px;
}

.partner .text-wrapper-24 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 96px;
  left: 370px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.partner .view-12 {
  height: 273px;
  left: 730px;
  position: absolute;
  top: 1439px;
  width: 604px;
}

.partner .text-wrapper-25 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 300;
  height: 96px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 177px;
  width: 585px;
}

.partner .line-12 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 160px;
  width: 600px;
}

.partner .text-wrapper-26 {
  color: #000000;
  font-family: "Unbounded", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 128px;
  left: 370px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 22px;
}

.partner .element-5 {
  height: 150px;
  left: 123px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 125px;
}
