.plasma {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .plasma .div {
    background-color: #ffffff;
    border: 1px none;
    height: 4224px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .plasma .div-2 {
    height: 1508px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1450px;
  }
  
  .plasma .element {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 300;
    height: 176px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1079px;
    width: 1191px;
  }
  
  .plasma .overlap {
    height: 1041px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .plasma .rectangle {
    height: 691px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .plasma .text-wrapper-2 {
    color: #03133d;
    font-family: "Unbounded", Helvetica;
    font-size: 80px;
    font-weight: 400;
    height: 189px;
    left: 330px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 717px;
    width: 780px;
  }
  
  .plasma .rectangle-2 {
    border: 40px solid;
    border-color: #fecc00;
    height: 469px;
    left: 272px;
    position: absolute;
    top: 417px;
    width: 805px;
  }
  
  .plasma .text-wrapper-3 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 500;
    height: 100px;
    left: 490px;
    letter-spacing: 0;
    line-height: 100px;
    position: absolute;
    top: 932px;
    white-space: nowrap;
    width: 493px;
  }
  
  .plasma .text-wrapper-4 {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 150px;
    font-style: italic;
    font-weight: 900;
    height: 299px;
    left: 271px;
    letter-spacing: 0;
    line-height: 300px;
    position: absolute;
    text-align: right;
    top: 486px;
    white-space: nowrap;
    width: 811px;
  }
  
  .plasma .overlap-group {
    background-color: #03133d;
    height: 240px;
    left: 0;
    position: absolute;
    top: 1268px;
    width: 1440px;
  }
  
  .plasma .p {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 192px;
    left: 475px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    top: 23px;
    width: 846px;
  }
  
  .plasma .text-wrapper-5 {
    color: #ffffff;
    font-family: "Unbounded", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 90px;
    left: 130px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    top: 53px;
    width: 190px;
  }
  
  .plasma .view {
    left: 60px !important;
    position: absolute !important;
    top: 30px !important;
  }
  
  .plasma .image {
    height: 28px;
    left: 1337px;
    position: absolute;
    top: 56px;
    width: 42px;
  }
  
  .plasma .component-183-instance {
    left: 508px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .plasma .design-component-instance-node {
    color: #ffffff !important;
  }
  
  .plasma .view-2 {
    left: 684px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .plasma .view-3 {
    left: 820px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .plasma .view-4 {
    left: 935px !important;
    position: absolute !important;
    top: 40px !important;
  }
  
  .plasma .element-2 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 189px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2101px;
    width: 1129px;
  }
  
  .plasma .span {
    font-weight: 300;
  }
  
  .plasma .text-wrapper-6 {
    font-weight: 300;
    text-decoration: underline;
  }
  
  .plasma .text-wrapper-7 {
    font-weight: 600;
  }
  
  .plasma .overlap-group-wrapper {
    height: 289px;
    left: -6px;
    position: absolute;
    top: 3526px;
    width: 1456px;
  }
  
  .plasma .overlap-group-2 {
    height: 290px;
    left: 6px;
    position: relative;
    top: -1px;
    width: 1440px;
  }
  
  .plasma .img {
    height: 217px;
    left: 0;
    position: absolute;
    top: 73px;
    width: 1440px;
  }
  
  .plasma .promgrupp-pc-gmail {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    height: 110px;
    left: 162px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 132px;
    width: 1101px;
  }
  
  .plasma .text-wrapper-8 {
    -webkit-text-stroke: 1px #03133d;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 700;
    height: 80px;
    left: 21px;
    letter-spacing: 0;
    line-height: 80px;
    position: absolute;
    top: 0;
    width: 1212px;
  }
  
  .plasma .plazmennaya-rezka {
    height: 449px;
    left: 0;
    position: absolute;
    top: 1539px;
    width: 1440px;
  }
  
  .plasma .view-5 {
    height: 1116px;
    left: 130px;
    position: absolute;
    top: 2349px;
    width: 1153px;
  }
  
  .plasma .overlap-2 {
    background-color: #03133d;
    border-radius: 10px;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 210px;
  }
  
  .plasma .text-wrapper-9 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    height: 64px;
    left: 51px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 13px;
  }
  
  .plasma .overlap-3 {
    background-color: #02133c;
    border-radius: 10px;
    height: 698px;
    left: 0;
    position: absolute;
    top: 107px;
    width: 210px;
  }
  
  .plasma .text-wrapper-10 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    height: 32px;
    left: 35px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 353px;
    transform: rotate(-90deg);
  }
  
  .plasma .overlap-4 {
    background-color: #03133d;
    border-radius: 10px;
    height: 295px;
    left: 0;
    position: absolute;
    top: 815px;
    width: 210px;
  }
  
  .plasma .text-wrapper-11 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    height: 32px;
    left: 2px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 121px;
    transform: rotate(-90deg);
  }
  
  .plasma .overlap-5 {
    background-color: #03133d;
    border-radius: 10px;
    height: 100px;
    left: 217px;
    position: absolute;
    top: 0;
    width: 210px;
  }
  
  .plasma .text-wrapper-12 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    height: 64px;
    left: 44px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 13px;
  }
  
  .plasma .overlap-6 {
    background-color: #03133d;
    border-radius: 10px;
    height: 100px;
    left: 434px;
    position: absolute;
    top: 0;
    width: 321px;
  }
  
  .plasma .overlap-7 {
    background-color: #03133d;
    border-radius: 10px;
    height: 100px;
    left: 762px;
    position: absolute;
    top: 0;
    width: 367px;
  }
  
  .plasma .text-wrapper-13 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 500;
    height: 64px;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 13px;
  }
  
  .plasma .overlap-8 {
    height: 700px;
    left: 217px;
    position: absolute;
    top: 110px;
    width: 210px;
  }
  
  .plasma .rectangle-3 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 210px;
  }
  
  .plasma .rectangle-4 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 50px;
    width: 210px;
  }
  
  .plasma .rectangle-5 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 210px;
  }
  
  .plasma .rectangle-6 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 150px;
    width: 210px;
  }
  
  .plasma .rectangle-7 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 200px;
    width: 210px;
  }
  
  .plasma .rectangle-8 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 250px;
    width: 210px;
  }
  
  .plasma .rectangle-9 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 300px;
    width: 210px;
  }
  
  .plasma .rectangle-10 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 350px;
    width: 210px;
  }
  
  .plasma .rectangle-11 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 400px;
    width: 210px;
  }
  
  .plasma .rectangle-12 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 450px;
    width: 210px;
  }
  
  .plasma .rectangle-13 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 500px;
    width: 210px;
  }
  
  .plasma .rectangle-14 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 550px;
    width: 210px;
  }
  
  .plasma .rectangle-15 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 600px;
    width: 210px;
  }
  
  .plasma .rectangle-16 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 650px;
    width: 210px;
  }
  
  .plasma .element-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 34px;
    font-weight: 400;
    height: 697px;
    left: 87px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 3px;
    width: 45px;
  }
  
  .plasma .overlap-9 {
    height: 700px;
    left: 434px;
    position: absolute;
    top: 110px;
    width: 321px;
  }
  
  .plasma .rectangle-17 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 321px;
  }
  
  .plasma .rectangle-18 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 50px;
    width: 321px;
  }
  
  .plasma .rectangle-19 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 321px;
  }
  
  .plasma .rectangle-20 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 150px;
    width: 321px;
  }
  
  .plasma .rectangle-21 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 200px;
    width: 321px;
  }
  
  .plasma .rectangle-22 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 250px;
    width: 321px;
  }
  
  .plasma .rectangle-23 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 300px;
    width: 321px;
  }
  
  .plasma .rectangle-24 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 350px;
    width: 321px;
  }
  
  .plasma .rectangle-25 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 400px;
    width: 321px;
  }
  
  .plasma .rectangle-26 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 450px;
    width: 321px;
  }
  
  .plasma .rectangle-27 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 500px;
    width: 321px;
  }
  
  .plasma .rectangle-28 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 550px;
    width: 321px;
  }
  
  .plasma .rectangle-29 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 600px;
    width: 321px;
  }
  
  .plasma .rectangle-30 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 650px;
    width: 321px;
  }
  
  .plasma .element-4 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 34px;
    font-weight: 400;
    height: 700px;
    left: 113px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 0;
  }
  
  .plasma .overlap-10 {
    height: 301px;
    left: 434px;
    position: absolute;
    top: 815px;
    width: 321px;
  }
  
  .plasma .element-5 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 34px;
    font-weight: 400;
    height: 300px;
    left: 106px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 1px;
  }
  
  .plasma .overlap-11 {
    height: 700px;
    left: 762px;
    position: absolute;
    top: 110px;
    width: 367px;
  }
  
  .plasma .rectangle-31 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 367px;
  }
  
  .plasma .rectangle-32 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 50px;
    width: 367px;
  }
  
  .plasma .rectangle-33 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 367px;
  }
  
  .plasma .rectangle-34 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 150px;
    width: 367px;
  }
  
  .plasma .rectangle-35 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 200px;
    width: 367px;
  }
  
  .plasma .rectangle-36 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 250px;
    width: 367px;
  }
  
  .plasma .rectangle-37 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 300px;
    width: 367px;
  }
  
  .plasma .rectangle-38 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 350px;
    width: 367px;
  }
  
  .plasma .rectangle-39 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 400px;
    width: 367px;
  }
  
  .plasma .rectangle-40 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 450px;
    width: 367px;
  }
  
  .plasma .rectangle-41 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 500px;
    width: 367px;
  }
  
  .plasma .rectangle-42 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 550px;
    width: 367px;
  }
  
  .plasma .rectangle-43 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 600px;
    width: 367px;
  }
  
  .plasma .rectangle-44 {
    background-color: #d9d9d9;
    border-radius: 10px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 650px;
    width: 367px;
  }
  
  .plasma .element-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 34px;
    font-weight: 400;
    height: 700px;
    left: 150px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 0;
  }
  
  .plasma .overlap-12 {
    height: 301px;
    left: 762px;
    position: absolute;
    top: 815px;
    width: 367px;
  }
  
  .plasma .element-7 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 34px;
    font-weight: 400;
    height: 300px;
    left: 144px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 1px;
  }
  
  .plasma .overlap-13 {
    height: 301px;
    left: 217px;
    position: absolute;
    top: 815px;
    width: 210px;
  }
  
  .plasma .element-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 34px;
    font-weight: 400;
    height: 300px;
    left: 85px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 1px;
  }
  
  .plasma .overlap-wrapper {
    height: 400px;
    left: 0;
    position: absolute;
    top: 3824px;
    width: 1452px;
  }
  
  .plasma .overlap-14 {
    height: 402px;
    position: relative;
    top: -2px;
    width: 1440px;
  }
  
  .plasma .rectangle-45 {
    background: linear-gradient(180deg, rgba(254, 204, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 247px;
    position: absolute;
    top: 2px;
    width: 1193px;
  }
  
  .plasma .rectangle-46 {
    background: linear-gradient(180deg, rgb(3, 19, 61) 0%, rgba(217, 217, 217, 0) 100%);
    height: 400px;
    left: 0;
    position: absolute;
    top: 2px;
    transform: rotate(-180deg);
    width: 284px;
  }
  
  .plasma .text-wrapper-14 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 54.17%,
      rgba(255, 255, 255, 0)100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 36px;
    font-style: italic;
    font-weight: 500;
    left: 289px;
    letter-spacing: -1.8px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 1px;
    width: 816px;
  }
  
  .plasma .text-wrapper-15 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 21.8px;
    position: absolute;
    top: 272px;
    white-space: nowrap;
  }
  
  .plasma .div-3 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 67px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 58px;
  }
  
  .plasma .text-wrapper-16 {
    line-height: 21.8px;
  }
  
  .plasma .text-wrapper-17 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -1.3px;
    line-height: 26px;
  }
  
  .plasma .element-9 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 40px;
  }
  
  .plasma .text-wrapper-18 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 26px;
    font-weight: 400;
    left: 1084px;
    letter-spacing: -1.3px;
    line-height: 26px;
    position: absolute;
    top: 197px;
    white-space: nowrap;
  }
  
  .plasma .element-10 {
    color: #03133d;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 408px;
    letter-spacing: -0.9px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 351px;
    width: 624px;
  }
  
  .plasma .line {
    height: 2px;
    left: 338px;
    position: absolute;
    top: 336px;
    width: 820px;
  }
  
  .plasma .line-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .plasma .component-181-instance {
    left: 586px !important;
    top: 99px !important;
  }
  
  .plasma .text-wrapper-19 {
    color: #000000;
    font-family: "Unbounded", Helvetica;
    font-size: 30px;
    font-weight: 400;
    height: 37px;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2020px;
  }
  